<template>
    <ks-button :spinColor="'fff'" @click="clicked" class="vault-button" :class="type_class" :spin="spin"
               v-if="!is_router_link" :disabled="disabled">
        <slot name="icon" v-if="icon">
            <component :is="icon_svg" color="ffffff"></component>
        </slot>
        <span><slot></slot></span>
    </ks-button>
    <router-link class="button" :class="type_class" v-else-if="is_router_link" :to="to">
		<span class="vault-button-loader" v-show="spin">
			<ks-loader-spin :show="spin" size="small"></ks-loader-spin>
		</span>

        <span :class="{visible: !spin, hidden: spin}">
    	    <span v-if="icon"><slot name="icon"><component :is="icon_svg" color="ffffff"></component></slot></span>
        	<slot></slot>
		</span>
    </router-link>
</template>

<script>
    // External Dependencies

    // Internal Dependencies
    import * as Icons from '../Icons';
    import VaultIcon from '@/Vault/UI/Icons/Icon';

    export default {
        name: 'Button',

        props: {
            type: {
                type: String,
                default: '',
            },

            icon: {
                type: Boolean,
                default: true,
            },

            prevent: {
                type: Boolean,
                default: true,
            },

            spin: {
                type: Boolean,
                default: false,
            },

            to: {
                type: Object,
                default() {
                    return {};
                },
            },

            disabled: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            icon_svg() {
                return this.icon_map[this.type];
            },

            type_class() {
                return {
                    disabled: this.disabled,
                    ...this.type_map[this.type],
                };
            },

            is_router_link() {
                return Object.keys(this.to).length > 0;
            },
        },

        data() {
            return {
                icon_map: {
                    'add': 'icon-add',
                    'edit': 'icon-edit',
                    'user': 'icon-user',
                    'envelope': 'icon-envelope',
                    'location': 'icon-location',
                    'save': 'icon-save',
                    'modal-save': 'icon-save',
                    'delete': 'icon-remove',
                    'filter': 'icon-filter',
                    'tuning': 'icon-tuning',
                    'refresh': 'icon-refresh',
                    'download': 'icon-download',
                },

                type_map: {
                    'add': {'add-btn': true},
                    'edit': {'': true},
                    'user': {'add-btn': true},
                    'envelope': {'btn': true},
                    'location': {'': true},
                    'save': {'save-btn': true},
                    'modal-save': {'': true},
                    'delete': {'delete-btn': true},
                    'filter': {'': true},
                    'tuning': {'': true},
                    'refresh': {'': true},
                    'download': {'': true},
                },
            };
        },

        methods: {
            clicked(e) {
                if (this.spin) {
                    e.preventDefault();
                    e.stopPropagation();
                    return;
                }

                if (this.prevent == true) {
                    e.preventDefault();
                }

                this.$emit('click', e);
            },
        },

        components: {
            ...Icons,
            VaultIcon,
        },
    };
</script>
