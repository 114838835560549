const state = {
    events: [],
    webhooks: [],
};

const mutations = {
    SET_EVENTS(state, events) {
        state.events = events;
    },

    SET_WEBHOOKS(state, webhooks) {
        state.webhooks = webhooks;
    }
};

import * as getters from './getters';
import * as actions from './actions';

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}