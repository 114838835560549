<template>
    <div>
        <slot></slot>
    </div>
</template>

<script>
    // External Dependencies
    import Glightbox from 'glightbox';
    import 'glightbox/dist/css/glightbox.css'

    // Internal Dependencies

    export default {
        name: 'ImageViewer',

        props: {
            galleryClass: {
                default: 'gallery-wrapper',
            },
        },

        mounted() {
            Glightbox({
                selector: `.${this.galleryClass}`,
                loop: true,
                draggable: false,
                autoplayVideos: false,
            })
        },
    }
</script>
