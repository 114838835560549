/**
 * Get users from store
 *
 * @param state
 * @returns {Array}
 */
export function users(state) {
    return state.users;
}

/**
 * Get paginator
 *
 * @param state
 * @returns {state.pagination|{customers}|{}}
 */
export function pagination(state) {
    return state.pagination;
}

/**
 * Get user invite object
 *
 * @param state
 * @returns {*}
 */
export function invite(state) {
    return state.invite;
}

export function user_profile_id(state) {
    return state.user_profile_id;
}

export function user_profile(state) {
    return state.user_profile;
}


/**
 *
 * Roles
 *
 */

/**
 *
 * @param state
 * @returns {Array}
 */
export function roles(state) {
    return state.roles;
}

/**
 *
 * @param state
 * @returns {null}
 */
export function timeoff_categories(state) {
    return state.timeoff_categories;
}
