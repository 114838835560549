// External Dependencies
import Cookie from 'js-cookie';
import api from 'kickstart-ui/lib/api';

/**
 * @returns {*}
 */
function getAccessToken() {
    return Cookie.get('vat');
}

export function createAuthInterceptor(Vue) {
    Vue.api.getInstance().interceptors.request.use(config => {
        const csrf_token = document.head.querySelector('meta[name="csrf-token"]');
        const access_token = getAccessToken();

        config.headers['X-Requested-With'] = 'XMLHttpRequest';
        config.headers['X-CSRF-TOKEN'] = csrf_token?.content;

        if ( access_token == undefined || access_token == null ) {
            if ( !window.refreshing_token ) {
                window.refreshing_token = true;

                return api.post('/refresh')
                    .then(({data}) => {
                        config.headers.Authorization = `Bearer ${data.access_token}`;

                        window.refreshing_token = false;

                        return config;
                    })
                    .catch(() => {
                        window.location.reload();
                    });
            } else {
                return config;
            }
        } else {
            config.headers.Authorization = `Bearer ${access_token}`;

            return config;
        }
    });

    Vue.api.getInstance().interceptors.response.use(undefined, error => {
        if ( 'response' in error  ) {
            if ( error.response.status === 401 ) {
                // Do not attempt to re-request ping calls since they will fail infinitely
                // Instead reload the app if the ping fails because that indicates that the web session is no longer active.
                if ( error.config?.url?.match(/\/(ping)$/)?.length ) {
                    window.location.reload();
                    return Promise.reject(error);
                }

                return new Promise((resolve, reject) => {
                    return setTimeout(() => {
                        return resolve(Vue.api.getInstance().request(error.config));
                    }, 500);
                });
            } else if ( error.response.status === 403 ) {
                Vue.toast({
                    type: 'error',
                    message: 'You do not have access to this resource.',
                    duration: 0
                });
            }
        }

        return Promise.reject(error);
    });
}
