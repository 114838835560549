import Vue from 'vue';
import _unionBy from 'lodash/unionBy';
import { arrayWrap } from 'helpers/array.js';

const state = {
    fields: {
        work: [],
        location: [],
        form_section: []
    },
    field: null,
    field_types: [],
    option_types: [
        'select',
        'radio',
        'checkbox',
        'multi_select',
    ],
    libraries: {
        data: [],
        paginator: {
            total_count: 0,
            total_pages: 0,
            current_page: 0,
            limit: 0,
            next_page: null,
            previous_page: null,
        }
    },

    /** @var {ServiceItem[]} */
    cached_items: [],
};

const mutations = {
    SET_FIELD_TYPES(state, field_types) {
        state.field_types = field_types;
    },
    SET_FIELDS(state, {group, fields}) {
        Vue.set(state.fields, group, [...fields]);
    },
    SET_FIELD(state, field) {
        state.field = {...field};
    },
    RESET_FIELD(state) {
        state.field = null;
    },
    SET_LIBRARIES(state, {data, paginator}) {
        Vue.set(state.libraries, 'data', data);
        Vue.set(state.libraries, 'paginator', paginator);
    },

    /**
     * @param state
     * @param {ServiceItem|ServiceItem[]} items
     */
    CACHE_ITEMS(state, items = []) {
        state.cached_items = _unionBy(state.cached_items, arrayWrap(items), 'service_item_id');
    },

    /**
     * @param state
     */
    CLEAR_CACHED_ITEMS(state) {
        state.cached_items = [];
    }
};

import * as getters from './getters';
import * as actions from './actions';

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
