import Vue from 'vue'

import {isObject, isString} from './types.js'

/**
 * Set a reactive property on an object.
 *
 * @param {Object} object
 * @param {String} property 'Dot' notation is supported.
 * @param mixed    value
 */
export default function deepSet(object, property, value) {
    if ( !isObject(object) ) {
        throw new TypeError('deepSet: First argument must be an object');
    }

    if ( !isString(property) ) {
        throw new TypeError('deepSet: Second argument must be a string');
    }

    if ( property.indexOf('.') === -1 ) {
        return Vue.set(object, property, value);
    }

    let keys = property.split('.');

    for ( let i = 0; i < (keys.length - 1); i++ ) {
        let key = keys[i];

        if ( !object.hasOwnProperty(key) || !object[key] ) {
            Vue.set(object, key, {});
        }

        object = object[key];
    }

    Vue.set(object, keys[keys.length - 1], value);

    return value;
}
