/**
 *
 * @param state
 * @returns {Array}
 */
export function classes(state) {
    return state.classes;
}

/**
 *
 * @param state
 * @returns {*}
 */
export function service_item(state) {
    return state.service_item;
}

/**
 * Get service item to be edited
 *
 * @param state
 * @returns {state.edit_service|{}}
 */
export function edit_service(state) {
    return state.edit_service;
}

/**
 * Get work order categories
 *
 * @param state
 * @returns {Array}
 */
export function categories(state) {
    return state.categories;
}

/**
 * Get the category to be edited
 *
 * @param state
 * @returns {state.edit_category|{}}
 */
export function edit_category(state) {
    return state.edit_category;
}

/**
 * Get the category to be deleted
 *
 * @param state
 * @returns {state.del_category|{}}
 */
export function del_category(state) {
    return state.del_category;
}

/**
 * Get the project to be edited
 *
 * @param state
 * @returns {state.edit_project|{}}
 */
export function edit_project(state) {
    return state.edit_project;
}

/**
 * Get projects
 *
 * @param state
 * @returns {Array}
 */
export function projects(state) {
    return state.projects;
}

/**
 * Get statuses
 *
 * @param state
 * @returns {Array}
 */
export function statuses(state) {
    return state.statuses;
}

/**
 *
 * @param state
 * @returns [{is_locked: boolean, has_classes: boolean, label: string, type: string}]
 */
export function item_types(state) {
    return state.item_types;
}

/**
 *
 * @param state
 * @returns {(function(*): (string|string))|*}
 */
export function item_type_plural(state) {
    return (type) => {
        if ( type === 'labor' ) {
            return 'labor';
        }

        return `${type}s`;
    }
}

/**
 *
 * @param state
 * @returns {function(*)}
 */
export function item_type_has_classes(state) {
    return (type) => {
        return !!state.item_types.find(t => t.type === type)?.has_classes;
    }
}

/**
 *
 * @param state
 * @returns {*}
 */
export function summary_options(state) {
    return state.summary_options;
}