export const REGEX_OPERATOR = /([+\-*/^])/;
export const REGEX_PARENTHESIS = /([()])/;
export const REGEX_REFERENCE = /{((?:\w+[.:])*(\w+))}/;
export const REGEX_REFERENCE_ALL = new RegExp(REGEX_REFERENCE, 'g');
export const REGEX_DELETED_REFERENCE = /#REF!/;
export const REGEX_FUNCTION = /\b(MAX|MIN|AVG|SUM|COUNT|SUMBY|COUNTBY)\b/;
export const REGEX_ERROR = /#ERR!/;

/**
 * Converts old-style references e.g. '{123}' into a fully qualified reference e.g. '{custom_field:123}'
 * @param {string} expression
 * @returns {string}
 */
export function translateSimpleReferences(expression) {
    if ( typeof expression !== 'string' ) {
        return expression;
    }

    return expression?.replaceAll(/{(\d+)}/g, '{custom_field:$1}')
}
