import customers from '../Vault/Customers/store/customers';
import locations from '../Vault/Locations/store/locations';
import users from '../Vault/Admin/Users/store/users';
import workorders from '../Vault/Admin/WorkOrder/store/workorder';
import config from '../Vault/Admin/Company/store/config';
import billing from '../Vault/Admin/Billing/store/billing';
import workorder from '../Vault/WorkOrder/store/workorder';
import invoices from '../Vault/Invoices/store/invoices';
import search_filters from '../Vault/Filters/store/search_filters';
import imports from '../Vault/Admin/Imports/store/imports';
import exports from '../Vault/Admin/Exports/store/exports';
import maps from '../Vault/Map/store/maps';
import forms from '../Vault/Admin/Forms/store/forms';
import fields from '../Vault/Admin/CustomFields/store/custom_fields';
import work_quotes from '@Vault/WorkQuotes/store/work_quotes';
import inventory from '@Vault/Inventory/store/inventory';
import equipment from '@Vault/Equipment/store/equipment';
import diagrams from '@Vault/Diagrams/store/diagrams';
import custom_processes from '@Vault/Admin/CustomProcess/store/custom_processes';
import reference_documents from '@Vault/ReferenceDocuments/store/reference-documents';
import certifications from '@Vault/Certifications/store/certifications';
import ftps from '@Vault/Ftps/store/ftps';
import notifications from '@Vault/Admin/Notifications/store/notifications';
import event_system from '@Vault/Admin/DataIntegrations/store/event_system';
import tasks from '@Vault/Tasks/store/tasks';

export default {
    customers,
    locations,
    users,
    workorders,
    config,
    billing,
    workorder,
    invoices,
    search_filters,
    imports,
    exports,
    maps,
    forms,
    fields,
    work_quotes,
    inventory,
    equipment,
    diagrams,
    custom_processes,
    reference_documents,
    certifications,
    ftps,
    notifications,
    event_system,
    tasks,
}
