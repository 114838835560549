// External Dependencies
// import {library} from '@fortawesome/fontawesome-svg-core';
// import {far as faRegular} from '@fortawesome/pro-regular-svg-icons';
// import {fas as faSolid} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

// library.add(faSolid, faRegular);

export default {
    name: 'VaultIcon',

    functional: true,

    props: {
        border: {
            type: Boolean,
            default: false
        },
        fixedWidth: {
            type: Boolean,
            default: false
        },
        flip: {
            type: String,
            default: null,
            validator: (value) => ['horizontal', 'vertical', 'both'].indexOf(value) > -1
        },
        icon: {
            type: [Object, Array, String],
            required: true
        },
        compose: {
            type: [Object, Array, String],
            default: null
        },
        listItem: {
            type: Boolean,
            default: false
        },
        pull: {
            type: String,
            default: null,
            validator: (value) => ['right', 'left'].indexOf(value) > -1
        },
        pulse: {
            type: Boolean,
            default: false
        },
        rotation: {
            type: Number,
            default: null,
            validator: (value) => [0, 90, 180, 270].includes(value)
        },
        size: {
            type: String,
            default: null,
            validator: (value) => ['lg', 'xs', 'sm', '1x', '2x', '3x', '4x', '5x', '6x', '7x', '8x', '9x', '10x'].indexOf(value) > -1
        },
        spin: {
            type: Boolean,
            default: false
        },
        transform: {
            type: [String, Object],
            default: null
        },
        symbol: {
            type: [Boolean, String],
            default: false
        },
        swapOpacity: {
            type: Boolean,
            default: false,
        },
    },

    components: {
        FontAwesomeIcon,
    },

    render(createElement, context) {
        return createElement(FontAwesomeIcon, {
            props: {
                ...context.props,
            },
            ...context.data
        })
    }
}
