// External
import Vue from 'vue';
import _cloneDeep from 'lodash/cloneDeep';
const blank_equipment = {
    reference_type: '',
    reference_id: null,
    equipment_id: null,
    equipment_make_id: null,
    equipment_model_id: null,
    equipment_number: '',
    equipment_type_id: null,
    make: null,
    model: null,
    reference: null,
    reference_history: [],
    calibration: null,
    calibration_history: [],
    serial: '',
    type: null,
}

const state = {
    equipment: {...blank_equipment},
    equipment_types: {
        data: [],
        pagination: {}
    },
    equipment_types_loading: false,
    equipment_type: {
        equipment_type_id: null,
        equipment_type: null,
        options: {},
        custom_fields: []
    },
    default_fields: [
        {
            label: 'Equipment #',
            key: 'equipment_number',
            field_type: 'text',
            is_required: true,
        },
        {
            label: 'Make',
            key: 'make.make',
            field_type: 'select',
            is_required: true,
        },
        {
            label: 'Model',
            key: 'model.model',
            field_type: 'select',
            is_required: false,
        },
        {
            label: 'Serial #',
            key: 'serial',
            field_type: 'text',
            is_required: false,
        },
        {
            label: 'Install Date',
            key: 'install_date',
            field_type: 'date',
            is_required: false,
        }
    ],
    drag_data: null,
};

const mutations = {
    SET_EQUIPMENT(state, equipment) {
        Vue.set(state, 'equipment', equipment);
    },
    SET_EQUIPMENT_TYPE(state, equipment_type) {
        Vue.set(state, 'equipment_type', _cloneDeep(equipment_type));
    },
    SET_EQUIPMENT_TYPE_OPTIONS(state, options) {
        Vue.set(state.equipment_type, 'options', options);
    },
    MERGE_EQUIPMENT_TYPE_OPTIONS(state, options) {
        Vue.set(state.equipment_type, 'options', {
            ...(state.equipment_type?.options || {}),
            ...options
        });
    },
    RESET_EQUIPMENT_TYPE(state) {
        Vue.set(state, 'equipment_type', {
            equipment_type: null,
            options: {},
            custom_fields: []
        });
    },
    SET_EQUIPMENT_TYPES(state, {data, pagination}) {
        state.equipment_types = {
            data, pagination
        };
    },
    SET_EQUIPMENT_TYPES_LOADING(state, loading) {
        state.equipment_types_loading = loading;
    },

    SET_DRAG_DATA(state, drag_data) {
        Vue.set(state, 'drag_data', drag_data);
    }
};

import * as getters from './getters';
import * as actions from './actions';

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
