/**
 *
 * @param commit
 * @param data
 * @param paginator
 */
export function setReferences({commit}, {data, paginator}) {
    commit('SET_REFERENCES', {data, paginator});
}

/**
 *
 * @param commit
 * @param data
 */
export function setReference({commit}, data) {
    commit('SET_REFERENCE', data);
}

/**
 *
 * @param commit
 * @param data
 * @param paginator
 */
export function setInventory({commit}, {data, paginator}) {
    commit('SET_INVENTORY', {data, paginator});
}

/**
 *
 * @param commit
 * @param drag_data
 */
export function setDragData({commit}, drag_data) {
    commit('SET_DRAG_DATA', drag_data);
}

/**
 *
 * @param commit
 * @param item
 */
export function setInventoryItem({commit}, item) {
    commit('SET_ITEM', item);
}

/**
 *
 * @param commit
 */
export function resetInventoryItem({commit}) {
    commit('RESET_ITEM');
}

/**
 *
 * @param commit
 * @param data
 * @param paginator
 */
export function setInventoryEquipment({commit}, {data, paginator}) {
    commit('SET_INVENTORY_EQUIPMENT', {data, paginator});
}

/**
 *
 * @param commit
 * @param inventory_location
 */
export function setInventoryLocation({commit}, inventory_location) {
    commit('SET_INVENTORY_LOCATION', inventory_location);
}

/**
 *
 * @param commit
 * @param {[]} parts
 */
export function setUsedParts({commit}, parts) {
    commit('SET_USED_PARTS', parts);
}

/**
 *
 * @param commit
 * @param {number} inventory_transaction_id
 * @param {ServiceItem} item
 * @param {number} quantity
 * @param {?number} inventory_quantity
 */
export function addUsedPart({commit},  {inventory_transaction_id, item, quantity, inventory_quantity = null}) {
    commit('ADD_USED_PART', {inventory_transaction_id, item, quantity, inventory_quantity});
}
