import { formatDate } from 'kickstart-ui/lib/dates';

/**
 * @param state
 * @return {Certification[]}
 */
export function certifications(state) {
    return state.certifications;
}

/**
 * @param state
 * @return {Certification[]}
 */
export function activeCertifications(state) {
    return state.certifications
        .filter(({expiration_date}) => !expiration_date || expiration_date > formatDate(new Date));
}

/**
 * @param state
 * @return {{}}
 */
export function typesPaginator(state) {
    return state.types_paginator;
}

/**
 * @param state
 * @return {CertificationType[]}
 */
export function types(state) {
    return state.certification_types;
}
