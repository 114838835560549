import Repository from 'Repository';

export class UsersRepository extends Repository {
    constructor() {
        super();

        this.resource = 'users';
    }
}

const Users = new UsersRepository;

export default Users;

/**
 * @typedef User
 * @type {object}
 * @property {number} user_id
 * @property {number} user_role_id
 * @property {string} display_name
 * @property {string} first_name
 * @property {string} last_name
 * @property {?string} email
 * @property {?string} cell_phone
 * @property {?Certification[]} certifications
 * @property {?string} created_at
 * @property {?string} updated_at
 */
