import Repository from '../../../Repository';

export class EntityConfigRepository extends Repository {
    constructor() {
        super();
        this.resource = 'entities/:entity_id/configs'
    }
}

const EntityConfigs = new EntityConfigRepository();

export default EntityConfigs;
