/**
 *
 * @param commit
 * @param equipment_type
 */
export function setEquipmentType({commit}, equipment_type) {
    commit('SET_EQUIPMENT_TYPE', equipment_type);
}

/**
 *
 * @param commit
 * @param data
 * @param pagination
 */
export function setEquipmentTypes({commit}, {data, pagination}) {
    commit('SET_EQUIPMENT_TYPES', {data, pagination});
}

/**
 *
 * @param commit
 */
export function resetEquipmentTypes({commit}) {
    commit('SET_EQUIPMENT_TYPES', {
        data: [],
        pagination: {}
    });
}

/**
 *
 * @param commit
 * @param loading
 */
export function setEquipmentTypesLoading({commit}, loading) {
    commit('SET_EQUIPMENT_TYPES_LOADING', loading);
}

/**
 *
 * @param commit
 */
export function resetEquipmentType({commit}) {
    commit('RESET_EQUIPMENT_TYPE');
}

/**
 *
 * @param commit
 * @param options
 */
export function setEquipmentTypeOptions({commit}, options) {
    commit('SET_EQUIPMENT_TYPE_OPTIONS', options);
}

/**
 *
 * @param commit
 * @param options
 */
export function mergeEquipmentTypeOptions({commit}, options) {
    commit('MERGE_EQUIPMENT_TYPE_OPTIONS', options);
}

/**
 *
 * @param commit
 * @param equipment
 */
export function setEquipment({commit}, equipment) {
    commit('SET_EQUIPMENT', equipment);
}


/**
 *
 * @param commit
 * @param drag_data
 */
export function setDragData({commit}, drag_data) {
    commit('SET_DRAG_DATA', drag_data);
}