/**
 * Set users and paginator into store
 *
 * @param commit
 * @param users
 */
export function setUsers({commit}, users) {
    if ( users.data ) {
        commit('SET_USERS', users.data);
        commit('SET_USERS_PAGINATION', users.paginator);
    } else {
        commit('SET_USERS', users);
    }
}

/**
 *
 * @param commit
 * @param user
 * @param attribute
 */
export function updateUserAttribute({commit}, {user, attribute}) {
    commit('UPDATE_USER_ATTRIBUTE', {user, attribute})
}

/**
 * Set a prop on the invite object
 *
 * @param commit
 * @param where
 * @param value
 */
export function setInviteProp({commit}, {where, value}) {
    commit('SET_INVITE_PROP', {where, value});
}

/**
 *
 * @param commit
 * @param key
 * @param value
 */
export function setInviteData({commit}, {key, value}) {
    commit('SET_INVITE_DATA', {key, value});
}

/**
 * Clear the user invite object
 *
 * @param commit
 */
export function clearInvite({commit}) {
    commit('CLEAR_INVITE');
}

/**
 * Set the user id of the user to edit
 *
 * @param commit
 * @param user_id
 */
export function setUserProfileId({commit}, user_id) {
    commit('SET_USER_PROFILE_ID', user_id);
}

/**
 * Set the user profile object to edit
 *
 * @param commit
 * @param user_profile
 */
export function setUserProfile({commit}, user_profile) {
    commit('SET_USER_PROFILE', user_profile);
}

/**
 * Set attribute on current user profile
 *
 * @param commit
 * @param where
 * @param value
 */
export function setUserProfileAttribute({commit}, {where, value}) {
    commit('SET_USER_PROFILE_ATTRIBUTE', {where, value});
}

/**
 * Clear the user_profile and user_profile_id
 *
 * @param commit
 */
export function clearUserProfile({commit}) {
    commit('CLEAR_USER_PROFILE');
}

/**
 * Update a users data
 *
 * @param commit
 * @param user_profile
 */
export function updateUserProfile({commit}, user_profile) {
    commit('UPDATE_USER_PROFILE', user_profile);
}
/**
 * Remove a user from state
 *
 * @param commit
 */
export function removeUser({commit}) {
    commit('REMOVE_USER');
}


/**
 *
 * ROLES
 *
 */
export function setRoles({commit}, roles) {
    commit('SET_ROLES', roles);
}

/**
 * Set role to be added or edited
 *
 * @param commit
 * @param role
 */
export function setRole({commit}, role) {
    commit('SET_ROLE', role);
}

/**
 *
 * @param commit
 * @param role
 */
export function removeRole({commit}, role) {
    commit('REMOVE_ROLE', role);
}

/**
 *
 * @param commit
 * @param categories
 */
export function setTimeoffCategories({commit}, categories) {
    commit('SET_TIMEOFF_CATEGORIES', categories);
}

/**
 *
 * @param commit
 * @param category
 */
export function addTimeoffCategory({commit}, category) {
    commit('ADD_TIMEOFF_CATEGORY', category);
}
