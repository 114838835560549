/**
 *
 * @param commit
 * @param events
 */
export function setEvents({commit}, events) {
    commit('SET_EVENTS', events)
}

/**
 *
 * @param commit
 * @param webhooks
 */
export function setWebhooks({commit}, webhooks) {
    commit('SET_WEBHOOKS', webhooks);
}