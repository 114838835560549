/**
 * @param commit
 * @param categories
 */
export function setTaskCategories({commit}, categories) {
    commit('SET_TASK_CATEGORIES', categories);
}

/**
 * @param commit
 * @param tasks
 */
export function setTasks({commit}, tasks) {
    commit('SET_TASKS', tasks);
}

/**
 * @param commit
 * @param paginator
 */
export function setTaskPaginator({commit}, paginator) {
    commit('SET_TASKS_PAGINATOR', paginator);
}

/**
 * @param commit
 * @param task_category_id
 */
export function setActiveTaskCategory({commit}, task_category_id) {
    commit('SET_TASK_CATEGORY', task_category_id);
}

/**
 * @param commit
 * @param task_id
 */
export function addSelectedTaskId({commit}, task_id) {
    commit('ADD_SELECTED_TASK_ID', task_id);
}

/**
 * @param commit
 * @param task_id
 */
export function removeSelectedTaskId({commit}, task_id) {
    commit('REMOVE_SELECTED_TASK_ID', task_id);
}

/**
 * @param commit
 */
export function resetSelectedTaskIds({commit}) {
    commit('RESET_SELECTED_TASK_IDS');
}

/**
 * @param commit
 * @param select_all
 */
export function setSelectAll({commit}, select_all) {
    commit('SET_SELECT_ALL', select_all);
}

