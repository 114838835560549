<template>
    <vault-icon :icon="icon" :style="{color: hex}"></vault-icon>
</template>

<script>
    // External Dependencies
    import {faLongArrowRight} from '@fortawesome/pro-regular-svg-icons/faLongArrowRight';

    // Internal Dependencies
    import BaseIcon from './BaseIcon';

    export default BaseIcon.extend({
        name: 'IconArrowRight',

        props: {
            outline: {
                default: false
            }
        },

        data() {
            return {
                icon: faLongArrowRight,
            }
        }
    });
</script>
