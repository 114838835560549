import { setUser } from '@sentry/browser';

/**
 * Set the active user for logging Sentry exceptions.
 *
 * @param {string|null} email
 */
export const setSentryUser = (email) => {
    if ( email ) {
        setUser({email});
    } else {
        setUser(null);
    }
}
