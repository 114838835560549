// External Dependencies
import Cookie from 'js-cookie';
import store from '@/store';

export function createVersionInterceptor(Vue) {
    Vue.api.getInstance().interceptors.response.use(response => {
        let version = Cookie.get('v-ver');

        if ( store.state.app_version != version ) {
            store.dispatch('setAppVersion', version);
        }

        return response;
    });
}
