import Repository from '../../../Repository';

export class ServiceItemRepository extends Repository {
    constructor() {
        super();
        this.resource = 'price-items';
    }
}

const ServiceItems = new ServiceItemRepository();

export default ServiceItems;

/**
 * @typedef ServiceItem
 * @type {object}
 * @property {number} service_item_id
 * @property {number} service_item_class_id
 * @property {number} tax_category_id
 * @property {?number} manufacturer_id
 * @property {string} name
 * @property {string} description
 * @property {string} item_class
 * @property {number} default_cost
 * @property {number} default_price
 * @property {?string} color
 * @property {string} type
 * @property {string} type_name
 * @property {string} user_defined_number
 * @property {?number} inventory_transaction_id
 */
