import {object_get} from 'kickstart-ui/lib/objects';

/**
 *
 * @param state
 * @return {Function}
 */
export function getFilter(state) {
    return (namespace, where) => {
        if ( state.filters[namespace] ) {
            return object_get(state.filters[namespace], where);
        }
    };
}

/**
 *
 * @param state
 * @return {function(*=)}
 */
export function filters(state) {
    return state.filters;
}

/**
 * @param state
 * @returns {*}
 */
export function dependent_filters(state) {
    return state.dependent_filters;
}
