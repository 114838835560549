import {PointArray} from '@svgdotjs/svg.js';

/**
 * @param degrees
 * @returns {number}
 */
export function calculateRotation(degrees = 0) {
    return parseFloat(((degrees % 360 + 360) % 360).toFixed(2));
}

/**
 * @param number
 * @param decimal
 * @returns {number}
 */
export function floatRound(number, decimal = 2) {
    return parseFloat(parseFloat(number).toFixed(decimal));
}

/**
 * @param element
 * @param box
 * @returns {{rotate: *, cx: number, cy: number, x: number, y: number}}
 */
export function rotatedBBox(element, box = null) {
    if ( box === null ) {
        box = element.bbox();
    }

    const rotate = element.transform().rotate;
    const ctm = element.node.getCTM();
    const cx = ctm.e + box.cx * ctm.a + box.cy * ctm.c;
    const cy = ctm.f + box.cx * ctm.b + box.cy * ctm.d;
    const x = cx - (box.width / 2);
    const y = cy - (box.height / 2);

    return {
        rotate,
        x,
        y,
        cx,
        cy
    }
}

/**
 * @param height
 * @param width
 * @param factor
 * @returns {number}
 */
export function calculateRoundedRadius({height, width, factor}) {
    factor = factor < 0 ? factor : factor / 100;

    return Math.min(width * factor, height * factor);
}

/**
 * @param element
 * @returns {boolean}
 */
export function isRounded(element) {
    const attr = element.attr(['rx', 'ry']);

    return element.type === 'rect' && attr.rx !== 0 && attr.ry !== 0;
}

/**
 * @param element
 */
export function updateRoundedRadius(element) {
    if ( !isRounded(element) ) return;

    const RADIUS = calculateRoundedRadius({
        height: element.height(),
        width: element.width(),
        factor: element.data('rounded-factor') || 0.15
    });

    element.radius(RADIUS, RADIUS);
}

/**
 * @param element
 * @param box
 * @returns {*}
 */
export function resizePolygon(element, box) {
    const proportionalSize = (width, height) => {
        if (width == null || height == null) {
            const bbox = element.bbox()

            if (width == null) {
                width = bbox.width / bbox.height * height
            } else if (height == null) {
                height = bbox.height / bbox.width * width
            }
        }

        return {width, height}
    }

    const p = proportionalSize(box.width, box.height);
    const points = new PointArray(element.attr('points'));

    element.attr('points', points.size(p.width, p.height));

    return element;
}

/**
 * @param element
 * @param class_name
 * @returns {boolean}
 */
export function hasClass(element, class_name) {
    if ( element.classList ) {
        return element.classList.contains(class_name);
    }

    return new RegExp('(^| )' + class_name + '( |$)', 'gi').test(element.class_name);
}

/**
 * @param element
 * @param class_name
 */
export function addClass(element, class_name) {
    if ( element.classList ) {
        element.classList.add(class_name);
    } else {
        element.className += ' ' + class_name;
    }
}

/**
 * @param element
 * @param class_name
 */
export function removeClass(element, class_name) {
    if ( element.classList ) {
        return element.classList.remove(class_name);
    }

    element.class_name = element.class_name.replace(new RegExp('(^|\\b)' + class_name.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
}