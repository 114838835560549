<template>
    <div class="page-header row">
        <div class="sm-12">
            <h1 class="heading-label">
                <slot></slot>
            </h1>
            <div class="heading-actions">
                <slot name="actions"></slot>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'PageHeader'
    }
</script>
