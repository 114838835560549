<template>
    <vault-icon :icon="icon" :style="{color: hex}"></vault-icon>
</template>

<script>
    // External Dependencies
    import {faCreditCardFront} from '@fortawesome/pro-solid-svg-icons/faCreditCardFront';

    // Internal Dependencies
    import BaseIcon from './BaseIcon';

    export default BaseIcon.extend({
        name: 'IconWallet',

        data() {
            return {
                icon: faCreditCardFront,
            }
        }
    })
</script>