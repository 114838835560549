<template>
    <vault-icon :icon="icon" :style="{color: hex}"></vault-icon>
</template>

<script>
    // External Dependencies
    import {faCalendarAlt} from '@fortawesome/pro-duotone-svg-icons/faCalendarAlt';

    // Internal Dependencies
    import BaseIcon from './BaseIcon';

    export default BaseIcon.extend({
        name: 'IconCalendar',

        data() {
            return {
                icon: faCalendarAlt,
            }
        }
    });
</script>
