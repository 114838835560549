/**
 *
 * @param commit
 * @param {Ftp[]} ftps
 */
export function setFtps({ commit }, ftps) {
    commit('SET_FTPS', ftps);
}

/**
 *
 * @param commit
 * @param {object[]} ftp_configs
 */
export function setFtpConfigs({ commit }, ftp_configs) {
    commit('SET_FTP_CONFIGS', ftp_configs);
}

/**
 * Update the FTP property on a config
 *
 * @param commit
 * @param ftp
 * @param config
 */
export function setFtpConfigFtp({ commit }, { ftp, config }) {
    commit('SET_FTP_CONFIG_FTP', { ftp, config });
}
