/**
 * Get users entity id
 *
 * @param state
 * @returns {number}
 */
export function entity_id(state) {
    return state.entity_id;
}

/**
 * Get entity object
 *
 * @param state
 * @returns {state.entity|{entity_name}}
 */
export function entity(state) {
    return state.entity;
}

/**
 *
 * @param state
 * @return {Array}
 */
export function breadcrumbs(state) {
    return state.breadcrumbs;
}

/**
 *
 * @param state
 * @returns {number}
 */
export function user_id(state) {
    return state.user_id;
}

/**
 *
 * @param state
 * @returns {string}
 */
export function user_display(state) {
    return state.user_display;
}

/**
 *
 * @param state
 * @returns {number}
 */
export function user_role_id(state) {
    return state.user_role_id;
}

/**
 *
 * @param state
 * @returns {string}
 */
export function username(state) {
    return state.username;
}

/**
 *
 * @param state
 * @returns {number}
 */
export function user_customer_id(state) {
    return state.user_customer_id;
}

/**
 * @param state
 * @returns {boolean}
 */
export function is_customer(state) {
    return typeof state.user_customer_id !== 'undefined';
}

/**
 *
 * @param state
 * @returns {default.watch.permissions|[]|{}|[string]|Permissions}
 */
export function permissions(state) {
    return state.permissions;
}

/**
 *
 * @param state
 * @returns {boolean}
 */
export function app_loaded(state) {
    return state.app_loaded;
}

/**
 * Get user role object
 *
 * @param state
 * @returns {state.role|{role_name, role_code}}
 */
export function role(state) {
    return state.role;
}

/**
 * Reset export filters
 *
 * @param state
 * @returns {state.export_filters|{}}
 */
export function allExportFilters(state) {
    return state.export_filters;
}

/**
 * Get Integration Options
 *
 * @param state
 * @returns {state.integrations}
 */
export function integration_options(state) {
    return state.integrations;
}

/**
 * Get Integration List
 *
 * @param state
 * @returns {state.integration_list}
 */
export function integration_list(state) {
    return state.integration_list;
}

/**
 *
 * @param state
 * @returns {string}
 */
export function user_type(state) {
    return state.user_type;
}

/**
 *
 * @param state
 * @returns {boolean}
 */
export function is_system_admin(state) {
    return state.system_admin;
}

/**
 *
 * @param state
 * @returns {boolean}
 */
export function is_impersonating(state) {
    return state.is_impersonating;
}

/**
 *
 * @param state
 * @returns {string}
 */
export function app_version(state) {
    return state.app_version;
}

/**
 *
 * @param state
 * @returns {boolean}
 */
export function refresh_required(state) {
    return state.refresh_required;
}

/**
 *
 * @param state
 * @returns {[]}
 */
export function tax_categories(state) {
    return state.tax_categories;
}

/**
 * @param state
 * @returns {number}
 */
export function has_task_categories(state) {
    return state.has_task_categories;
}

/**
 *
 * @param state
 * @returns {Mention[]}
 */
export function mentions(state) {
    return state.mentions;
}

/**
 *
 * @param state
 * @returns {number}
 */
export function unreadMentionsCount(state) {
    return state.unread_mentions_count;
}

/**
 *
 * @param state
 * @returns {[string]|string[]|*}
 */
export function user_types(state) {
    return state.user_types;
}

/**
 *
 * @param state
 * @returns {{}}
 */
export function user_type_access(state) {
    let i = 0;
    return state.user_types.reduce((carry, role_type) => {
        if ( role_type === 'sub_contractor' ) {
            carry[role_type] = ['sub_contractor'];
        } else {
            carry[role_type] = state.user_types.slice(i)
        }
        i++;
        return carry;
    }, {});
}

/**
 *
 * @param state
 * @param getters
 * @returns {function(*)}
 */
export function has_access_to_role(state, getters) {
    return (role) => {
        let permission_codes = Object.entries(state.permissions).map(([permission_code]) => {
            return permission_code;
        });

        return state.user_type === 'account_admin' || (
            // Role type must be in the accessible list of role types
            getters.user_type_access[state.user_type].indexOf(role.role_type) !== -1 &&
            // role must not have permissions you don't have access to
            role.permissions.map(p => p.permission_code).filter(code => !permission_codes.includes(code)).length === 0
        );
    }
}
