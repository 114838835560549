/**
 * @param state
 * @returns {null}
 */
export function diagram(state) {
    return state.diagram;
}

/**
 * Get the diagram. This is a reference to the svg library
 *
 * @param state
 * @returns {null}
 */
export function svg(state) {
    return state.svg;
}

/**
 * Get the elements selected in the diagram
 *
 * @param state
 * @returns {*}
 */
export function selected(state) {
    if ( !state.selected_ids.length ) {
        return [];
    }

    return state.selected;
}

/**
 * @param state
 * @returns {*[]|*}
 */
export function elements(state) {
    if ( !state.element_ids.length ) {
        return [];
    }


    return state.elements;
}

/**
 * @param state
 * @returns {[]}
 */
export function shapes(state) {
    return state.shapes;
}

/**
 * @param state
 * @returns {[]}
 */
export function custom_elements(state) {
    return state.custom_elements;
}

/**
 * @param state
 * @returns {[string, unknown]}
 */
export function all_custom_elements(state) {
    return Object.entries(state.custom_elements)
        .reduce((acc, [,items]) => acc.concat(items?.elements || []), [])
}

/**
 * @param state
 * @returns {string}
 */
export function diagram_movement(state) {
    return state.diagram_movement;
}

/**
 * @param state
 * @returns {boolean}
 */
export function has_history(state) {
    return state.history.length > 0;
}

/**
 * @param state
 * @returns {boolean}
 */
export function has_redo(state) {
    return state.redo.length > 0;
}

/**
 * @param state
 * @returns {*}
 */
export function diagram_errors(state) {
    return state.errors;
}

/**
 * @param state
 * @returns {*}
 */
export function is_dirty(state) {
    return state.dirty;
}

/**
 * @param state
 * @returns {boolean|*}
 */
export function is_editing_custom_element(state) {
    return Object.keys(state.editing_custom_element).length > 0;
}

/**
 * @param state
 * @returns {{}|*}
 */
export function editing_custom_element(state) {
    return state.editing_custom_element;
}