const state = {
    notification_profiles: []
};

const mutations = {
    SET_NOTIFICATION_PROFILES(state, profiles) {
        state.notification_profiles = profiles;
    },
};

import * as getters from './getters';
import * as actions from './actions';

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
