import _throttle from 'lodash/throttle';
import {
    getAllFormConditions,
    getAllFormSectionConditions,
    getConditionCounties,
    getConditionServiceItems,
    getFormTriggerConditions,
    getValidationConditions,
} from 'helpers/conditions.js';
import { EQUIPMENT_DEFAULT_FIELDS } from '@Vault/Equipment/repositories/EquipmentTypesRepository.js';

/**
 * @param equipment
 * @param {string} field
 * @returns {string|null}
 */
function getEquipmentDefaultFieldValue(equipment, field) {
    switch ( field ) {
        case 'equipment_number':
        case 'install_date':
        case 'serial':
            return equipment[field] || null;

        case 'make':
            return equipment.make?.make || null;

        case 'model':
            return equipment.model?.model || null;
    }
}

/**
 *
 * @param commit
 * @param forms
 */
export function setForms({commit}, forms) {
    commit('SET_FORMS', forms);
}

/**
 *
 * @param commit
 * @param dispatch
 * @param form
 */
export function setForm({commit, dispatch}, form) {
    commit('SET_FORM', form);

    let conditions = getAllFormConditions(form);
    dispatch('locations/loadCountyData', getConditionCounties(conditions), { root: true });

    /** @see loadServiceItemData() */
    dispatch('fields/loadServiceItemData', getConditionServiceItems(conditions), { root: true });
}

/**
 *
 * @param commit
 */
export function resetForm({commit}) {
    commit('RESET_FORM');
}

/**
 *
 * @param commit
 * @param sections
 */
export function setFormSections({commit}, sections) {
    commit('SET_FORM_SECTIONS', sections);
}

/**
 *
 * @param commit
 * @param dispatch
 * @param section
 */
export function setSection({ commit, dispatch }, section) {
    commit('SET_SECTION', section);

    let conditions = getAllFormSectionConditions(section);
    dispatch('locations/loadCountyData', getConditionCounties(conditions), { root: true });

    /** @see loadServiceItemData() */
    dispatch('fields/loadServiceItemData', getConditionServiceItems(conditions), { root: true });
}

/**
 *
 * @param commit
 * @param sections
 */
export function setSectionSort({commit}, sections) {
    commit('SET_SECTION_SORT', sections);
}

/**
 *
 * @param commit
 */
export function resetSection({commit}) {
    commit('RESET_SECTION');
}

/**
 *
 * @param commit
 * @param field
 */
export function setField({commit}, field) {
    commit('SET_FIELD', field);
}

/**
 *
 * @param commit
 */
export function newField({commit}) {
    commit('NEW_FIELD');
}

/**
 *
 * @param commit
 */
export function resetField({commit}) {
    commit('RESET_FIELD');
}

/**
 *
 * @param commit
 * @param fields
 */
export function setSectionFields({commit}, fields) {
    commit('SET_SECTION_FIELDS', fields);
}

/**
 *
 * @param commit
 * @param fields
 */
export function setEquipmentTypeFields({commit}, fields) {
    commit('SET_EQUIPMENT_TYPE_FIELDS', fields);
}

/**
 *
 * @param commit
 * @param fields
 */
export function setFormulaSuggestions({commit}, fields) {
    commit('SET_FORMULA_SUGGESTIONS', fields);
}

/**
 *
 * @param commit
 * @param fields
 */
export function setReadOnlySuggestions({commit}, fields) {
    commit('SET_READ_ONLY_SUGGESTIONS', fields);
}

/**
 *
 * @param commit
 * @param entry
 */
export function setEntry({commit}, entry) {
    commit('SET_ENTRY', entry);
}

/**
 *
 * @param commit
 * @param sections
 */
export function setEntryFormSections({commit}, sections) {
    sections = sections.filter((section) => {
        return section.fields.length || section.equipment_type_id;
    })

    commit('SET_ENTRY_FORM_SECTIONS', sections);
}

/**
 *
 * @param commit
 * @param values
 */
export function setEntryValues({commit}, values) {
    commit('SET_VALUES', values);
}

/**
 *
 * @param commit
 * @param {number} form_section_id
 * @param {number} value_index
 * @param equipment
 */
export function setSectionEquipmentFieldValues({commit}, {form_section_id, value_index = 0, equipment}) {
    if ( !equipment ) {
        return;
    }

    let values = (equipment.field_values || [])
        .map(({field, value}) => ({
            reference_key: `custom_field:${field.custom_field_id}`,
            value,
            multi_value: field.is_multi_value_field,
        }))
        .concat(EQUIPMENT_DEFAULT_FIELDS
            .map(field => ({
                reference_key: `equipment_type:${equipment.equipment_type_id}:${field}`,
                value: getEquipmentDefaultFieldValue(equipment, field),
                multi_value: false,
            }))
        );

    commit('SET_FORM_REFERENCE_VALUES', [
        ...values.map(value => ({context: 'form', context_id: form_section_id, value_index, ...value})),
        ...values.map(value => ({context: 'equipment', context_id: equipment.equipment_id, ...value})),
    ]);
}

/**
 *
 * @param commit
 * @param files
 */
export function setEntryFiles({commit}, files) {
    commit('SET_FILES', files);
}

/**
 *
 * @param commit
 * @param section_id
 * @param value_index
 * @param custom_field_id
 * @param files
 */
export function setEntrySectionFieldFiles({commit}, {section_id, value_index, custom_field_id, files}) {
    commit('SET_SECTION_FIELD_FILES', {section_id, value_index, custom_field_id, files});
}

/**
 *
 * @param commit
 * @param section_id
 * @param value
 * @param value_index
 */
export function setEntryFieldValue({commit}, {section_id, value, value_index}) {
    commit('SET_FIELD_VALUE', {section_id, value, value_index});
}

/**
 *
 * @param commit
 */
export function resetFormReferenceValues({commit}) {
    commit('RESET_FORM_REFERENCE_VALUES');
}

/**
 * Set the value for a form reference.  Value updates are de-duplicated and committed in throttled batches every 50ms
 * to avoid firing unnecessary mutations.
 *
 * @param commit
 * @param {string} reference_key
 * @param {string} context
 * @param {number} context_id
 * @param {number} value_index
 * @param {number|string} value
 * @param {boolean} multi_value
 */
export function setFormReferenceValue({commit}, {reference_key, context, context_id, value_index = 0, value, multi_value = false}) {
    // Get the payload destination to use as the key in the staged updates map.  Additional updates within the throttle
    // delay that target this destination will overwrite the previous one, so the latest version is always committed.
    const key = JSON.stringify({reference_key, context, context_id, value_index});

    // Stage the payload and signal the mutation.
    staged_reference_value_updates.set(key, {reference_key, context, context_id, value_index, value, multi_value});
    throttledSetFormReferenceValues(commit);
}

const staged_reference_value_updates = new Map();
const throttledSetFormReferenceValues = _throttle(commit => {
    // Commit and clear staged updates
    commit('SET_FORM_REFERENCE_VALUES', Array.from(staged_reference_value_updates.values()))
    staged_reference_value_updates.clear();
}, 50);

/**
 *
 * @param commit
 * @param {object[]} form_reference_values
 * @param {string} form_reference_values[].reference_key
 * @param {string} form_reference_values[].context
 * @param {number} form_reference_values[].context_id
 * @param {number} form_reference_values[].value_index
 * @param {number|string} form_reference_values[].value
 * @param {boolean} form_reference_values[].multi_value
 */
export function setFormReferenceValues({commit}, form_reference_values) {
    // don't commit unnecessary mutations
    if ( form_reference_values.length > 0 ) {
        commit('SET_FORM_REFERENCE_VALUES', form_reference_values);
    }
}

/**
 *
 * @param commit
 * @param {object[]} form_reference_values
 * @param {string} form_reference_values[].reference_key
 * @param {string} form_reference_values[].context
 * @param {number} form_reference_values[].context_id
 * @param {number} form_reference_values[].value_index
 */
export function unsetFormReferenceValues({commit}, form_reference_values) {
    // don't commit unnecessary mutations
    if ( form_reference_values.length > 0 ) {
        commit('UNSET_FORM_REFERENCE_VALUES', form_reference_values);
    }
}

/**
 *
 * @param commit
 * @param {string} reference_key
 * @param {string} context
 * @param {number} context_id
 * @param {number} value_index
 */
export function unsetFormReferenceValue({commit}, {reference_key, context, context_id, value_index = 0}) {
    commit('UNSET_FORM_REFERENCE_VALUE', {reference_key, context, context_id, value_index});
}

/**
 *
 * @param commit
 */
export function resetStore({commit}) {
    commit('RESET_STORE');
}

/**
 *
 * @param commit
 * @param location
 */
export function setEntryLocation({commit}, location) {
    commit('SET_ENTRY_LOCATION', location);
}

/**
 *
 * @param commit
 * @param customer
 */
export function setEntryCustomer({commit}, customer) {
    commit('SET_ENTRY_CUSTOMER', customer);
}

/**
 *
 * @param commit
 * @param entry_sections
 */
export function setEntrySections({commit}, entry_sections) {
    commit('SET_ENTRY_SECTION_DATA', entry_sections);
}

/**
 *
 * @param commit
 * @param entry_sections
 */
export function mergeEntrySections({commit}, entry_sections) {
    commit('MERGE_ENTRY_SECTION_DATA', entry_sections);
}

/**
 *
 * @param commit
 * @param {number} form_section_id
 * @param {number} value_index
 * @param {Equipment} equipment
 * @param {boolean} update_all
 */
export function setEntrySectionEquipment({commit}, {form_section_id, value_index, equipment, update_all = true}) {
    commit('SET_ENTRY_SECTION_EQUIPMENT', {form_section_id, value_index, equipment, update_all});
}

/**
 *
 * @param commit
 * @param dispatch
 * @param form_triggers
 */
export function setFormTriggers({commit, dispatch}, form_triggers) {
    commit('SET_FORM_TRIGGERS', form_triggers)

    let conditions = getFormTriggerConditions(form_triggers);
    dispatch('locations/loadCountyData', getConditionCounties(conditions), { root: true });

    /** @see loadServiceItemData() */
    dispatch('fields/loadServiceItemData', getConditionServiceItems(conditions), { root: true });
}

/**
 * @param commit
 * @param value
 * @param custom_field_id
 */
export function updateSectionValue({commit}, {value, custom_field_id}) {
    commit('UPDATE_SECTION_VALUE', {value, custom_field_id});
}

/**
 * @param commit
 * @param index
 */
export function setSectionIndex({commit}, index) {
    commit('SET_SECTION_INDEX', index);
}

/**
 *
 * @param commit
 * @param dispatch
 * @param validation
 */
export function setResultValidation({commit, dispatch}, validation) {
    commit('SET_RESULT_VALIDATION', validation);

    let conditions = getValidationConditions(validation);
    dispatch('locations/loadCountyData', getConditionCounties(conditions), { root: true });

    /** @see loadServiceItemData() */
    dispatch('fields/loadServiceItemData', getConditionServiceItems(conditions), { root: true });
}

/**
 *
 * @param commit
 * @param context_date
 */
export function setEntryContextDate({commit}, context_date) {
    commit('SET_ENTRY_CONTEXT_DATE', context_date);
}

/**
 *
 * @param commit
 * @param inventory_location_id
 */
export function setEntryInventoryLocationId({commit}, inventory_location_id) {
    commit('SET_ENTRY_INVENTORY_LOCATION_ID', inventory_location_id);
}

/**
 *
 * @param commit
 * @param values
 */
export function patchEntryValues({commit}, values) {
    commit('PATCH_ENTRY_VALUES', values);
}

/**
 *
 * @param commit
 * @param form_section_id
 * @param value_index
 * @param equipment
 */
export function insertEntryValue({commit}, {form_section_id, value_index, equipment}) {
    commit('INSERT_ENTRY_VALUE', {form_section_id, value_index, equipment});
}

/**
 *
 * @param commit
 * @param form_section_id
 * @param value_index
 */
export function removeEntryValue({commit}, {form_section_id, value_index}) {
    commit('REMOVE_ENTRY_VALUE', {form_section_id, value_index});
}

/**
 *
 * @param commit
 * @param section_id
 * @param list
 */
export function setEntrySectionList({commit}, {section_id, list}) {
    commit('SET_ENTRY_SECTION_LISTS', {section_id, list});
}

/**
 *
 * @param commit
 * @param section_id
 */
export function sortEntrySectionList({commit}, section_id) {
    commit('SORT_ENTRY_SECTION_LIST', section_id);
}

/**
 *
 * @param commit
 */
export function resetEntrySectionLists({commit}) {
    commit('RESET_ENTRY_SECTION_LISTS');
}
/**
 * @param commit
 * @param cache_key
 */
export function setPrintViewCacheKey({commit}, cache_key) {
    commit('SET_PRINT_VIEW_CACHE_KEY', cache_key);
}

/**
 * @param commit
 * @param print_view
 */
export function setFormPrintView({commit}, print_view) {
    commit('SET_PRINT_VIEW', print_view);
}

/**
 * @param commit
 * @param print_view_pages
 */
export function updateFormPrintViewPages({commit}, print_view_pages) {
    commit('UPDATE_PRINT_VIEW_PAGES', print_view_pages);
}

/**
 * Unsets form reference values and values at the current section
 *
 * @param commit
 * @param fields
 * @param value_index
 * @param form_section_id
 */
export function unsetSectionFieldValues({commit}, {fields, value_index, form_section_id}) {
    commit('UNSET_SECTION_FIELD_VALUES', {fields, value_index, form_section_id});
}

/**
 * @param commit
 * @param form_print_view_page_id
 * @param conditions
 */
export function updatePrintViewPageConditions({commit}, {form_print_view_page_id, conditions}) {
    commit('UPDATE_PRINT_VIEW_PAGE_CONDITIONS', {form_print_view_page_id, conditions});
}
