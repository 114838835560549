<template>
    <vault-icon :icon="icon" :style="{color: hex}"></vault-icon>
</template>

<script>
    // External Dependencies
    import {faSlidersV} from '@fortawesome/pro-solid-svg-icons/faSlidersV';

    // Internal Dependencies
    import BaseIcon from './BaseIcon';

    export default BaseIcon.extend({
        name: 'IconTuning',

        data() {
            return {
                icon: faSlidersV,
            }
        }
    })
</script>
