import Vue from 'vue';

const blank_invoice = {
    invoice_number: '',
    invoice_date: null,
    invoice_notes: '',
    customer_id: '',
    customer_contact_id: '',
    location_id: '',
    sales_tax: '',
    published: null,
    customer: null,
    location: null,
    contact: null,
    items: [],
    pending_jobs: [],
}

const state = {
    pagination: {
        invoices: {}
    },
    invoices: [],
    invoice: () => Object.assign({}, blank_invoice),
    taxable_state: {
        state: null,
        is_taxable: false,
        is_loading: false,
        request_canceler: null
    },
};

const mutations = {
    SET_INVOICES (state, invoices) {
        if ( 'paginator' in invoices ) {
            Vue.set(state.pagination, 'invoices', invoices.paginator);
            state.invoices = invoices.data;
        } else {
            state.invoices = invoices;
        }
    },
    REMOVE_INVOICE (state, invoice) {
        let index = state.invoices.indexOf(invoice);

        if ( index > -1 ) {
            state.invoices.splice(index, 1);
        }
    },
    RESET_INVOICE (state) {
        Vue.set(state, 'invoice', Object.assign({}, blank_invoice));
        Vue.set(state.invoice, 'items', []);
    },
    SET_INVOICE_DATE(state, date) {
        state.invoice.invoice_date = date;
    },
    SET_INVOICE(state, invoice) {
        state.invoice = Object.assign({}, invoice);

        if ( invoice.contact ) {
            state.invoice.customer = invoice.contact.customer;
            state.invoice.customer_id = invoice.contact.customer_id;
        }
    },
    SET_INVOICE_ITEMS(state, items) {
        Vue.set(state.invoice, 'items', items);
    },
    SET_INVOICE_PENDING_JOBS(state, pending_jobs) {
        Vue.set(state.invoice, 'pending_jobs', pending_jobs);
    },
    ADD_INVOICE_ITEM(state, item) {
        state.invoice.items.push(item);
    },
    DELETE_INVOICE_ITEM(state, item) {
        let index = state.invoice.items.indexOf(item);

        if ( -1 !== index ) {
            state.invoice.items.splice(index, 1);
        } else {
            console.error('Could not find item', 'item')
        }
    },
    UPDATE_INVOICE_ITEM(state, item) {
        let index = state.invoice.items.map(item => item.service_item_xref_id)
            .indexOf(item.service_item_xref_id);

        if ( -1 !== index ) {
            Vue.set(state.invoice.items, index, item);
        } else {
            console.error('Could not find item', item);
        }
    },
    UPDATE_INVOICE_ITEM_PROP(state, {item, property, value}) {
        let index = 'service_item_xref_id' in item
            ? state.invoice.items.map(inv_item => inv_item.service_item_xref_id).indexOf(item.service_item_xref_id)
            : state.invoice.items.indexOf(item);

        if ( -1 !== index ) {
            item = state.invoice.items[index];

            Vue.set(item, property, value);
        } else {
            console.error('Could not find item prop', item, property);
        }
    },
    ADD_INVOICE_WORK(state, work) {
        state.work.push(work);
    },
    SET_INVOICE_WORK(state, work) {
        Vue.set(state.invoice, 'work', work);
    },
    SET_INVOICE_VISITS(state, visits) {
        Vue.set(state.invoice, 'visits', visits);
    },
    SET_INVOICE_CUSTOMER(state, customer) {
        Vue.set(state.invoice, 'customer', customer);
    },
    SET_INVOICE_LOCATION(state, location) {
        Vue.set(state.invoice, 'location', location);
    },
    SET_INVOICE_CONTACT(state, contact) {
        Vue.set(state.invoice, 'contact', contact);
    },
    SET_SALES_TAX(state, sales_tax) {
        Vue.set(state.invoice, 'sales_tax', parseFloat(sales_tax));
    },
    SET_IS_TAXABLE_STATE_LOADING(state, is_taxable_state_loading) {
        Vue.set(state.taxable_state, 'is_loading', is_taxable_state_loading);
    },
    SET_IS_TAXABLE_STATE(state, is_taxable_state) {
        Vue.set(state.taxable_state, 'is_taxable', is_taxable_state);
    },
    SET_STATE_USED_FOR_TAX(state, state_used_for_tax) {
        Vue.set(state.taxable_state, 'state', state_used_for_tax);
    },
    SET_TAXABLE_STATE_REQUEST_CANCELER(state, request_canceler) {
        Vue.set(state.taxable_state, 'request_canceler', request_canceler);
    }
};

import * as getters from './getters';
import * as actions from './actions';

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
