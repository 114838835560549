import {isString} from './types';

/**
 * @param str
 * @returns {string}
 */
export function capitalize(str) {
    if (!isString(str)) {
        throw new Error('capitalize requires a string argument');
    }

    if (str.length === 0) {
        return '';
    }

    if (str.length === 1) {
        return str.toUpperCase();
    }

    return str[0].toUpperCase() + str.slice(1);
}

/**
 * Pluralize some text based on the count provided.
 *   pluralize(0, 'field')              // => '0 fields'
 *   pluralize(1, 'marsh', 'marshes')   // => '1 marsh'
 *   pluralize(2, 'deer', 'deer')       // => '2 deer'
 *
 * @param {number|string} count - the number of items
 * @param {string} singular - the singular form of the word
 * @param {string} plural - the plural form of the word (if left blank adds an 's' to the singular)
 * @returns {string}
 */
export function pluralize(count, singular, plural = singular + 's') {
    return count == 1
        ? `${count} ${singular}`
        : `${count} ${plural}`;
}

/**
 *
 * @param {string} str
 * @returns {string}
 */
export function snakeToTitle(str) {
    return str.split('_').map(function (item) {
        return item.charAt(0).toUpperCase() + item.substring(1);
    }).join(' ');
}


/**
 * @link https://stackoverflow.com/a/67243723/4468423
 * @param {string} str
 * @returns {string}
 */
export function camelToKebab(str) {
    return str.replace(/[A-Z]+(?![a-z])|[A-Z]/g, ($, ofs) => (ofs ? '-' : '') + $.toLowerCase());
}

/**
 * Get the ordinal of the given number, e.g. 1st, 2nd, 3rd, 22nd, etc.
 * @param number
 * @returns {string}
 */
export function ordinal(number) {
    let str_number = String(number);
    let last = str_number.slice(-1);

    switch ( true ) {
        case ['11', '12', '13'].includes(str_number):
        case ['0', '4', '5', '6', '7', '8', '9'].includes(last):
            return number + 'th';
        case last == 1:
            return number + 'st';
        case last == 2:
            return number + 'nd';
        case last == 3:
            return number + 'rd';
    }
}

/**
 * Get a random alphanumeric string with the given length (up to 11 chars)
 * @param {number} [length=6]
 * @returns {string}
 */
export function randomChars(length = 6) {
    return Math.random().toString(36).substring(2, length + 2);
}

/**
 *
 * @param number
 * @param decimals
 * @returns {number}
 */
export function round(number, decimals = 2) {
    let factor = Number('1' + '0'.repeat(decimals));

    return Math.round(Number(number) * factor)/factor;
}


/**
 *
 * @param {string} text
 * @returns {string}
 */
export function nl2br(text) {
    return text.replaceAll("\n", "<br>");
}
