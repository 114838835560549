import Vue from 'vue';

export default Vue.extend({
    props: {
        color: {
            default: 'inherit'
        },

        size: {
            default: '14'
        },

        muted: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        hex() {
            if ( this.muted ) {
                return '#888';
            }

            return `#${this.color}`;
        },

        dimensions() {
            return `${this.size}px`;
        }
    }
});
