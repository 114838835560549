import _get from 'lodash/get';
import _pickBy from 'lodash/pickBy';

/**
 * Get the location customer for the work order
 *
 * @param state
 * @returns {state.customer|{customer_name, customer_id, contacts, default_contact}|null}
 */
export function customer(state) {
    return state.customer;
}

/**
 * Get the billing customer for the work order
 *
 * @param state
 * @returns {state.billing_customer|{customer_name, customer_id, contacts, default_contact}|null}
 */
export function billing_customer(state) {
    return state.billing_customer;
}

/**
 * Get the location for the work order
 *
 * @param state
 * @returns {null|state.location|{}}
 */
export function location(state) {
    return state.location;
}

/**
 *
 * @param state
 * @returns {null}
 */
export function location_diagram(state) {
    return state.location_diagram;
}

/**
 *
 * @param state
 * @returns {null|state.location.delivery_address|[]}
 */
export function location_delivery_addresses(state) {
    if ( state.location ) {
        if ( 'delivery_address' in state.location ) {
            return state.location.delivery_address;
        }
    }

    return null;
}

/**
 *
 * @param state
 * @returns {null|state.location.field_values|[]}
 */
export function location_field_values(state) {
    if ( state.location ) {
        if ( 'field_values' in state.location ) {
            return state.location.field_values;
        }
    }

    return null;
}

/**
 * Get the work order
 *
 * @param state
 * @returns {*}
 */
export function work_order(state) {
    return state.work_order;
}

/**
 * Get the work order items
 *
 * @param state
 * @returns {*}
 */
export function items(state) {
    return state.work_order.items;
}

/**
 * Get the file that are on the work order
 *
 * @param state
 */
export function files(state) {
    return state.work_order.files;
}

/**
 *
 * @param state
 * @returns {default.watch.generating_visits|boolean}
 */
export function generating_visits(state) {
    return state.generating_visits;
}

/**
 *--------------------------------------------------
 * Bulk Workorder Actions
 *--------------------------------------------------
 */
export function selected_workorder_ids(state) {
    return (key) => {
        return key in state.bulk.selected
            ? Object.keys(
                _pickBy(_get(state.bulk.selected, key, {}), (o) => {
                    return o;
                })
            )
            : [];
    }
}

/**
 *
 */
export function num_selected_workorders(state) {
    return (key) => {
        return Object.keys(_pickBy(_get(state.bulk.selected, key, {}), o => o)).length;
    }
}

/**
 *
 * @param state
 * @returns {Function}
 */
export function selected_visits(state) {
    return (key, visit_number) => {
        if ( visit_number ) {
            return _get(state.bulk.selected, `${key}.${visit_number}`, false);
        }

        return _get(state.bulk.selected, key, {});
    }
}

/**
 * @param state
 * @returns {state.bulk.selected|{}}
 */
export function all_selected_workorder_ids(state) {
    return state.bulk.selected;
}

/*
 * Get the work order form errors
 *
 * @param state
 * @returns {Object}
 */
/**
 *
 */
export function errors(state) {
    return state.work_order_errors;
}

/**
 * @param state
 * @return {CustomerContact}
 */
export function contact(state) {
    return state.contact;
}

/**
 * @param state
 * @return {?BillingTerm}
 */
export function billingTerm(state) {
    return state.contact.billing_term || state.contact.customer?.billing_term || null;
}

/**
 *
 */
export function projects(state) {
    return state.resources.projects;
}

/**
 *
 */
export function categories(state) {
    return state.resources.categories;
}

/**
 *
 */
export function category(state) {
    return state.work_order.category;
}

/**
 *
 */
export function statuses(state) {
    return state.resources.statuses;
}

/**
 * Get work or work_visit notes
 *
 * @param state
 * @returns {Array}
 */
export function notes(state) {
    return state.notes;
}

/**
 * Work schedule
 *
 * @param state
 * @returns {{recurring: number, start_date: string, end_date: string, duration_type: string, duration_value: string, frequency_type: string, frequency_value: string, frequency_day_of_week: string, frequency_day_of_month: string, due_within_days: string}|state.work_schedule|{recurring, start_date, end_date, duration_type, duration_value, frequency_type, frequency_value, frequency_day_of_week, frequency_day_of_month, due_within_days}}
 */
export function work_schedule(state) {
    return state.work_schedule;
}

/**
 * --------------------------
 * Schedule Filter Actions
 * --------------------------
 */

/**
 *
 * @param state
 * @return {*}
 */
export function schedule_filters(state) {
    return state.schedule_filters;
}

/**
 *
 * @param state
 * @return {*}
 */
export function default_filters(state) {
    return state.default_filters;
}

/**
 *
 * @param state
 * @return {string}
 */
export function style_code(state) {
    return state.style_code;
}

/**
 *
 * @param state
 * @returns {Date}
 */
export function schedule_date(state) {
    return state.schedule_date;
}

/**
 *
 * @param state
 * @returns {{}|state.drag_data}
 */
export function drag_data(state) {
    return state.drag_data;
}

/**
 *
 * @param state
 * @returns {null|Array}
 */
export function work_invoices(state) {
    return state.invoices;
}

/**
 *
 * @param state
 * @returns {string}
 */
export function grid_view(state) {
    return state.grid_view;
}

/**
 *
 * @param state
 * @returns {{}}
 */
export function unique_bulk_visits_work(state) {
    let work = {};

    Object.entries(state.bulk_visits)
        .filter(([key, visit]) => visit.work.recurring)
        .forEach(([key, visit]) => {
            if ( !(visit.work.work_id in work) ) {
                work[visit.work.work_id] = {};
            }

            work[visit.work.work_id][visit.work_visit_id] = visit;
        });

    return work;
}

/**
 *
 * @param state
 * @returns {{}}
 */
export function bulk_visits(state) {
    return state.bulk_visits;
}

/**
 *
 * @param state
 * @returns {[]|default.watch.selections}
 */
export function selected_items(state) {
    return state.selections.selected_items;
}

/**
 *
 * @param state
 * @returns {[]}
 */
export function available_items(state) {
    return state.selections.available_items;
}

/**
 *
 * @param state
 * @returns {[]}
 */
export function visit_items(state) {
    return state.visit_items;
}

/**
 *
 * @param state
 * @returns {default.watch.generate_visits_progress|number}
 */
export function generate_visits_progress(state) {
    return state.generate_visits_progress;
}

/**
 *
 * @param state
 * @returns {{}}
 */
export function schedule_list_visits(state) {
    return state.schedule_list.visits;
}

/**
 * @param state
 * @returns {{}}
 */
export function schedule_list_route_groups(state) {
    return state.schedule_list.route_groups;
}

/**
 *
 * @param state
 * @returns {{}}
 */
export function selected_schedule_list_visits(state) {
    let visits = {};

    for ( let date in state.schedule_list.visits ) {
        for ( let v in state.schedule_list.visits[date] ) {
            if ( state.schedule_list.visits[date][v].selected ) {
                visits[v] = state.schedule_list.visits[date][v];
            }
        }
    }

    return visits;
}

/**
 * @param state
 * @returns {{unscheduled: boolean, scheduled: boolean}}
 */
export function schedule_loading(state) {
    return state.schedule_loading;
}

/**
 *
 * @param state
 * @returns {Form[]}
 */
export function suggested_forms(state) {
    return [
        // item forms
        ...state.visit_items.flatMap((item) => item.forms || []),
        // combo item forms
        ...state.visit_items.flatMap(item => item.items?.flatMap((item) => item.forms || []) || [])
    ];
}

/**
 *
 * @param state
 * @returns {{}}
 */
export function schedule_assigned_list(state) {
    return state.schedule_assigned_list;
}
