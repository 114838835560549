<template>
    <vault-icon :icon="icon" :style="{color: hex}"></vault-icon>
</template>

<script>
    // External Dependencies
    import {faTag} from '@fortawesome/pro-solid-svg-icons/faTag';

    // Internal Dependencies
    import BaseIcon from './BaseIcon';

    export default BaseIcon.extend({
        name: 'IconTag',

        data() {
            return {
                icon: faTag,
            }
        }
    })
</script>
