import Vue from 'vue';

const state = {
    locations: [],
    pagination: {
        locations: {}
    },
    location: {},
    states: [],
    all_states: [],
    countries: [],
    counties: [],
    municipalities: [],
    country_id: 1,
    state_id: 0,
    county_id: 0,
    reference: {
        id: '',
        type: ''
    },
    default_fields: [
        {
            label: 'Location #',
            key: 'location_number',
        },
        {
            label: 'Location Name',
            key: 'label',
        },
        {
            label: 'Address 1',
            key: 'address1',
        },
        {
            label: 'Address 2',
            key: 'address2',
        },
        {
            label: 'City',
            key: 'city',
        },
        {
            label: 'State',
            key: 'state_id',
        },
        {
            label: 'County',
            key: 'county_id',
        },
        {
            label: 'Municipality',
            key: 'municipality_id'
        },
        {
            label: 'Postal Code',
            key: 'zip',
        },
        {
            label: 'Country',
            key: 'country_id',
        },
        {
            label: 'Email',
            key: 'email_address',
        },
        {
            label: 'Phone',
            key: 'phone_number',
        },
    ],
};

const blank_location = {
    location_number: '',
    address1: '',
    address2: '',
    city: '',
    zip: '',
    state_id: '',
    county_id: '',
    country_id: '1',
    municipality_id: '',
    delivery_address: [],
    field_values: [],
    notes: [],
    equipment: [],
    form_entries: [],
    broadcast_channel: null,
    service_compliance_xref: [],
    target_data_maps: [],
}

const mutations = {
    SET_LOCATIONS (state, locations) {
        if ( locations.paginator ) {
            Vue.set(state.pagination, 'locations', locations.paginator);
            Vue.set(state, 'locations', locations.data);
        } else {
            Vue.set(state, 'locations', locations);
        }
    },
    SET_LOCATION (state, location) {
        Vue.set(state, 'location', {...blank_location, ...location});
    },
    SET_LOCATION_RELATIONSHIP(state, {relation, data}) {
        Vue.set(state.location, relation, data);
    },
    RESET_LOCATION (state) {
        Vue.set(state, 'location', blank_location);
    },
    SET_LOCATION_PAGINATION (state, pagination) {
        Vue.set(state.pagination, 'locations', pagination);
    },
    SET_STATES (state, states) {
        Vue.set(state, 'states', states);
    },
    SET_COUNTIES (state, {counties, merge = false}) {
        if ( merge ) {
            // only merge counties that already aren't in there
            let county_ids = new Set(state.counties.map(({county_id}) => county_id));
            state.counties.push(...counties.filter(({county_id}) => !county_ids.has(county_id)));
        } else {
            Vue.set(state, 'counties', counties);
        }
    },
    SET_ALL_STATES (state, states) {
        Vue.set(state, 'all_states', states);
    },
    SET_COUNTRIES (state, countries) {
        Vue.set(state, 'countries', countries);
    },
    SET_REFERENCE (state, reference) {
        state.reference.id = reference.id;
        state.reference.type = reference.type;
    },
    SET_COUNTRY_ID (state, country_id) {
        state.country_id = country_id;
    },
    SET_CURRENT_COUNTRY_STATES (state) {
        Vue.set(state, 'states', [...(state.countries.find(({country_id}) => country_id == state.country_id)?.states || [])]);
    },
    SET_STATE_ID (state, state_id) {
        state.state_id = state_id;
    },
    SET_LOCATION_EQUIPMENT(state, equipment) {
        Vue.set(state.location, 'equipment', equipment);
    },
    DELETE_LOCATION_DIAGRAM (state) {
        Vue.delete(state.location, 'diagram');
    },
    SET_LOCATION_DIAGRAM (state, diagram) {
        Vue.set(state.location, 'diagram', diagram);
    },
    SET_FORM_ENTRIES (state, entries) {
        Vue.set(state, 'form_entries', entries);
    },
    SET_MUNICIPALITIES (state, {municipalities, merge = false}) {
        if ( merge ) {
            // only merge municipalities that already aren't in there
            let county_ids = new Set(state.municipalities.map(({municipality_id}) => municipality_id));
            state.municipalities.push(...municipalities.filter(({municipality_id}) => !municipality_ids.has(municipality_id)));
        } else {
            Vue.set(state, 'municipalities', municipalities);
        }
    },
    SET_COUNTY_ID (state, county_id) {
        state.county_id = county_id;
    },
};

import * as getters from './getters';
import * as actions from './actions';

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
