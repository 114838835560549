/**
 *
 * @param commit
 * @param price_profile
 */
export function setPriceProfile({commit}, price_profile) {
    commit('SET_PROFILE', price_profile);
}

/**
 *
 * @param commit
 * @param modifiers
 */
export function setPriceProfileModifiers({commit}, modifiers) {
    commit('SET_PROFILE_MODIFIERS', modifiers);
}

/**
 *
 * @param commit
 */
export function resetPriceProfile({commit}) {
    commit('RESET_PROFILE');
}

/**
 *
 * @param commit
 * @param {String} type
 * @param {Array} items
 */
export function setPriceItems({commit}, {type, items}) {
    commit('SET_PRICE_ITEMS', {type, items});
}

/**
 *
 * @param commit
 * @param {String} type
 * @param item
 */
export function addPriceItem({commit}, {type, item}) {
    commit('ADD_PRICE_ITEM', {type, item});
}

/**
 *
 * @param commit
 * @param {String} type
 * @param item
 */
export function updatePriceItem({commit}, {type, item}) {
    commit('UPDATE_PRICE_ITEM', {type, item});
}

/**
 *
 * @param commit
 * @param items
 */
export function setPriceClassItems({commit}, items) {
    commit('SET_PRICE_CLASS_ITEMS', items);
}

/**
 *
 * @param commit
 * @param item
 */
export function deletePriceItem({commit}, item) {
    commit('DELETE_PRICE_ITEM', item);
}

/**
 *
 * @param commit
 * @param pagination
 */
export function setPagination({commit}, pagination) {
    commit('SET_PAGINATION', pagination);
}

/**
 *
 * @param commit
 * @param segments
 */
export function setChartSegments({commit}, segments) {
    commit('SET_CHART_SEGMENTS', segments);
}

/**
 *
 * @param commit
 * @param accounts
 */
export function setChartAccounts({commit}, accounts) {
    commit('SET_CHART_ACCOUNTS', accounts);
}
