import EntityConfigs from '../repositories/EntityConfigRepository.js';

/**
 *
 * @param commit
 * @param configs
 */
export function setConfigs({commit}, configs) {
    commit('SET_CONFIGS', configs);
}

/**
 *
 * @param commit
 * @param config_name
 * @param value
 */
export function setConfigValue({commit}, {config_name, value}) {
    commit('SET_CONFIG_VALUE', {config_name, value});
}

/**
 *
 * @param commit
 * @param entity_id
 */
export function loadEntityConfigs({commit}, entity_id) {
    EntityConfigs.params({
        entity_id
    });

    EntityConfigs.list().then(({data}) => {
        commit('SET_CONFIGS', data);
    })
}
