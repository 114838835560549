<template>
    <div class="form-group relative">
        <a v-if="term && term.length" href="#" class="absolute" style="right: 0.75rem;top: 0.5rem" @click.prevent="clearSearch">
            <vault-icon :icon="icon" :style="{color: '#888'}"></vault-icon>
        </a>
        <input type="text" :placeholder="placeholder" @input="search" v-model="term" ref="input" :name="name" :disabled="disabled" autocomplete="off">
        <slot></slot>
    </div>
</template>

<script>
    // External Dependencies
    import {faTimes} from '@fortawesome/pro-light-svg-icons/faTimes';
    import _debounce from 'lodash/debounce';

    // Internal Dependencies

    export default {
        name: 'SearchBar',

        model: {
            event: 'input',
            prop: 'value'
        },

        props: {
            name: {
                default: 'search_term'
            },

            placeholder: {
                default: ''
            },

            delay: {
                default: 500
            },

            value: {
                default: ''
            },

            disabled: {
                type: Boolean,
                default: false
            },

            detectHash: {
                type: Boolean,
                default: true
            }
        },

        data() {
            return {
                icon: faTimes,
                term: this.value || '',

                emitSearch: _debounce(() => {
                    this.$emit('search', this.term);
                }, this.delay),
            }
        },

        methods: {
            search(e) {
                this.$emit('input', e.target.value);

                // Don't run search when the search term is a hash.
                // This method of searching does an exact match on some pages.
                if ( this.detectHash && e.target.value === '#' ) {
                    return;
                }

                this.emitSearch();
            },

            clearSearch() {
                this.term = '';
                this.$emit('input', '');
                this.$emit('search', '');
                this.$refs.input.focus();
            },

            focus() {
                this.$refs.input.focus();
            },
        },

        watch: {
            value() {
                this.term = this.value;
            }
        }
    }
</script>
