/**
 * @typedef Condition
 * @type {Object}
 * @property {string} condition_type
 * @property {*} condition_value
 * @property {string} operator
 * @property {string} match_type
 * @property {string} field_type
 * @property {?number} custom_field_id
 * @property {?number} customer_id
 * @property {?number} country_id
 * @property {?number[]} state_id
 * @property {?number[]} county_id
 */

/**
 * Get a field's display conditions.
 * @param {CustomField} field
 * @returns {Condition[]}
 */
export function getFieldDisplayConditions(field) {
    return field.options?.conditions || [];
}

/**
 * Get a field's display conditions.
 * @param {CustomField} field
 * @returns {Condition[]}
 */
export function getFieldRequiredConditions(field) {
    return field.options?.required?.conditions || [];
}

/**
 * Get a field's formula conditions.
 * @param {CustomField} field
 * @returns {Condition[]}
 */
export function getFieldFormulaConditions(field) {
    return field.options?.formula?.flatMap(formula => formula.conditions) || [];
}

/**
 * Get a field's read-only conditions.
 * @param {CustomField} field
 * @returns {Condition[]}
 */
export function getFieldReadOnlyConditions(field) {
    return field.options?.read?.flatMap(formula => formula.conditions) || [];
}

/**
 * Get all the conditions related to a CustomField in a flat array.
 * @param {CustomField} field
 * @returns {Condition[]}
 */
export function getAllFieldConditions(field) {
    return [
        ...getFieldDisplayConditions(field),
        ...getFieldRequiredConditions(field),
        ...getFieldFormulaConditions(field),
        ...getFieldReadOnlyConditions(field),
    ];
}

/**
 * Get a validation ruleset's conditions.
 * @param {CustomValidation} validation
 * @returns {Condition[]}
 */
export function getValidationConditions(validation) {
    return validation?.rules?.flatMap(rule => rule.conditions) || [];
}

/**
 * Get all the conditions related to a FormSection in a flat array.
 * @param {FormSection} section
 * @returns {Condition[]}
 */
export function getAllFormSectionConditions(section) {
    return [
        ...(section.fields?.flatMap(field => getAllFieldConditions(field)) || []),
        ...getValidationConditions(section.validation),
    ];
}

/**
 * Get all the conditions related to a Form in a flat array.
 * @param {Form} form
 * @returns {Condition[]}
 */
export function getAllFormConditions(form) {
    return [
        ...(form.sections?.flatMap(section => getAllFormSectionConditions(section)) || []),
        ...getFormTriggerConditions(form.triggers),
        ...getValidationConditions(form.validation),
    ];
}

/**
 * Get a validation ruleset's conditions.
 * @param triggers
 * @returns {Condition[]}
 */
export function getFormTriggerConditions(triggers) {
    return triggers?.flatMap(trigger => trigger.conditions) || [];
}

/**
 * Get the unique county ids listed in conditions.
 * @param {Condition[]} conditions
 * @returns {number[]}
 */
export function getConditionCounties(conditions) {
    return conditions.filter(condition => condition?.condition_type === 'location')
        .reduce((county_ids, {county_id}) => {
            return county_id?.length > 0
                ? [...(new Set([...county_ids, ...county_id]))]
                : county_ids;
        }, []);
}

/**
 * Get the unique service item ids listed in conditions.
 * @param {Condition[]} conditions
 * @returns {array}
 */
export function getConditionServiceItems(conditions) {
    return [...(new Set(
        conditions.filter(condition => condition?.condition_type === 'service_item')
            .flatMap(({service_item_id}) => service_item_id || [])
    ))];
}
