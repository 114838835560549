import Vue from 'vue';
import {PointArray} from '@svgdotjs/svg.js';

import {calculateRotation, calculateRoundedRadius, isRounded, updateRoundedRadius, resizePolygon, rotatedBBox} from '@Vault/Diagrams/diagram-helpers';
import {isUndefined} from 'helpers/types';
import {elementLoop, isUndoing, isRedoing} from '@Vault/Diagrams/store/history-helpers';

const state = {
    diagram: null,
    svg: null,
    drag_data: null,
    selected: [],
    selected_ids: [],
    elements: [],
    element_ids: [],
    shapes: [],
    custom_elements: {},
    diagram_movement: 'pan',
    history: [],
    redo: [],
    errors: {},
    dirty: false,
    editing_custom_element: {},
    history_backup: [],
    redo_backup: [],
};

const removeElement = element => {
    const index = state.elements.indexOf(element);
    const id_index = state.element_ids.indexOf(element.id());

    if ( index !== -1 ) {
        state.elements.splice(index, 1);
    }
    if ( id_index !== -1 ) {
        state.element_ids.splice(id_index, 1);
    }
}

const mutations = {
    ADD_HISTORY (state, {action, payload}) {
        state.history.unshift({action, payload});

        if ( state.history.length > 50 ) {
            state.history.pop();
        }
    },

    ADD_REDO (state, {action, payload}) {
        state.redo.unshift({action, payload});
    },

    CLEAR_REDO (state) {
        Vue.set(state, 'redo', []);
    },

    SET_DIAGRAM (state, diagram) {
        Vue.set(state, 'diagram', diagram);
    },

    SET_DIAGRAM_ID (state, diagram_id) {
        Vue.set(state.diagram, 'diagram_id', diagram_id);
    },

    UPDATE_DIAGRAM_NAME (state, diagram_name) {
        if ( state.diagram == null ) {
            Vue.set(state, 'diagram', {});
        }

        Vue.set(state.diagram, 'diagram_name', diagram_name);
    },

    UPDATE_DIAGRAM_DESCRIPTION (state, diagram_description) {
        if ( state.diagram == null ) {
            Vue.set(state, 'diagram', {});
        }

        Vue.set(state.diagram, 'diagram_description', diagram_description);
    },

    SET_SVG (state, svg) {
        Vue.set(state, 'svg', svg);
    },

    ADD_ELEMENT (state, element) {
        state.element_ids.push(element.id());
        state.elements[state.elements.length] = element;
    },

    REMOVE_ELEMENT (state, element) {
        removeElement(element);
    },

    CLEAR_ELEMENTS (state) {
        Vue.set(state, 'elements', []);
    },

    ADD_SELECTED (state, element) {
        state.selected_ids.push(element.id());
        state.selected[state.selected.length] = element;
    },

    UPDATE_SELECTED_FILL (state, options) {
        elementLoop(options, state, (element, $options) => element.fill($options));
    },

    UPDATE_SELECTED_STROKE (state, options) {
        elementLoop(options, state,(element, $options) => {
            const dasharray = element.attr('stroke-dasharray');

            if ( typeof dasharray !== 'undefined' && 'width' in $options ) {
                const width = $options.width * 3;
                element.attr('stroke-dasharray', `${width} ${width}`)
            }

            element.stroke($options);

            if ( element.hasHiddenElement() ) {
                const hidden = element.prev();

                if ( typeof hidden !== 'undefined' ) {
                    hidden.stroke({width: $options.width + 9});
                }
            }
        });

        if ( isUndoing(options) || isRedoing(options) ) {
            state.svg.fire('stroke:change');
        }
    },

    UPDATE_SELECTED_SIZE (state, options) {
        elementLoop(options, state,(element, size_options) => {
            const {height, width, constrain, rotate, cx, cy, x, y} = Array.isArray(size_options)
                ? size_options.find(item => item.id === element.id())
                : size_options;

            if ( element.type === 'polygon' ) {
                resizePolygon(element, {height, width});
            } else if ( constrain ) {
                if ( !isUndefined(width) ) {
                    element.size(width);
                } else {
                    element.size(null, height);
                }
            } else {
                element.size(width, height);
            }

            if ( isUndefined(x) && isUndefined(y) && isUndefined(rotate) ) {
                const bbox = rotatedBBox(element);

                element.move(bbox.x, bbox.y);
                element.attr('transform', `rotate(${calculateRotation(bbox.rotate)}, ${bbox.cx}, ${bbox.cy})`);
            } else if ( typeof element.attr('transform') !== 'undefined' ) {
                element.move(x, y);
                element.attr('transform', `rotate(${calculateRotation(rotate)}, ${cx}, ${cy})`)
            }

            if ( element.type === 'polygon' ) {
                const points = new PointArray(element.attr('points'));
                element.attr('points', points.move(x, y));
            } else {
                element.cx(cx);
                element.cy(cy);
            }

            element.dispatch('resize', {box: element.box(), handler: {el: element}});

            if ( isRounded(element) ) {
                updateRoundedRadius(element);
            }

            element.dispatch('endresize');
        });
    },

    UPDATE_SELECTED_ROTATE (state, rotate) {
        elementLoop(rotate, state, (element, $rotate) => {
            let element_rotate = $rotate;

            if ( Array.isArray($rotate) ) {
                element_rotate = $rotate.find(item => item.id === element.id())?.rotate || 0;
            }

            element.updateRotatePosition(element_rotate);

            element.dispatch('endresize');
        });
    },

    UPDATE_SELECTED_POSITION (state, position) {
        elementLoop(position, state, (element, $position) => {
            if ( 'x' in $position && 'y' in $position ) {
                element.move($position.x, $position.y);

                if ( typeof element.attr('transform') !== 'undefined' ) {
                    element.updateRotatePosition();
                }
            } else if ( 'x' in $position ) {
                let x = $position.x;

                if ( $position.x === '+' ) {
                    x = element.x() + 1;
                } else if ( $position.x === '-' ) {
                    x = element.x() - 1;
                }

                element.x(x);
                if ( typeof element.attr('transform') !== 'undefined' ) {
                    element.updateRotatePosition();
                }
            } else if ( 'y' in $position ) {
                let y = $position.y;

                if ( $position.y === '+' ) {
                    y = element.y() + 1;
                } else if ( $position.y === '-' ) {
                    y = element.y() - 1;
                }

                element.y(y);
                if ( typeof element.attr('transform') !== 'undefined' ) {
                    element.updateRotatePosition();
                }
            }

            element.dispatch('dragend');
        });
    },

    UPDATE_SELECTED_ZINDEX (state, zindex) {
        elementLoop(zindex, state, (element, $zindex) => {
            // This needs parent to target the g element that the elements are wrapped in
            element.deselect();
            if ( $zindex == 'back' ) {
                if ( element.is_custom_element ) {
                    element.back();
                } else {
                    element.parent().back();
                }
            } else if ( $zindex == 'front' ) {
                if ( element.is_custom_element ) {
                    element.front();
                } else {
                    element.parent().front();
                }
            } else if ( !Number.isNaN($zindex) ) {
                if ( element.is_custom_element ) {
                    state.svg.add(element.remove(), $zindex);
                } else {
                    state.svg.add(element.parent().remove(), $zindex);
                }
            }
            element.select();
        })
    },

    UPDATE_SELECTED_LABEL_DATA (state, {text, key}) {
        state.selected.forEach(element => {
            element[key](text);
        });
    },

    CLEAR_SELECTED (state) {
        Vue.set(state, 'selected', []);
        Vue.set(state, 'selected_ids', []);
    },

    DESELECT_ELEMENT (state, element) {
        const index = state.selected.indexOf(element);
        const id_index = state.selected_ids.indexOf(element.id());

        if ( index !== -1 ) {
            state.selected.splice(index, 1);
        }

        if ( id_index !== -1 ) {
            state.selected_ids.splice(id_index, 1);
        }
    },

    UPDATE_ROUNDED_FACTOR (state, rounded_factor) {
        elementLoop(rounded_factor, state, (element, factor) => {
            const radius = calculateRoundedRadius({
                height: element.height(),
                width: element.width(),
                factor: factor
            });

            element.radius(radius, radius)
                .data('rounded-factor', factor);
        });
    },

    UPDATE_TEXT_SIZE (state, text_size) {
        elementLoop(text_size, state, (element, $text_size) => {
            if ( element.is_text ) {
                element.font('size', $text_size);
            }
        });
    },

    UPDATE_TEXT (state, text) {
        elementLoop(text, state, (element, $text) => {
            if ( element.is_text ) {
                element.plain($text);
                element.remember('_selectHandler').mutationHandler();
            }
        });
    },

    UPDATE_CONSTRAIN (state, constrain) {
        elementLoop(constrain, state, (element, $constrain) => {
            element.params('constrain', $constrain ? 1 : null);

            const resizeHandler = element.remember('_resizeHandler');

            if ( !isUndefined(resizeHandler) ) {
                resizeHandler.setOptions({
                    constrain: !!$constrain,
                })
            }

            element.fire('constrain:change');
        });
    },

    SET_SHAPES (state, shapes) {
        Vue.set(state, 'shapes', shapes);
    },

    SET_CUSTOM_ELEMENTS (state, custom_elements) {
        Vue.set(state, 'custom_elements', custom_elements);
    },

    ADD_CUSTOM_ELEMENT (state, custom_element) {
        const group_index = state.custom_elements
            .findIndex(({diagram_element_group_id}) => diagram_element_group_id === custom_element.diagram_element_group_id);

        state.custom_elements[group_index].elements.push(custom_element);
    },

    UPDATE_CUSTOM_ELEMENT (state, custom_element) {
        const group_index = state.custom_elements
            .findIndex(({diagram_element_group_id}) => diagram_element_group_id === custom_element.diagram_element_group_id);
        const index = state.custom_elements[group_index].elements
            .findIndex(i => i.diagram_element_id === custom_element.diagram_element_id);

        if ( index !== -1 ) {
            Vue.set(state.custom_elements[group_index].elements, index, custom_element);
        }
    },

    REMOVE_CUSTOM_ELEMENT (state, custom_element) {
        const group_index = state.custom_elements
            .findIndex(({diagram_element_group_id}) => diagram_element_group_id === custom_element.diagram_element_group_id);
        const index = state.custom_elements[group_index].elements
            .findIndex(i => i.diagram_element_id === custom_element.diagram_element_id);

        if ( index !== -1 ) {
            state.custom_elements[group_index].elements.splice(index, 1);
        }
    },

    SET_DIAGRAM_MOVEMENT (state, movement) {
        state.diagram_movement = movement;
    },

    RESET_STORE (state) {
        Vue.set(state, 'diagram', null);
        Vue.set(state, 'svg', null);
        Vue.set(state, 'drag_data', null);
        Vue.set(state, 'selected', []);
        Vue.set(state, 'selected_ids', []);
        Vue.set(state, 'elements', []);
        Vue.set(state, 'element_ids', []);
        Vue.set(state, 'shapes', []);
        Vue.set(state, 'custom_elements', []);
        Vue.set(state, 'diagram_movement', 'pan');
        Vue.set(state, 'history', []);
        Vue.set(state, 'redo', []);
        Vue.set(state, 'history_backup', []);
        Vue.set(state, 'redo_backup', []);
        Vue.set(state, 'editing_custom_element', {});
    },

    DIAGRAM_ERRORS (state, errors) {
        Vue.set(state, 'errors', errors);
    },

    CLEAR_DIAGRAM_ERRORS (state) {
        Vue.set(state, 'errors', {});
    },

    SET_DIRTY (state, dirty) {
        Vue.set(state, 'dirty', dirty);
    },

    SET_EDITING_CUSTOM_ELEMENT (state, custom_element) {
        const is_editing = Object.keys(custom_element).length > 0;
        const editing_class = 'editing-diagram-element';
        Vue.set(state, 'editing_custom_element', custom_element);

        if ( is_editing ) {
            Vue.set(state, 'history_backup', state.history);
            Vue.set(state, 'redo_backup', state.redo);
            Vue.set(state, 'history', []);
            Vue.set(state, 'redo', []);

            if ( !document.body.classList.contains(editing_class) ) {
                document.body.classList.add(editing_class);
            }
        } else {
            Vue.set(state, 'history', state.history_backup);
            Vue.set(state, 'redo', state.redo_backup);
            Vue.set(state, 'history_backup', []);
            Vue.set(state, 'redo_backup', []);
            document.body.classList.remove(editing_class);

            state.elements
                .filter(element => !element.is_hidden)
                .forEach(element => {
                    element.selectize(false).resize('stop');
                    element.is_custom_element ? element.remove() : element.parent().remove();

                    removeElement(element);
                })
        }

        state.elements.forEach(element => {
            element.deselect();
            is_editing ? element.hide() : element.show();
        });

        Vue.set(state, 'selected', []);
        Vue.set(state, 'selected_ids', []);
    },

    UPDATE_CUSTOM_ELEMENT_SORT (state, {elements, group}) {
        const group_index = state.custom_elements.findIndex(({group_name}) => group_name === group);

        state.custom_elements[group_index]?.elements
            ?.forEach((element, index) => {
                const sort = elements.indexOf(element.diagram_element_id);

                Vue.set(state.custom_elements[group_index].elements[index], 'sort', sort);
            });

        state.custom_elements[group_index]?.elements?.sort((a, b) => a.sort - b.sort);
    },

    ADD_CUSTOM_ELEMENT_GROUP (state, group) {
        group.elements = [];
        state.custom_elements.push(group);

        state.custom_elements.sort((a, b) => a.sort - b.sort);
    },

    UPDATE_CUSTOM_ELEMENT_GROUP (state, group) {
        const group_index = state.custom_elements
            .findIndex(({diagram_element_group_id}) => diagram_element_group_id === group.diagram_element_group_id);

        state.custom_elements[group_index].group_name = group.group_name;
    },

    UPDATE_CUSTOM_ELEMENT_GROUP_SORT (state, groups) {
        state.custom_elements
            .forEach((group, index) => {
                const sort = groups.findIndex(({diagram_element_group_id}) => diagram_element_group_id === group.diagram_element_group_id);

                Vue.set(state.custom_elements[index], 'sort', sort);
            });

        state.custom_elements.sort((a, b) => a.sort - b.sort);
    },
};

import * as getters from './getters';
import * as actions from './actions';

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}