// External Dependencies
import _findIndex from 'lodash/findIndex';
import _cloneDeep from 'lodash/cloneDeep';
import Vue from 'vue';

const blank_invite = {
    first_name: '',
    last_name: '',
    email: '',
    user_number: '',
    user_role_id: '',
    sub_contractor_id: '',
    customer_id: '',
    birthdate: '',
    hire_date: '',
    pay_type: '',
    terminated_date: '',
    data: {},
};

const state = {
    users: [],
    pagination: {},
    invite: _cloneDeep(blank_invite),
    user_profile_id: 0,
    user_profile: _cloneDeep(blank_invite),
    roles: [],
    role: {},
    timeoff_categories: [],
};

const mutations = {
    SET_USERS (state, users) {
        state.users = users;
    },

    UPDATE_USER_ATTRIBUTE (state, {user, attribute}) {
        let index = state.users.findIndex(u => u.user_id === user.user_id);

        if ( -1 !== index ) {
            Vue.set(state.users[index], attribute, user[attribute]);
        }
    },

    SET_USERS_PAGINATION (state, pagination) {
        state.pagination = pagination;
    },

    SET_INVITE_PROP (state, {where, value}) {
        state.invite[where] = value;
    },

    SET_INVITE_DATA(state, {key, value}) {
        Vue.set(state.invite.data, key, value);
    },

    CLEAR_INVITE (state) {
        state.invite = _cloneDeep(blank_invite);
    },

    SET_USER_PROFILE_ID (state, user_id) {
        state.user_profile_id = user_id;
    },

    SET_USER_PROFILE (state, user_profile) {
        state.user_profile = _cloneDeep(user_profile);
    },

    SET_USER_PROFILE_ATTRIBUTE (state, {where, value}) {
        Vue.set(state.user_profile, where, value);
    },

    CLEAR_USER_PROFILE (state) {
        state.user_profile = {};
        state.user_profile_id = 0;
    },

    UPDATE_USER_PROFILE (state, user_profile) {
        let index = _findIndex(state.users, (o) => {
            return o.user_id == state.user_profile_id
        });

        Vue.set(state.users, index, user_profile);
    },

    REMOVE_USER (state) {
        let index = _findIndex(state.users, (o) => {
            return o.user_id == state.user_profile.user_id;
        });

        if ( index === -1 ) {
            return;
        }

        state.users.splice(index, 1);
    },

    SET_ROLES (state, roles) {
        state.roles = roles;
    },

    SET_ROLE (state, role) {
        Vue.set(state, 'role', role);
    },

    REMOVE_ROLE (state, role) {
        let index = _findIndex(state.roles, (o) => {
            return o.user_role_id == role.user_role_id;
        });

        if ( index === -1 ) {
            return;
        }

        state.roles.splice(index, 1);
    },

    SET_TIMEOFF_CATEGORIES (state, categories) {
        state.timeoff_categories = categories;
    },

    ADD_TIMEOFF_CATEGORY (state, category) {
        state.timeoff_categories.push(Object.assign({}, category));
    },
};

import * as getters from './getters';
import * as actions from './actions';

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
