import _sortBy from 'lodash/sortBy';

/**
 * Get the loaded categories.
 *
 * @param state
 * @returns {ReferenceCategory[]}
 */
export function categories(state) {
    return _sortBy(state.categories, 'name');
}

/**
 * Get the current document.
 *
 * @param state
 * @returns {?ReferenceDocument}
 */
export function document(state) {
    return state.document;
}

/**
 * Get the loaded documents.
 *
 * @param state
 * @returns {ReferenceDocument[]}
 */
export function documents(state) {
    return _sortBy(state.documents, 'title');
}

/**
 * Get paginator
 *
 * @param state
 * @returns {*}
 */
export function paginator(state) {
    return state.paginator;
}

/**
 * Get the currently dragging category.
 *
 * @param state
 * @returns {?ReferenceCategory}
 */
export function draggingCategory(state) {
    return state.dragging_category;
}

/**
 * Get the currently dragging document.
 *
 * @param state
 * @returns {?ReferenceDocument}
 */
export function draggingDocument(state) {
    return state.dragging_document;
}

/**
 * Get whether we are editing the current document.
 *
 * @param state
 * @returns {boolean}
 */
export function editingDocument(state) {
    return state.editing_document;
}

/**
 * Get a flattened array of the loaded categories.
 *
 * @param state
 * @returns {ReferenceCategory[]}
 */
export function flattenedCategories(state) {
    return _sortBy(state.flattened_categories, 'name');
}

/**
 * Get the currently selected category.
 *
 * @param state
 * @returns {?ReferenceCategory}
 */
export function selectedCategory(state) {
    return state.selected_category;
}

/**
 * Get the selected item.
 *
 * @param state
 * @returns {?ReferenceItem}
 */
export function selectedItem(state) {
    return state.selected_item;
}

/**
 * Get the currently selected item key.
 *
 * @param state
 * @returns {?string}
 */
export function selectedItemKey(state) {
    switch ( state.selected_item?.type ) {
        case 'category':
            return 'category-' + state.selected_item.item.reference_category_id;

        case 'document':
            return 'document-' + state.selected_item.item.reference_document_id;

        default:
            return null;
    }
}
