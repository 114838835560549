import Vue from 'vue';

// Used when creating new templates.
const blank_import_template = {
    import_template_id: 0,
    import_template_name: "",
    import_file_format_id: 0,
    ignore_first_row: 1,
    update_if_exists: 1,
    import_type_id: 0,
    import_as_user_id: 0,
    entity_id: 0,

    fields: [],
    pending: []
};

const state = {
    // All import templates.
    import_templates: [],
    import_types: [],
    import_file_formats: [],
    import_template: {}
};

const mutations = {
    SET_IMPORT_TEMPLATE(state, template) {
        Vue.set(state, 'import_template', template);
    },

    SET_IMPORT_TEMPLATES(state, templates) {
        state.import_templates = templates;
    },

    REMOVE_IMPORT_TEMPLATE(state, import_template_id) {
        for ( let i in state.import_templates) {
            let import_template = state.import_templates[i];

            if (import_template.import_template_id == import_template_id) {
                state.import_templates.splice(i, 1);
                break;
            }
        }
    },

    SET_BLANK_IMPORT_TEMPLATE(state) {
        state.import_template = Object.assign({}, blank_import_template);
    },

    SET_IMPORT_TYPES(state, types) {
        state.import_types = types;
    },

    SET_IMPORT_FILE_FORMATS(state, formats) {
        state.import_file_formats = formats;
    },

    REMOVE_CURRENT_IMPORT_TEMPLATE(state) {
        state.import_template = {};
    },

    SET_IMPORT_TEMPLATE_FIELD(state, {field, value}) {
        // if the structure already has a registered property, just set it.
        if (state.import_template.hasOwnProperty(field)) {
            state.import_template[field] = value;
        } else { // Else, reactive set it.
            Vue.set(state.import_template, field, value);
        }
    },

    ADD_FIELD(state, field) {
        state.import_template.fields.push(field);
    },

    CLEAR_FIELDS(state) {
        state.import_template.fields = [];
    },

    REMOVE_IMPORT_FIELD(state, import_type_field_id) {
        let fields = state.import_template.fields;

        for (let i in fields) {
            if ( fields[i].import_type_field_id == import_type_field_id ) {
                fields.splice(i, 1);
                break;
            }
        }
    },

    SET_IMPORT_TEMPLATE_FIELD_PROPERTY(state, {import_type_field_id, field, value}) {
        let fields = state.import_template.fields;

        for (let i in fields) {
            let template_field = fields[i];

            if (template_field.import_type_field_id == import_type_field_id) {
                Vue.set(state.import_template.fields[i], field, value);
                break;
            }
        }
    },

    ADD_PENDING_IMPORT(state, {import_template_id, pending_import}) {
        for (let i in state.import_templates) {
            if (state.import_templates[i].import_template_id == import_template_id) {
                if (!state.import_templates[i].hasOwnProperty('pending')) {
                    Vue.set(state.import_templates[i], 'pending', []);
                }

                state.import_templates[i].pending.push(pending_import);
                break;
            }
        }
    },

    SET_IMPORT_TEMPLATE_FIELD_BY_ID(state, {import_template_id, field, value}) {
        for (let i in state.import_templates) {
            let import_template = state.import_templates[i];

            if (import_template.import_template_id == import_template_id) {
                Vue.set(state.import_templates[i], field, value);
                break;
            }
        }
    },

    REMOVE_PENDING_IMPORT(state, {import_template_id, import_pending_id}) {
        for (let i in state.import_templates) {
            if (state.import_templates[i].import_template_id == import_template_id) {
                for (let j in state.import_templates[i].pending) {
                    let pending_import = state.import_templates[i].pending[j];

                    if (pending_import.import_pending_id == import_pending_id) {
                        state.import_templates[i].pending.splice(j, 1);
                        break;
                    }
                }

                break;
            }
        }

    },

    CLEAR_PENDING_IMPORTS(state, import_template_id) {
        let index = state.import_templates.findIndex(template => template.import_template_id === import_template_id);
        if ( index >= 0 ) {
            Vue.set(state.import_templates[index], 'pending', []);
        }
    }
};

import * as getters from './getters';
import * as actions from './actions';

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
