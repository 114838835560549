/**
 * @param commit
 * @param quote
 */
export function setQuote({commit}, quote) {
    commit('SET_QUOTE', quote);
}

/**
 * @param commit
 */
export function clearQuote({commit}) {
    commit('CLEAR_QUOTE');
}

/**
 * @param commit
 * @param where
 * @param value
 */
export function setQuoteData({commit}, {where, value}) {
    commit('SET_QUOTE_DATA', {where, value});
}

/**
 * @param commit
 * @param item
 */
export function addQuoteItem({commit}, item) {
    commit('ADD_QUOTE_ITEM', item);
}

/**
 * @param commit
 * @param items
 */
export function setQuoteItems({commit}, items) {
    commit('SET_QUOTE_ITEMS', items);
}

/**
 * @param commit
 * @param value
 * @param property
 * @param item
 */
export function updateQuoteItemData({commit}, {value, property, item}) {
    commit('UPDATE_QUOTE_ITEM_DATA', {value, property, item});
}

/**
 * @param commit
 */
export function refreshQuoteTotal({commit}) {
    commit('REFRESH_TOTAL');
}

/**
 * @param commit
 * @param item
 */
export function deleteQuoteItem({commit}, item) {
    commit('DELETE_QUOTE_ITEM', item);
}

/**
 * @param commit
 * @param errors
 * @param item
 */
export function addErrorsToItem({commit}, {errors, item}) {
    commit('ADD_ERRORS_TO_QUOTE_ITEM', {errors, item})
}

/**
 * @param commit
 * @param item
 */
export function clearItemErrors({commit}, item) {
    commit('CLEAR_QUOTE_ITEM_ERRORS', item);
}