/**
 * @param state
 * @returns {Ftp[]}
 *
 * }
 */
export function ftps(state) {
    return state.ftps;
}

/**
 * @param state
 * @returns {*}
 */
export function ftp_configs(state) {
    return state.ftp_configs;
}

/**
 * @param state
 * @return {Ftp}
 */
export function blank_ftp(state) {
    return Object.assign({}, state.blank_ftp);
}
