<template>
    <div class="address flex align-start" :class="{ 'text-sm': small, 'no-color': noColor }">
        <div v-if="showIcon" class="icon w-5 mr-2 text-center text-blue-800" :class="iconCss">
            <icon-location></icon-location>
        </div>

        <div>
            <slot></slot>

            <div class="address-info" :class="{ 'text-sm': small, 'text-xs': tiny }" v-if="addressObj">
                <component :is="componentIs" ref="address" @click="handleAddressClick" :to="linkAddress">
                    <div>{{addressObj.address1}}</div>
                    <div v-if="addressObj.address2" class="mt-1">{{addressObj.address2}}</div>
                    <div class="mt-1">{{addressObj.city}} {{addressObj.state | state}} {{addressObj.zip}}</div>
                    <div v-if="addressObj.county_name" class="mt-1">{{ addressObj.county_name }} County</div>
                </component>

                <div v-if="addressObj.deleted_at" class="mt-1 text-xs italic muted">
                    Location Deleted:
                    {{addressObj.deleted_at | date}} {{addressObj.deleted_at | time}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // Internal Dependencies
    import {IconLocation} from 'Icons';

    export default {
        name: 'AddressInfo',

        props: {
            showIcon: {
                type: Boolean,
                default: false
            },
            small: {
                type: Boolean,
                default: true,
            },
            tiny: {
                type: Boolean,
                default: false,
            },
            noColor: {
                type: Boolean,
                default: false
            },
            addressObj: {
                type: Object,
                default() {
                    return {
                        address1: '',
                        address2: '',
                        city: '',
                        state: '',
                        zip: '',
                    }
                }
            },
            linkAddress: {
                default: false,
            },
            mobileLink: {
                default: false,
            },
            iconCss: {
                type: String,
                default: ''
            },
        },

        computed: {
            isMobile() {
                return /Android|iPhone|iPad/i.test(navigator.userAgent);
            },
        },

        data() {
            return {
                componentIs: 'div',
            };
        },

        mounted() {
            if ( this.linkAddress !== false ) {
                this.componentIs = 'router-link';
            }

            if ( this.mobileLink && this.isMobile ) {
                this.componentIs = 'a'
            }
        },

        methods: {
            handleAddressClick(e) {
                if ( this.mobileLink && this.isMobile  ) {
                    window.open(`https://www.google.com/maps/search/?api=1&query=${this.formatAddressQuery()}`);
                    return;
                }

                if ( !this.linkAddress ) {
                    return;
                }
                e.preventDefault();

                this.$router.push(this.linkAddress);
            },

            formatAddressQuery() {
                const {address1, address2, city, zip} = this.addressObj
                const state = this.addressObj.state instanceof Object ? this.addressObj.state.state : this.addressObj.state;

                return encodeURI(`${address1} ${address2 || ''} ${city} ${state} ${zip}`);
            },
        },

        filters: {
            state(val) {
                if ( val instanceof Object ) {
                    return val.state;
                }

                return val;
            }
        },

        components: {
            IconLocation,
        }
    }
</script>
