/**
 * Supportive Stuff
 */

export const setExportFileFormats = ({commit}, formats) => {
    commit('SET_EXPORT_FILE_FORMATS', formats);
};

export const setExportTypes = ({commit}, types) => {
    commit('SET_EXPORT_TYPES', types);
};


/**
 * Templates, and related stuff.
 */

export const setExportTemplates = ({commit}, templates) => {
    commit('SET_EXPORT_TEMPLATES', templates);
};

export const setExportTemplateById = ({commit}, template) => {
    commit('SET_EXPORT_TEMPLATE', template);
};

export const removePendingExport = ({commit}, export_pending) => {
    commit('DELETE_PENDING_EXPORT', export_pending)
};

export const setExportTemplateFieldById = ({commit}, {export_template_id, field, value}) => {
    commit('SET_EXPORT_TEMPLATE_FIELD_BY_ID', {export_template_id, field, value});
};

export const setBlankExportTemplate = ({commit}) => {
    commit('SET_BLANK_EXPORT_TEMPLATE');
};
