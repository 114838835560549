import { makeEquipmentCustomFields, makeEquipmentGeneralFields } from '@Vault/Admin/Equipment/EquipmentConditionFields.js';

/**
 *
 * @param state
 * @returns {Form[]}
 */
export function forms(state) {
    return state.forms;
}

/**
 *
 * @param state
 * @returns {?Form}
 */
export function form(state) {
    return state.form;
}

/**
 *
 * @param state
 */
export function blank_form(state) {
    return {...state.blank_form};
}

/**
 *
 * @param state
 * @returns {FormSection}
 */
export function section(state) {
    return state.section;
}

/**
 * @param state
 * @returns {CustomField[]}
 */
export function allFormSectionFields(state) {
    return state.form.sections.flatMap(section => {
        return section.fields.map(field => ({
            ...field,
            form_section_id: section.form_section_id,
            section_name: section.section_name,
            group_name: section.section_name,
            section_type: section.section_type,
        }));
    })
}

/**
 * @param state
 * @returns {EquipmentGeneralField[]|EquipmentCustomField[]}
 */
export function allFormEquipmentFields(state) {

    return state.form.sections
        .filter(({equipment_type}) => !!equipment_type)
        .flatMap(section => {
            let merge = {
                section_name: section.section_name,
                group_name: section.section_name + ' Equipment',
                option_group_label: section.section_name + ' Equipment',
            }
            return [
                ...makeEquipmentGeneralFields(section.equipment_type, section.form_section_id, merge),
                ...makeEquipmentCustomFields(section.equipment_type, section.form_section_id, merge),
            ]
        });
}

/**
 *
 * @param state
 * @returns {?CustomField}
 */
export function field(state) {
    return state.field;
}

/**
 *
 * @param state
 * @returns {null}
 */
export function entry(state) {
    return state.entry;
}

/**
 * These represent all sections in the form not to be confused with the 'entry_sections'
 * @param state
 * @returns {default.computed.sections|Array}
 */
export function sections(state) {
    return state.entry_sections;
}

/**
 *
 * @param state
 * @returns {FormSection[]}
 */
export function form_sections(state) {
    return state.form.sections || [];
}

export function thisOrPreviousSectionIds(state) {
    return state.form.sections
        .filter(({sort}) => sort <= state.section.sort)
        .map(({form_section_id}) => form_section_id);
}

/**
 * Returns a consolidated array of form section and equipment type fields.
 *
 * @param state
 * @returns {Array}
 */
export function form_fields(state) {
    return [
        ...(state.form.sections || [])
            .reduce((fields, section) => {
                (section.fields || []).forEach(field => {
                    fields.push({
                        ...field,
                        form_section_id: section.form_section_id,
                        section_name: section.section_name,
                    });
                });

                return fields;
            }, []),
        ...state.equipment_type_fields.slice(),
    ];
}

/**
 *
 * @param state
 * @returns {Array}
 */
export function equipment_type_fields(state) {
    return state.equipment_type_fields;
}

/**
 *
 * @param state
 * @returns {Boolean}
 */
export function equipment_type_fields_loaded(state) {
    return state.equipment_type_fields_loaded;
}

/**
 *
 * @param state
 * @returns {Object}
 */
export function form_references(state) {
    return state.form_references;
}

/**
 *
 * @param state
 * @returns {Array}
 */
export function formula_suggestions(state) {
    return state.formula_suggestions;
}

/**
 *
 * @param state
 * @returns {Array}
 */
export function read_only_suggestions(state) {
    return state.read_only_suggestions;
}

/**
 *
 * @param state
 * @returns {*}
 */
export function last_section(state) {
    if ( state.entry_sections.length === 0 ) {
        return null;
    }

    return state.entry_sections[state.entry_sections.length - 1];
}

/**
 *
 * @param state
 * @returns {Object<number, Equipment>}
 */
export function allFormEntrySectionEquipment(state) {
    return (state.entry?.entry_sections || [])
        .filter(({equipment}) => !!equipment)
        .reduce((entry_section_equipment, {form_section_id, value_index, equipment}) => {
            if ( !entry_section_equipment[form_section_id] ) {
                entry_section_equipment[form_section_id] = {};
            }

            entry_section_equipment[form_section_id][value_index] = equipment;

            return entry_section_equipment;
        }, {});
}

/**
 *
 * @param state
 * @returns {state.entry_values|{}}
 */
export function entry_values(state) {
    return state.entry_values;
}

/**
 *
 * @param state
 * @returns {{}}
 */
export function section_values(state) {
    return state.section_values;
}

/**
 *
 * @param state
 * @returns {state.entry_files|{}}
 */
export function entry_files(state) {
    return state.entry_files;
}

/**
 *
 * @param state
 * @returns {state.entry_location|{}}
 */
export function entry_location(state) {
    return state.entry_location;
}

/**
 *
 * @param state
 * @returns {{}}
 */
export function entry_customer(state) {
    return state.entry_customer;
}

/**
 *
 * @param state
 * @returns {state.entry.entry_sections|[]}
 */
export function entry_sections(state) {
    return state.entry ? state.entry.entry_sections : [];
}

/**
 * @param state
 * @returns {number}
 */
export function section_index(state) {
    return state.section_index;
}

/**
 *
 * @param state
 * @returns {null|*}
 */
export function inventory_location_id(state) {
    return state.inventory_location_id;
}

/**
 *
 * @param state
 * @returns {null|*}
 */
export function context_date(state) {
    return state.context_date;
}

/**
 *
 * @param state
 * @returns {{}}
 */
export function entry_section_lists(state) {
    return state.entry_section_lists;
}
/**
 * @param state
 * @returns {null|*}
 */
export function print_view_cache_key(state) {
    return state.print_view_cache_key;
}

/**
 * @param state
 * @returns {{}|{}|*}
 */
export function form_print_view(state) {
    return state.form_print_view;
}

/**
 *
 * @param state
 * @returns {[{result: number, type: string},{result: number, type: string},{result: number, type: string},{result: number, type: string},{result: number, type: string}]|[{label: string, key: string},{label: string, key: string},{label: string, key: string},{label: string, key: string},{label: string, key: string},null]|[{label: string, value: number},{label: string, value: number},{label: string, value: number}]|[{label: string, value: number},{label: string, value: number},{label: string, value: number}]|*[]|*}
 */
export function result_options(state) {
    return state.result_options;
}

/**
 *
 * @param state
 * @returns {[{result: string, type: string},{result: string, type: string},{result: string, type: string}]}
 */
export function result_state_options(state) {
    return state.result_state_options;
}
