import Vue from 'vue';
import Vuex from 'vuex';
import { setSentryUser } from 'helpers/sentry.js';

Vue.use(Vuex);

import modules from './modules';
import _clone from 'lodash/cloneDeep';

const state = {
    app_version: null,
    refresh_required: false,
    entity_id: 0,
    entity: {
        entity_name: '',
        timezone_code: '',
        reply_to_email: '',
        timezone_abbreviation: '',
        address1: '',
        address2: '',
        city: '',
        zip: '',
        state_id: '',
        country_id: 1,
        state: {}
    },
    user_id: 0,
    user_role_id: 0,
    username: '',
    user_display: '',
    user_type: '',
    user_types: [
        'account_admin',
        'admin',
        'field',
        'sub_contractor',
        'customer',
    ],
    system_admin: false,
    is_impersonating: false,
    permissions: [],
    breadcrumbs: [],
    app_loaded: false,
    role: {
        role_name: '',
        user_type: ''
    },
    export_filters: {},
    integrations: {},
    tax_categories: [],
    has_task_categories: 0,
    mentions: [],
    unread_mentions_count: 0,
    integration_list: {
        qbo: {
            title: 'Contact is Connected to QuickBooks Online',
            icon: '/images/thirdparty/quickbooks/C2QB_white_btn_sm_hover.png'
        },
        another_integration: {
            title: 'Example.',
            icon: '/images/thirdparty/example/logo.png'
        },
    },
    route_to: null,
};

const mutations = {
    SET_ENTITY_ID(state, entity_id) {
        state.entity_id = entity_id
    },

    SET_ENTITY(state, entity) {
        let entity_clone = _clone(entity);

        for (let k in entity_clone) {
            if (state.entity.hasOwnProperty(k)) {
                state.entity[k] = entity_clone[k];
            }
        }
    },

    SET_USER_INFO(state, {user_id, username, user_type, user_display, user_role_id}) {
        mutations.SET_USERNAME(state, username);
        state.user_id = user_id;
        state.user_display = user_display;
        state.user_role_id = user_role_id
        state.user_type = user_type;
    },

    SET_USER_ID(state, user_id) {
        state.user_id = user_id;
    },

    SET_USER_ROLE_ID(state, user_role_id) {
        state.user_role_id = user_role_id;
    },

    SET_USERNAME(state, username) {
        state.username = username;
        setSentryUser(username);
    },

    SET_USER_TYPE(state, user_type) {
        state.user_type = user_type;
    },

    SET_SYSTEM_ADMIN(state, system_admin) {
        state.system_admin = system_admin;
    },

    SET_IS_IMPERSONATING(state, is_impersonating) {
        state.is_impersonating = is_impersonating;
    },

    SET_APP_LOADED(state, loaded) {
        state.app_loaded = loaded;
    },

    //Breeadcrumb methods
    RESET_BREADCRUMB(state, breadcrumb) {
        Vue.nextTick(() => {
            state.breadcrumbs = breadcrumb ? [breadcrumb] : [];
        });
    },
    PUSH_BREADCRUMB(state, breadcrumb) {
        Vue.nextTick(() => {
            state.breadcrumbs.push(breadcrumb);
        });
    },
    POP_BREADCRUMB(state) {
        state.breadcrumbs.pop();
    },
    EDIT_BREADCRUMB(state, breadcrumb) {
        Vue.set(state.breadcrumbs, state.breadcrumbs.length - 1, breadcrumb)
    },
    SET_PERMISSIONS(state, permissions) {
        Vue.set(state, 'permissions', permissions);
    },
    SET_USER_ROLE(state, role) {
        Vue.set(state, 'role', role);
    },
    SET_EXPORT_FILTER(state, filter) {
        Vue.set(state.export_filters, filter.key, filter.value);
    },
    REMOVE_EXPORT_FILTER(state, filter) {
        Vue.delete(state.export_filters, filter);
    },
    RESET_EXPORT_FILTERS(state) {
        state.export_filters = {};
    },
    SET_INTEGRATION_OPTIONS(state, integration) {
        Vue.set(state.integrations, integration.integration_code, integration.options);
    },
    SET_APP_VERSION(state, app_version) {
        Vue.set(state, 'app_version', app_version);
    },
    SET_REFRESH_REQUIRED(state) {
        Vue.set(state, 'refresh_required', true);
    },

    SET_ROUTE_TO(state, route_to) {
        state.route_to = route_to;
    },

    SET_TAX_CATEGORIES(state, tax_categories) {
        Vue.set(state, 'tax_categories', tax_categories);
    },

    SET_HAS_TASK_CATEGORIES(state, has_task_categories) {
        Vue.set(state, 'has_task_categories', has_task_categories);
    },

    SET_MENTIONS(state, mentions) {
        state.mentions = mentions;
        if ( mentions.length > 0 ) {
            state.unread_mentions_count = mentions[0].unread_mentions_count;
        }
    },

    UPDATE_MENTION(state, mention) {
        // The repository injects an unread count
        // If an unread count exists on the mention, this is being injected
        // after reading a mention to keep the frontend in sync. Update the
        // store count from it and delete the injected key/value.
        if ( ![undefined, null].includes(mention.unread_mentions_count) ) {
            state.unread_mentions_count = mention.unread_mentions_count;
            delete mention.unread_mentions_count;

        // Otherwise a new mention is being pushed, which *should* be unread
        // but check it anyway and increment the count accordingly.
        } else if ( !mention.read_at ) {
            state.unread_mentions_count++;
        }

        const index = state.mentions.findIndex(({mention_id}) => mention_id === mention.mention_id);
        if ( index >= 0 ) {
            Vue.set(state.mentions, index, mention);
        } else {
            state.mentions.unshift(mention);
        }
    },

    DELETE_MENTION(state, mention) {
        const index = state.mentions.findIndex(({mention_id}) => mention_id === mention.mention_id);
        if ( index !== -1 ) {
            state.mentions.splice(index, 1);
        }

        // Decrement unread mentions count if deleted mention was never read
        if ( !mention.read_at ) {
            state.unread_mentions_count--;
        }
    },
};

import * as getters from './getters';
import * as actions from './actions';

export default new Vuex.Store({
    state,
    mutations,
    getters,
    actions,
    modules
});
