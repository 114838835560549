<template>
    <vault-icon :icon="icon" :style="{color: hex}"></vault-icon>
</template>

<script>
    // External Dependencies
    import {faPlus} from '@fortawesome/pro-solid-svg-icons/faPlus';

    // Internal Dependencies
    import BaseIcon from './BaseIcon';

    export default BaseIcon.extend({
        name: 'IconAdd',

        data() {
            return {
                icon: faPlus,
            }
        }
    });
</script>
