/**
 *
 * @param commit
 * @param data
 * @param paginator
 */
export const setCustomProcesses = ({commit}, {data, paginator}) => {
    commit('SET_CUSTOM_PROCESSES', {data, paginator});
};

/**
 *
 * @param commit
 * @param data
 * @param paginator
 */
export const setCustomProcessLogs = ({commit}, {data, paginator}) => {
    commit('SET_CUSTOM_PROCESS_LOGS', {data, paginator});
};


/**
 *
 * @param commit
 * @param custom_process
 */
export const setCustomProcess = ({commit}, custom_process) => {
    commit('SET_CUSTOM_PROCESS', custom_process);
};

export const updateCustomProcessField = ({commit}, {custom_process, field, value}) => {
    commit('UPDATE_CUSTOM_PROCESS_FIELD', {custom_process, field, value});
};

/**
 *
 * @param commit
 */
export const resetCustomProcess = ({commit}) => {
    commit('RESET_CUSTOM_PROCESS');
};

