/**
 * Set work order classes and potentially items
 *
 * @param commit
 * @param {String} type
 * @param {[Object]} classes
 */
export const setClasses = ({commit}, {type, classes}) => {
    commit('SET_CLASSES', {type, classes});
};

/**
 * Add a new class group
 * @param commit
 * @param new_class
 */
export const addClass = ({commit}, new_class) => {
    commit('ADD_CLASS', new_class);
};

/**
 * Update an existing class group
 *
 * @param commit
 * @param existing_class
 */
export const updateClass = ({commit}, existing_class) => {
    commit('UPDATE_CLASS', existing_class);
};

/**
 * Add a new service to a class group
 *
 * @param commit
 * @param service
 */
export const addServiceToClass = ({commit}, service) => {
    commit('ADD_SERVICE_TO_CLASS', service);
};

/**
 * Set a service item in the store
 *
 * @param commit
 * @param service_item
 */
export const setServiceItem = ({commit}, service_item) => {
    commit('SET_SERVICE_ITEM', service_item);
};

/**
 * Update an existing service item in the store
 *
 * @param commit
 * @param existing_class
 */
export const updateService = ({commit}, existing_class) => {
    commit('UPDATE_SERVICE_ITEM', existing_class);
};

/**
 * Delete an existing service from class list in the store
 *
 * @param commit
 * @param service_item_class_id
 * @param service_item_id
 */
export const deleteService = ({commit}, {service_item_class_id, service_item_id}) => {
    commit('DELETE_SERVICE_ITEM', {service_item_class_id, service_item_id});
};

/**
 * Delete a work order ticket group
 *
 * @param commit
 * @param {Object} service_item_class
 */
export const deleteClass = ({commit}, service_item_class) => {
    commit('DELETE_CLASS', service_item_class);
};

/**
 * Put service item in store
 *
 * @param commit
 * @param ticket_item
 */
export const editService = ({commit}, service) => {
    commit('EDIT_SERVICE', Object.assign({}, service));
};

export const editServiceProperty = ({commit}, {property, value}) => {
    commit('EDIT_SERVICE_PROPERTY', {property, value});
}

/**
 * Clear edit service
 *
 * @param commit
 */
export const clearEditService = ({commit}) => {
    commit('CLEAR_EDIT_SERVICE');
};

//
// Categories
//

/**
 * Set work order categories
 *
 * @param commit
 * @param categories
 */
export const setCategories = ({commit}, categories) => {
    commit('SET_CATEGORIES', categories);
};

/**
 * Add a new category
 *
 * @param commit
 * @param category
 */
export const addCategory = ({commit}, category) => {
    commit('ADD_CATEGORY', category);
};

/**
 * Put an edit category into the store
 *
 * @param commit
 * @param category
 */
export const editCategory = ({commit}, category) => {
    commit('EDIT_CATEGORY', category);
};

/**
 * Clear the edit category
 *
 * @param commit
 */
export const clearEditCategory = ({commit}) => {
    commit('CLEAR_EDIT_CATEGORY');
};

/**
 * Move a category's parent
 *
 * @param commit
 * @param category
 */
export const moveCategory = ({commit}, category) => {
    commit('MOVE_CATEGORY', category);
};

/**
 * The category that the user wants to delete
 *
 * @param commit
 * @param category
 */
export const delCategory = ({commit}, category) => {
    commit('DEL_CATEGORY', category);
};

//
// Projects
//

/**
 * Set the projects list
 *
 * @param commit
 * @param projects
 */
export const setProjects = ({commit}, projects) => {
    commit('SET_PROJECTS', projects);
};

/**
 * Add a new project to the store
 *
 * @param commit
 * @param project
 */
export const addProject = ({commit}, project) => {
    commit('ADD_PROJECT', project);
};

/**
 * Set project to edit in the store
 *
 * @param commit
 * @param project
 */
export const editProject = ({commit}, project) => {
    commit('EDIT_PROJECT', project);
};

/**
 * Update project in store
 *
 * @param commit
 * @param project
 */
export const updateProject = ({commit}, project) => {
    commit('UPDATE_PROJECT', project);
};

/**
 * Clear the edit project
 *
 * @param commit
 */
export const clearEditProject = ({commit}) => {
    commit('CLEAR_EDIT_PROJECT');
};

/**
 * Remove project from the store
 *
 * @param commit
 * @param ticket_project_id
 */
export const deleteProject = ({commit}, ticket_project_id) => {
    commit('DEL_PROJECT', ticket_project_id);
};

/**
 * Set work order statuses
 *
 * @param commit
 * @param statuses
 */
export const setStatuses = ({commit}, statuses) => {
    commit('SET_STATUSES', statuses);
};
