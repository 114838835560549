import Vue from 'vue';
import _sortBy from 'lodash/sortBy';

const state = {
    inventory: {
        data: [],
        paginator: {}
    },
    locations: {
        data: [],
        paginator: {}
    },
    inventory_location: {},
    reference: {},
    drag_data: null,
    item: null,
    equipment: {
        data: [],
        paginator: {}
    },
    parts: [],
};

const mutations = {
    SET_REFERENCES(state, {data, paginator}) {
        state.locations = {data, paginator};
    },

    SET_REFERENCE(state, data) {
        Vue.set(state, 'reference', {...data});
    },

    SET_INVENTORY(state, {data, paginator}) {
        state.inventory = {data, paginator};
    },

    SET_DRAG_DATA(state, drag_data) {
        state.drag_data = {...drag_data};
    },

    SET_ITEM(state, item) {
        state.item = {...item};
    },

    RESET_ITEM(state) {
        state.item = null;
    },

    SET_INVENTORY_EQUIPMENT(state, {data, paginator}) {
        state.equipment = {data, paginator};
    },

    SET_INVENTORY_LOCATION(state, inventory_location) {
        Vue.set(state, 'inventory_location', inventory_location);
    },

    SET_USED_PARTS(state, parts) {
        state.parts = _sortBy(parts, ['item.name', 'item.user_defined_number']);
    },

    ADD_USED_PART(state, {inventory_transaction_id, item, quantity, inventory_quantity}) {
        // try to update an existing used part
        let index = state.parts.findIndex(part => item.service_item_id === part.item.service_item_id);
        if ( index >= 0 ) {
            // remove if the part hasn't been used yet and is quantity 0
            if ( !inventory_transaction_id && quantity === 0 ) {
                state.parts.splice(index, 1);

            // otherwise update the quantity
            } else {
                Vue.set(state.parts[index], 'quantity', quantity);
            }

        // otherwise add it
        } else if ( quantity > 0 ) {
            mutations.SET_USED_PARTS(state, [
                ...state.parts,
                {item, inventory_quantity, quantity},
            ]);
        }
    },
};

import * as getters from './getters';
import * as actions from './actions';

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
