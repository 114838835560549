export function locations(state) {
    return state.locations;
}

export function location(state) {
    return state.location;
}

export function pagination(state) {
    return state.pagination.locations;
}

export function states(state) {
    return state.states;
}

export function counties(state) {
    return state.counties;
}

export function municipalities(state) {
    return state.municipalities;
}

/**
 *
 * @param state
 * @returns {Set<number>}
 */
export function countyIds(state) {
    return new Set(state.counties.map(({county_id}) => county_id));
}

export function all_states(state) {
    return state.all_states;
}

export function countries(state) {
    return state.countries;
}

export function reference(state) {
    return state.reference;
}

export function country_id(state) {
    return state.country_id;
}

export function state_id(state) {
    return state.state_id;
}

export function county_id(state) {
    return state.county_id;
}

export function formEntries(state) {
    return state.form_entries;
}

/**
 * @param state
 * @returns {[{is_required: boolean, label: string, field_type: string, key: string}, {is_required: boolean, label: string, field_type: string, key: string}, {is_required: boolean, label: string, field_type: string, key: string}, {is_required: boolean, label: string, field_type: string, key: string}, {is_required: boolean, label: string, field_type: string, key: string}]|*}
 */
export function default_fields(state) {
    return state.default_fields;
}
