<template>
    <ks-modal
        :title="title"
        :danger="danger"
        ref="modal"
        @close="close(false)"
        :min-width="minWidth"
        :min-height="minHeight"
        :max-width="maxWidth"
        :max-height="maxHeight"
    >
        <slot></slot>

        <div slot="footer">
            <vault-button :spin="saving" @click.prevent="save" :class="{ danger }" type="save">
                {{saveButtonLabel}}
            </vault-button>
            <button class="button outline" @click.prevent="close">Cancel</button>
        </div>
    </ks-modal>
</template>

<script>
    // External Dependencies

    // Internal Dependencies

    export default {
        name: 'FormModal',

        props: {
            title: {
                type: String,
                default: 'Save'
            },

            saveButtonLabel: {
                type: String,
                default: 'Save'
            },

            danger: {
                type: Boolean,
                default: false,
            },

            minWidth: {
                type: String,
                default: null,
            },

            maxWidth: {
                type: String,
                default: null,
            },

            minHeight: {
                type: String,
                default: null
            },

            maxHeight: {
                type: String,
                default: null
            },
        },

        data() {
            return {
                form: null,
                saving: false
            };
        },

        methods: {
            registerForm(form) {
                this.form = form;
            },

            open(model_id = null) {
                this.checkForm();

                if (model_id) {
                    this.form.fetchModel(model_id);
                }

                this.$refs.modal.open();
                this.$nextTick(() => {
                    if ( typeof this.form.initForm === 'function' ) {
                        this.form.initForm();
                    }
                })
            },

            close(close_modal = true) {
                if (typeof this.form.clearForm === 'function') {
                    this.form.clearForm();
                }

                this.$emit('close');

                if (close_modal) {
                    this.$refs.modal.close();
                }
            },

            save() {
                this.checkForm();

                if (typeof this.form.save !== 'function') {
                    console.error('Form component missing required save() method.');
                }

                this.saving = true;

                return this.form.save().then(r => {
                    this.close(true);
                }).catch((error) => {
                    console.error(error);
                }).finally(() => {
                    this.saving = false;
                });
            },

            checkForm() {
                if (!this.form) {
                    console.error('FormModel: form property missing or not set');
                }
            },
        },
    }
</script>
