import Vue from 'vue';

const blank_customer = {
    customer_name: '',
    customer_id: null,
    price_profile_id: '',
    billing_term_id: '',
    company: 0,
    address1: '',
    address2: '',
    city: '',
    country_id: '',
    state_id: '',
    zip: '',
}
const blank_contact_location = {
    location_number: '',
    address1: '',
    address2: '',
    city: '',
    zip: '',
    state_id: '',
    county_id: '',
    country_id: '1',
};
const blank_contact = {
    first_name: '',
    last_name: '',
    company_name: '',
    email: '',
    phone_number: '',
    default: 0,
    allow_sms: 0,
    is_billing: 1,
    location_id: '',
    contact_number: '',
    location: Object.assign({}, blank_contact_location),
    external_id: '',
};
const state = {
    customers: [],
    office: [],
    pagination: {
        customers: {},
        contacts: {},
        users: {},
    },
    customer: {
        customer_name: '',
        customer_id: null,
        contacts: [],
        default_contact: null,
        price_profile_id: 0,
        sub_price_profile_id: 0,
        users_count: 0,
        users: [],
        configs: [],
        target_data_maps: [],
    },
    contact: {
        first_name: '',
        last_name: '',
        company_name: '',
        email: '',
        phone_number: '',
        default: 0,
        allow_sms: 0
    },
    users: [],
    active_notification_profiles: [],
    available_notification_profiles: [],
    customer_contacts: [],
    customer_locations: [],
    contact_types: [],
    price_profiles: [],
    billing_terms: [],
    blank_contact_location: blank_contact_location,
    blank_contact: blank_contact,
};

const mutations = {
    SET_CUSTOMERS (state, customers) {
        state.customers = customers;
    },
    SET_CUSTOMERS_PAGINATION (state, pagination) {
        state.pagination.customers = pagination;
    },
    SET_CONTACTS_PAGINATION (state, pagination) {
        state.pagination.contacts = pagination;
    },
    SET_CUSTOMER (state, customer) {
        Vue.set(state, 'customer', customer);
    },
    RESET_CUSTOMER (state) {
        Vue.set(state, 'customer', blank_customer);
    },
    ADD_CUSTOMER (state, customer) {
        state.customers.push(customer);
    },
    SET_CUSTOMER_LOCATIONS (state, locations) {
        Vue.set(state, 'customer_locations', locations);
    },
    SET_CONTACT (state, contact) {
        Vue.set(state, 'contact', contact);
    },
    SET_CONTACT_DEFAULT (state, default_val) {
        state.contact.default = default_val;
    },
    SET_CUSTOMER_CONTACTS (state, contacts) {
        state.customer_contacts = contacts;
    },
    UPDATE_CONTACT (state, contact) {
        let index = state.customer_contacts.findIndex(({customer_contact_id}) => customer_contact_id === contact.customer_contact_id);
        if ( index >= 0 ) {
            state.customer_contacts.splice(index, 1, contact);
        }

        if ( state.customer.default_contact.customer_contact_id === contact.customer_contact_id ) {
            Vue.set(state.customer, 'default_contact', contact);
        }
    },
    RESET_CONTACT (state, data) {
        Vue.set(state, 'contact', Object.assign(blank_contact, data));
    },
    ADD_CONTACT_NOTE (state, note) {
        let index = state.customer_contacts.findIndex(({customer_contact_id}) => customer_contact_id === note.reference_id);
        if ( index < 0 ) {
            return;
        }
        let count = state.customer_contacts[index].notes_count || 0;
        Vue.set(state.customer_contacts[index], 'notes_count', count + 1)
    },
    SET_PRICE_PROFILES (state, price_profiles) {
        state.price_profiles = price_profiles;
    },
    SET_BILLING_TERMS (state, billing_terms) {
        state.billing_terms = billing_terms;
    },
    SET_CUSTOMER_OFFICE (state, office) {
        state.office = office;
    },
    SET_CONTACT_TYPES (state, types) {
        state.contact_types = types;
    },
    SET_CUSTOMER_USERS (state, {users, paginator}) {
        state.users = [...users];
        state.pagination.users = {...paginator};
    },
    SET_AVAILABLE_NOTIFICATION_PROFILES(state, profiles) {
        Vue.set(state, 'available_notification_profiles', profiles);
    },
    SET_ACTIVE_NOTIFICATION_PROFILES(state, profiles) {
        Vue.set(state, 'active_notification_profiles', profiles);
    },
};

import * as getters from './getters';
import * as actions from './actions';

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
