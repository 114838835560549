/**
 *
 * @param state
 * @returns {string[]}
 */
export function config_options(state) {
    return Object.keys(state.configs);
}

/**
 *
 * @param state
 * @returns {EntityConfig}
 */
export function configs(state) {
    return state.configs;
}
