import {isUndefined, isObject, isNull, isArray} from 'helpers/types';
import {calculateRotation} from '@Vault/Diagrams/diagram-helpers';
import {PointArray} from '@svgdotjs/svg.js';

const HIDDEN_CLASS = 'off-canvas';

/**
 * @property {function()} parent
 * @property {function()} id
 */
export default class DiagramElement {
    constructor(element) {
        this.element = element;
        this.element_reference = {}
        this.diagram_data_id = 0;
        this.$label = '';
        this.$description = '';
        this.$params = {};
        this.$zIndex = 0;

        return new Proxy(this, {
            get: function (element, field, receiver) {
                if ( field in element ) {
                    return element[field];
                }

                const target = Reflect.get(element.element, field, receiver);

                if ( typeof target === 'function' ) {
                    return function (...args) {
                        return target.apply(element.element, args);
                    }
                }

                return element.element[field];
            },

            set: function (element, field, value, receiver) {
                if ( field in element ) {
                    element[field] = value;

                    return true;
                }

                const target = Reflect.get(element.element, field, receiver);

                element.element[field] = value;

                return true;
            },
        });
    }

    hasHiddenElement() {
        return this.element.type === 'line';
    }

    zIndex(index) {
        this.$zIndex = index;

        return this;
    }

    get z_index() {
        return this.$zIndex;
    }

    get reference_type() {
        return this.element_reference.reference_type || '';
    }

    get reference_id() {
        return this.element_reference.reference_id || 0;
    }

    get shape_name() {
        return this.element_reference.shape_name || this.element.type;
    }

    get is_custom_element() {
        return this.reference_type === 'element';
    }

    get is_text() {
        return this.reference_type === 'shape' && this.shape_name.toLowerCase() === 'text';
    }

    get is_hidden() {
        return this.hasClass(HIDDEN_CLASS);
    }

    reference(reference) {
        if ( isUndefined(reference) ) {
            return this.element_reference;
        }

        const {reference_type, reference_id, shape_name} = reference;
        this.element_reference = {
            reference_type: reference_type || '',
            reference_id: reference_id || 0,
            shape_name,
        }

        return this;
    }

    diagramDataId(diagram_data_id) {
        this.diagram_data_id = diagram_data_id;

        return this;
    }

    selected() {
        if ( this.is_custom_element ) {
            return this.element.data('selected');
        }

        return this.parent().data('selected');
    }

    select() {
        if ( !this.selected() ) {
            if ( this.is_custom_element ) {
                this.element.data('selected', 1);
            } else{
                this.parent().data('selected', 1);
            }

            this.element.selectize().resize(true, {
                constrain: !!this.params('constrain'),
                diagram_element: this
            });
        }

        return this;
    }

    deselect() {
        if ( this.selected() ) {
            if ( this.is_custom_element ) {
                this.element.data('selected', null);
            } else {
                this.parent().data('selected', null);
            }

            this.element.selectize(false).resize('stop');
        }

        return this;
    }

    box() {
        return this.element.bbox();
    }

    z() {
        if ( this.is_custom_element ) {
            return this.element.position();
        }

        return this.parent().position();
    }

    label(label) {
        if ( isUndefined(label) ) {
            return this.$label || '';
        }

        this.$label = label;

        return this;
    }

    description(description) {
        if ( isUndefined(description) ) {
            return this.$description || '';
        }

        this.$description = description;

        return this;
    }

    params(key, value) {
        if ( isUndefined(key) && isUndefined(value) ) {
            return this.$params;
        }

        if ( isNull(value) ) {
            delete this.$params[key];

            return this;
        }

        if ( isUndefined(value) ) {
            return this.$params[key] || null;
        }

        if ( isObject(key) ) {
            for ( let [$key, $value] in Object.entries(key) ) {
                this.$params[$key] = $value;
            }
        } else {
            this.$params[key] = value;
        }

        return this;
    }

    meta(meta) {
        if ( isUndefined(meta) ) {
            const attr = this.element.attr();
            const data = {
                height: this.element.height(),
                width: this.element.width(),
                rotate: calculateRotation(this.element.transform().rotate),
                fill: attr.fill,
                stroke: attr.stroke,
                'stroke-width': attr['stroke-width'],
            };

            if ( 'points' in attr ) {
                data['points'] = attr.points;
            }
            if ( data.rotate !== 0 ) {
                const bbox = this.element.bbox();
                data['cx'] = bbox.cx;
                data['cy'] = bbox.cy;
            }
            if ( 'stroke-dasharray' in attr ) {
                data['stroke-dasharray'] = attr['stroke-dasharray'];
            }

            const rounded_factor = this.element.data('rounded-factor');
            if ( !isUndefined(rounded_factor) ) {
                data['rounded-factor'] = rounded_factor;
            }

            if ( this.is_text ) {
                data['text_size'] = this.element.font('size');
                data['text'] = this.element.text();
            }

            for ( let [$key, $value] of Object.entries(this.$params) ) {
                data[$key] = $value;
            }

            return data;
        }

        const meta_ignore = ['height', 'width', 'fill', 'stroke', 'stroke-width', 'points', 'rotate', 'text', 'text_size', 'rounded-factor', 'cx', 'cy'];
        const data = {
            fill: meta.fill,
            stroke: meta.stroke,
            'stroke-width': meta['stroke-width']
        };

        if ( 'points' in meta ) {
            data['points'] = meta.points;
        }

        if ( 'rotate' in meta && meta.rotate !== 0 && 'cx' in meta && 'cy' in meta ) {
            data['transform'] = `rotate(${calculateRotation(meta.rotate)}, ${meta.cx}, ${meta.cy})`
        }

        if ( this.is_text ) {
            this.element.font('size', meta.text_size);
        }

        const box = this.box();
        if ( this.element.size.length > 1 ) {
            this.element.size(meta.width, meta.height);
        } else {
            this.element.size(meta.width);
        }
        this.move(box.x, box.y);
        this.element.attr(data);

        for ( let [$key, $value] of Object.entries(meta) ) {
            if ( !meta_ignore.includes($key) ) {
                this.$params[$key] = $value;
            }
        }
    }

    attr(key = null, value = null) {
        if ( isNull(key) && isNull(value) ) {
            return this.element.attr();
        }

        if ( isNull(value) ) {
            if ( isArray(key) ) {
                return this.element.attr(key);
            }

            if ( isObject(key) ) {
                this.element.attr(key);

                return this;
            }

            return this.element.attr(key);
        }

        this.element.attr(key, value);

        return this;
    }

    click(callback)  {
        this.element.click(callback.bind(this));

        return this;
    }

    on(event, callback) {
        this.element.on(event, callback.bind(this));

        return this;
    }

    updateRotatePosition(rotate = false) {
        const box = this.box();
        if ( rotate === false ) {
            rotate = this.element.transform().rotate;
        }

        if ( typeof rotate !== 'undefined' || rotate !== false ) {
            this.element.attr('transform', `rotate(${calculateRotation(rotate)}, ${box.cx}, ${box.cy})`);
        }
    }

    move(x, y) {
        if ( this.element.type === 'polygon' ) {
            const points = new PointArray(this.element.attr('points'));
            this.element.attr('points', points.move(x, y));
        } else {
            this.element.move(x, y);
        }

        if ( this.hasHiddenElement() ) {
            this.element.fire('endresize');
        }

        return this;
    }

    x(x) {
        if ( isUndefined(x) ) {
            return this.element.x() || this.element.bbox().x;
        }

        if ( this.hasHiddenElement() && this.parent() !== null ) {
            this.parent().x(x);

            return this;
        }

        this.element.x(x);

        return this;
    }

    y(y) {
        if ( isUndefined(y) ) {
            return this.element.y() || this.element.bbox().y;
        }

        if ( this.hasHiddenElement() && this.parent() !== null ) {
            this.parent().y(y);

            return this;
        }

        this.element.y(y);

        return this;
    }

    hasClass(class_name) {
        if ( this.element.parent() !== null && !this.is_custom_element ) {
            return this.element.parent().hasClass(class_name);
        }

        return this.element.hasClass(class_name);
    }

    addClass(class_name) {
        if ( this.element.parent() !== null && !this.is_custom_element ) {
            return this.element.parent().addClass(class_name);
        }

        return this.element.addClass(class_name);
    }

    removeClass(class_name) {
        if ( this.element.parent() !== null && !this.is_custom_element ) {
            return this.element.parent().removeClass(class_name);
        }

        return this.element.removeClass(class_name);
    }

    hide() {
        this.addClass(HIDDEN_CLASS);

        return this;
    }

    show() {
        this.removeClass(HIDDEN_CLASS);

        return this;
    }
}
