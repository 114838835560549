/**
 *
 * @param state
 * @returns {{data: [], paginator: {next_page: null, total_count: number, limit: number, total_pages: number, previous_page: null, current_page: number}}|{data: [], paginator: {next_page: null, total_count: number, limit: number, total_pages: number, previous_page: null, current_page: number}}|*}
 */
export function custom_processes(state) {
    return state.custom_processes;
}

/**
 *
 * @param state
 * @returns {null}
 */
export function custom_process(state) {
    return state.custom_process;
}

/**
 *
 * @param state
 * @returns {null}
 */
export function custom_process_logs(state) {
    return state.logs;
}