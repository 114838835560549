import ServiceItems from '@Vault/Admin/WorkOrder/repositories/ServiceItemRepository.js';
import _debounce from 'lodash/debounce';

/**
 *
 * @param commit
 * @param field_types
 */
export function setFieldTypes({commit}, field_types) {
    commit('SET_FIELD_TYPES', field_types)
}

/**
 *
 * @param commit
 * @param fields
 */
export function setFields({commit}, {group, fields}) {
    commit('SET_FIELDS', {group, fields});
}

/**
 *
 * @param commit
 * @param field
 */
export function setField({commit}, field) {
    commit('SET_FIELD', field);
}

/**
 *
 * @param commit
 */
export function resetField({commit}) {
    commit('RESET_FIELD');
}

/**
 *
 * @param commit
 * @param data
 * @param paginator
 */
export function setLibraries({commit}, {data, paginator}) {
    commit('SET_LIBRARIES', {data, paginator});
}

/**
 *
 * @param commit
 * @param {ServiceItem[]} items
 */
export function cacheItems({commit}, items) {
    commit('CACHE_ITEMS', items);
}

/**
 *
 * @param commit
 */
export function clearCachedItems({commit}) {
    commit('CLEAR_CACHED_ITEMS');
}

/**
 * Get service item data for the given service_item_ids, and merge into the store data.  This debounces and
 * consolidates multiple simultaneous requests for loading item data so we don't hammer the endpoint.
 * @param commit
 * @param {?[]} service_item_ids
 */
export function loadServiceItemData({commit}, service_item_ids = null) {
    if ( !service_item_ids?.length ) {
        return;
    }

    service_item_ids.forEach(id => service_item_ids_waiting_to_load.add(id));

    // fetch requested items, merge them into the store
    return debouncedLoadServiceItemData(commit);
}

const service_item_ids_waiting_to_load = new Set();
const debouncedLoadServiceItemData = _debounce(commit => {
    ServiceItems.list({ service_item_ids: [...service_item_ids_waiting_to_load].join(','), ignore_price_profile: true })
        .then(({data}) => {
            commit('CACHE_ITEMS', data);
        });

    service_item_ids_waiting_to_load.clear();
}, 100);
