import Vue from 'vue';

import AddressInfo from './AddressInfo.vue';
import PageBreadcrumb from './PageBreadcrumb.vue';
import PageHeader from './PageHeader.vue';
import SearchBar from './SearchBar.vue';
import FormGroup from './FormGroup.vue';
import UserRegistration from '../Registration/User/UserRegistration.vue';
import FormModal from './FormModal.vue';

Vue.component('address-info', AddressInfo);
Vue.component('page-breadcrumb', PageBreadcrumb);
Vue.component('page-header', PageHeader);
Vue.component('search-bar', SearchBar);
Vue.component('form-group', FormGroup);
Vue.component('user-registration', UserRegistration);
Vue.component('form-modal', FormModal);

Vue.component('vault-icon', () => import('./Icons/Icon'));
Vue.component('vault-button', () => import('./Buttons/VaultButton.vue'));
