<template>
    <vault-icon :icon="icon" :style="{color: hex}"></vault-icon>
</template>

<script>
    // External Dependencies
    import { faUserFriends } from '@fortawesome/pro-regular-svg-icons/faUserFriends.js';

    // Internal Dependencies
    import BaseIcon from './BaseIcon';

    export default BaseIcon.extend({
        name: 'IconSavedSearchPublic',

        data() {
            return {
                icon: faUserFriends,
            };
        },
    })
</script>
