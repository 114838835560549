// External Dependencies
import Vue from 'vue';
import {object_get} from 'kickstart-ui/lib/objects';
import _findIndex from 'lodash/findIndex';
import {isArray} from 'helpers/types';
import deepSet from 'helpers/deepSet';
import cloneDeep from 'lodash/cloneDeep';

// Blank Work
const blank_work_order = {
    work_id: 0,
    entity_id: 0,
    location_id: 0,
    work_status_id: null,
    description: '',
    customer_work_number: '',
    recurring: 0,
    start_date: '',
    start_time: '',
    end_date: '',
    end_time: '',
    duration_type: null,
    duration_value: null,
    account_manager_id: null,
    service_reason_id: null,

    frequency_type: '',
    frequency_value: '',
    frequency_day_of_week: null,
    frequency_day_of_month: null,
    due_within_days: '',
    closed_on: null,

    assigned_to: [],
    add_edoc: [],
    files: [],
    monitors: [],
    items: [],
};

const blank_schedule_filters = {
    start_date: '',
    assigned: '',
    states: [],
    customer: [],
    account_manager_id: 0,
    status: '',
    work_category: [],
    is_work_category_negated: false,
    field_user_role_id: 0,
    contact_reference_id: 0,
    assigned_to: 0,
    show_unscheduled: false,
    description: '',
    service_item_category: {
        service_item_class_id: 0,
        item_class: '',
    },
    highlight_user_id: 0,
    work_project: [],
};

const blank_work_schedule = {
    recurring: 0,
    start_date: '',
    end_date: '',
    any_time: false,
    duration_type: null,
    duration_value: null,
    frequency_type: '',
    frequency_value: '',
    frequency_day_of_week: '',
    frequency_day_of_month: '',
    due_within_days: '',
    schedule_days: [1,2,3,4,5],
    sync_users: false,
    override_schedule_window: false,
};

const state = {
    style_code: 'W',
    schedule_date: new Date(),
    grid_view: 'list',
    customer: null,
    billing_customer: null,
    location: null,
    location_diagram: null,
    contact: null,
    work_order: cloneDeep(blank_work_order),
    visit_items: [],
    // Data for bulk workorder actions.
    bulk: {
        selected: {}
    },
    work_order_errors: {},
    resources: {
        categories: [],
        projects: [],
        statuses: [{
            label: 'Open',
            work_status: 'open',
            color: '#41a541'
        }, {
            label: 'Closed',
            work_status: 'closed',
            color: '#555555'
        }]
    },
    default_filters: cloneDeep(blank_schedule_filters),
    schedule_filters: cloneDeep(blank_schedule_filters),
    notes: [],
    work_schedule: cloneDeep(blank_work_schedule),
    generating_visits_handler: null,
    generating_visits: false,
    generate_visits_progress: 0,
    drag_data: {},
    invoices: null,
    bulk_visits: {},

    selections: {
        selected_items: [],
        available_items: [],
    },

    schedule_list: {
        visits: {
            today: {},
            tomorrow: {},
            next_five_days: {},
        },
        route_groups: {},
    },
    schedule_assigned_list: {},
    schedule_loading: {
        scheduled: false,
        unscheduled: false,
    },
};

const mutations = {
    SET_CUSTOMER (state, customer) {
        Vue.set(state, 'customer', customer);
    },

    SET_BILLING_CUSTOMER (state, customer) {
        Vue.set(state, 'billing_customer', customer);
    },

    SET_LOCATION (state, location) {
        Vue.set(state, 'location', location);
    },

    SET_LOCATION_DIAGRAM(state, diagram) {
        Vue.set(state, 'location_diagram', diagram);
    },

    SET_CONTACT (state, contact) {
        Vue.set(state, 'contact', contact);
    },

    SET_NOTES (state, notes) {
        Vue.set(state, 'notes', notes);
    },

    CLEAR_NOTES (state) {
        Vue.set(state, 'notes', []);
    },

    ADD_NOTE (state, note) {
        state.notes.push(note);
    },

    SET_WORK_ORDER_ATTRIBUTE (state, {where, value, push}) {
        if ( push ) {
            if ( typeof state.work_order[where] == 'undefined' ) {
                Vue.set(state.work_order, where, []);
            }

            return state.work_order[where].push(value);
        }

        Vue.set(state.work_order, where, value);
    },

    UPDATE_WORK_ORDER_SERVICE_ITEM (state, {where, value, item}) {
        let index = _findIndex(state.work_order.items, o => {
            return item.service_item_id === o.service_item_id;
        });

        Vue.set(state.work_order.items[index], where, value);
    },

    SET_WORK_ORDER (state, work_order) {
        Vue.set(state, 'location', work_order.location);

        if ( 'customer' in work_order ) {
            Vue.set(state, 'customer', work_order.customer);
        }

        if ( 'billing_customer' in work_order ) {
            Vue.set(state, 'billing_customer', work_order.billing_customer);
        }

        if ( work_order.location && object_get(work_order.location, 'customer', false) ) {
            // Only set customer from location if it's not in the original data
            if ( !('customer' in work_order) ) {
                Vue.set(state, 'customer', work_order.location.customer);
            }

            delete work_order.location.customer;
            delete work_order.location;
        }

        if ( object_get(work_order, 'contact', false) ) {
            Vue.set(state, 'contact', work_order.contact);

            if ( !('billing_customer' in work_order) && object_get(work_order.contact, 'customer', false) ) {
                // Only set billing_customer from the contact if it's not in the original data
                Vue.set(state, 'billing_customer', work_order.contact.customer);

                delete work_order.contact.customer;
            }

            delete work_order.contact;
        }

        Vue.set(state, 'work_order', work_order);
    },

    RESET_WORK_ORDER (state) {
        state.work_order = cloneDeep(blank_work_order);
        state.location = null;
        state.customer = null;
        state.contact = null;
    },

    SET_VISIT_ITEMS (state, items) {
        Vue.set(state, 'visit_items', items);
    },

    REMOVE_MONITOR (state, monitor) {
        let where = 'monitored_by';
        let value = monitor.user_id;
        let monitor_key = 'user_id';

        if ( monitor.notification_email_id > 0 ) {
            where = 'monitored_email_id';
            value = monitor.notification_email_id;
            monitor_key = 'notification_email_id';
        }

        if ( object_get(monitor, 'ticket_id', false) === false ) {
            let index = state.work_order[where].indexOf(value);

            if ( index !== -1 ) {
                state.work_order[where].splice(index, 1);
            }
        }

        let monitors_index = _findIndex(state.work_order.monitors, (o) => {
            return o[monitor_key] === monitor[monitor_key];
        });

        if ( monitors_index !== -1 ) {
            state.work_order.monitors.splice(monitors_index, 1);
        }
    },

    REMOVE_FILE (state, edoc_file_id) {
        let index = _findIndex(state.work_order.files, (o) => {
            return o.edoc_file_id === edoc_file_id;
        });

        if ( index !== -1 ) {
            state.work_order.files.splice(index, 1);
        }

        if ( typeof state.work_order.add_edoc != 'undefined' ) {
            let add_edoc_index = state.work_order.add_edoc.indexOf(edoc_file_id);

            if ( add_edoc_index !== -1 ) {
                state.work_order.add_edoc.splice(add_edoc_index, 1);
            }
        }
    },

    REMOVE_SERVICE_ITEM (state, item) {
        let index = state.work_order.items.indexOf(item);

        if ( index !== -1 ) {
            state.work_order.items.splice(index, 1);
        }
    },

    ADD_SELECTED_WORKORDER_ID(state, {key, ids}) {
        if (!(key in state.bulk.selected)) {
            Vue.set(state.bulk.selected, key, {});
        }

        if (isArray(ids)) {
            // Do a slice here to prevent duplication on the add
            ids.forEach((item) => {
                Vue.set(state.bulk.selected[key], parseInt(item), true);
            });
        } else {
            Vue.set(state.bulk.selected[key], parseInt(ids), true);
        }
    },

    REMOVE_SELECTED_WORKORDER_ID(state, {key, ids}) {
        if (isArray(ids)) {
            ids.forEach(function(item) {
                Vue.set(state.bulk.selected[key], parseInt(item), false);
            });
        } else {
            Vue.set(state.bulk.selected[key], parseInt(ids), false);
        }
    },

    CLEAR_SELECTED_WORKORDERS(state, key) {
        Vue.set(state.bulk.selected, key, {});
    },

    SET_ERRORS (state, errors) {
        state.work_order_errors = errors;
    },

    CLEAR_ERRORS (state) {
        state.work_order_errors = {};
    },

    SET_RESOURCE(state, {resource, value}) {
        Vue.set(state.resources, resource, value);
    },

    SET_SCHEDULE_DATE(state, date) {
        state.schedule_date = date;
    },

    SET_SCHEDULE_FILTER(state, {filter, value}) {
        deepSet(state.schedule_filters, filter, value);
    },

    CLEAR_SCHEDULE_FILTERS(state) {
        let active_start_date = state.schedule_filters.start_date;
        let schedule_filters = cloneDeep(state.default_filters);

        schedule_filters.start_date = active_start_date;

        state.schedule_filters = schedule_filters;
    },

    CLEAR_SCHEDULE_FILTER(state, key) {
        Vue.set(state.schedule_filters, key, cloneDeep(state.default_filters[key]));
    },

    SET_WORK_SCHEDULE (state, schedule) {
        Vue.set(state, 'work_schedule', schedule);
    },

    SET_WORK_SCHEDULE_ATTRIBUTE (state, {where, value}) {
        Vue.set(state.work_schedule, where, value);
    },

    RESET_WORK_SCHEDULE (state) {
        Vue.set(state, 'work_schedule', cloneDeep(blank_work_schedule));
    },

    SET_GENERATING_WORK_VISITS (state, status) {
        state.generating_visits = status;
    },

    SET_GENERATE_VISITS_PROGRESS (state, progress) {
        state.generate_visits_progress = progress;
    },

    SET_DRAG_DATA(state, drag_data) {
        Vue.set(state, 'drag_data', drag_data);
    },

    SET_WORK_INVOICES (state, data) {
        Vue.set(state, 'invoices', data);
    },

    SET_GRID_VIEW (state, view) {
        state.grid_view = view;
    },

    ADD_BULK_SCHEDULE_VISIT (state, {visit}) {
        Vue.set(state.bulk_visits, visit.work_visit_number, visit);
    },

    REMOVE_BULK_SCHEDULE_VISIT (state, {visit}) {
        Vue.delete(state.bulk_visits, visit.work_visit_number);
    },

    CLEAR_BULK_SCHEDULE_VISITS (state) {
        Vue.set(state, 'bulk_visits', {});
    },

    SET_AVAILABLE_ITEMS (state, items) {
        state.selections.available_items = [];
        state.selections.available_items = items;
    },

    ADD_TO_SELECTIONS (state, work_id) {
        if (state.selections.selected_items.indexOf(work_id) === -1) {
            state.selections.selected_items.push(work_id);
        }
    },

    SELECT_ALL (state) {
        state.selections.selected_items = state.selections.available_items.slice();
    },

    REMOVE_FROM_SELECTIONS (state, work_id) {
        let match = state.selections.selected_items.indexOf(work_id);

        if ( match !== -1 ) {
            state.selections.selected_items.splice(match, 1);
        }
    },

    RESET_SELECTIONS (state) {
        Vue.set(state.selections, 'selected_items', []);
    },

    CLEAR_SCHEDULE_LIST_VISITS (state, date) {
        Vue.set(state.schedule_list.visits, date, {});
        Vue.set(state.schedule_list.route_groups, date, {});
    },

    ADD_SCHEDULE_LIST_VISITS (state, {date, visits}) {
        if ( !(date in state.schedule_list.visits) ) {
            Vue.set(state.schedule_list.visits, date, {});
        }

        Vue.set(state.schedule_list.route_groups, date, visits);

        visits
            .reduce((acc, v) => acc.concat(v.visits), [])
            .forEach(v => {
                Vue.set(state.schedule_list.visits[date], v.work_visit_number, v);
            });
    },

    SELECT_SCHEDULE_LIST_VISIT (state, {date, work_visit_number, check}) {
        if ( work_visit_number in state.schedule_list.visits[date] ) {
            state.schedule_list.visits[date][work_visit_number].selected = check;
        }
    },

    SELECT_ALL_SCHEDULE_LIST_VISITS (state, {date, check}) {
        if ( date ) {
            for ( const i in state.schedule_list.visits[date] ) {
                state.schedule_list.visits[date][i].selected = check;
            }

            return;
        }

        for ( const date in state.schedule_list.visits ) {
            for ( const i in state.schedule_list.visits[date] ) {
                state.schedule_list.visits[date][i].selected = check;
            }
        }
    },

    SET_SCHEDULE_LOADING (state, data) {
        const name = Object.keys(data).shift();

        state.schedule_loading[name] = data[name];
    },

    SET_SCHEDULE_ASSIGNED_LIST(state, data) {
        Vue.set(state, 'schedule_assigned_list', data);
    },

    SET_GROUP_COLLAPSE (state, {schedule_list_key, group_id, collapsed}) {
        const index = state.schedule_list.route_groups
            ?.[schedule_list_key]
            ?.findIndex(group => group.group_id === group_id);

        if ( index !== -1 ) {
            Vue.set(state.schedule_list.route_groups[schedule_list_key][index], 'collapsed', collapsed);
        }
    },
};

import * as getters from './getters';
import * as actions from './actions';

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
