/**
 * @typedef OptionItem
 * @type {object}
 * @property label
 * @property value
 *
 * @typedef EquipmentGeneralField
 * @type {object}
 * @property {number} condition_form_section_id
 * @property {string} label
 * @property {string} field_type
 * @property {string} field_key
 * @property {string} key
 * @property {string} option_group_label
 * @property {?*} dictionary
 * @property {?object} options
 * @property {OptionItem[]} options.options
 *
 * @typedef EquipmentCustomField
 * @type {object}
 * @mixes CustomField
 * @property {number} condition_form_section_id
 * @property {string} field_key
 * @property {string} option_group_label
 */

/**
 *
 * @param {EquipmentType} equipment_type
 * @param {number} form_section_id
 * @param {?object} merge
 * @returns {EquipmentGeneralField[]}
 */
export function makeEquipmentGeneralFields(equipment_type, form_section_id, merge = {}) {
    equipment_type.makes ??= [];

    return [
        {
            label: 'Equipment Number',
            field_key: 'equipment_number',
            key: 'equipment_number',
            field_type: 'text',
        },
        {
            label: 'Make',
            field_key: 'equipment_make_id',
            key: 'make.make',
            field_type: 'select',
            dictionary: equipment_type.makes.reduce((dictionary, make) => {
                dictionary[make.equipment_make_id] = make.make;
                return dictionary;
            }, {}),
            options: {
                options: equipment_type.makes.map((make) => {
                    return {label: make.make, value: make.equipment_make_id}
                }),
            },
        },
        {
            label: 'Model',
            field_key: 'equipment_model_id',
            key: 'model.model',
            field_type: 'select',
            dictionary: equipment_type.makes.reduce((dictionary, make) => {
                make.models.reduce((dictionary, model) => {
                    dictionary[model.equipment_model_id] = model.model;
                    return dictionary;
                }, dictionary);
                return dictionary;
            }, {}),
            options: {
                options: equipment_type.makes.reduce((makes, make) => {
                    return makes.concat(make.models.map((model) => {
                        return {
                            label: model.model,
                            value: model.equipment_model_id,
                            group: make.make
                        };
                    }));
                }, []),
            },
        },
        {
            label: 'Serial',
            field_key: 'serial',
            key: 'serial',
            field_type: 'text',
        },
        {
            label: 'Install Date',
            field_key: 'install_date',
            key: 'install_date',
            field_type: 'date',
        },
    ].map(field => ({
        ...field,
        option_group_label: 'General',
        condition_form_section_id: form_section_id,
        equipment_type_id: equipment_type.equipment_type_id,
        ...merge
    }));
}

/**
 *
 * @param {EquipmentType} equipment_type
 * @param {number} form_section_id
 * @param {?object} merge
 * @returns {EquipmentCustomField[]}
 */
export function makeEquipmentCustomFields(equipment_type, form_section_id, merge = {}) {
    return equipment_type.fields.map((field) => {
        return {
            ...field,
            field_key: 'custom_field',
            option_group_label: 'Custom',
            condition_form_section_id: form_section_id,
            equipment_type_id: equipment_type.equipment_type_id,
            ...merge
        };
    })
}
