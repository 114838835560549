// Various functions to assist type checking.

/**
 * Check if an item is an object.
 */
export function isObject(item) {
    return !isNull(item) && item.constructor === Object
}

/**
 * Check if an item is a string.
 */
export function isString(item) {
    return !isNull(item) && item.constructor === String;
}

/**
 * Check if an item is an array.
 */
export function isArray(item) {
    return !isNull(item) && item.constructor === Array;
}

/**
 * Check if an item is a number.
 */
export function isNumber(item) {
    return !isNull(item) && item.constructor === Number;
}

/**
 * Checks if data is formdata
 *
 * @param data
 * @returns {boolean}
 */
export function isFormData(data) {
    return (typeof FormData !== 'undefined') && (data instanceof FormData);
}

/**
 * Check if an item is undefined
 *
 * @param item
 * @returns {boolean}
 */
export function isUndefined(item) {
    return typeof item === 'undefined';
}

/**
 * Checks if item is null. Strict
 *
 * @param item
 * @returns {boolean}
 */
export function isNull(item) {
    return item === null;
}

/**
 * Check if date is valid
 *
 * @param date
 * @returns {boolean}
 */
export function isValidDate(date) {
    if ( date === null ) {
        return false;
    }

    if ( !(date instanceof Date)) {
        date = new Date(date);
    }

    return !isNaN(date.getDate());
}

/**
 * Checks if a key in an object exists and if it does check that the key has data
 *
 * @param item
 * @param key
 * @returns {*}
 */
export function existsAndHasData(item, key) {
    if ( !isObject(item) ) {
        return false;
    }

    if ( item.hasOwnProperty(key) ) {
        const value = item[key];

        if ( isUndefined(value) ) {
            return false;
        } else if ( isObject(value) ) {
            return Object.keys(value).length > 0;
        } else if ( isArray(value) ) {
            return value.length > 0;
        } else if ( isString(value) ) {
            return value !== '';
        } else {
            return !isUndefined(value);
        }
    }

    return false;
}

/**
 * @param {File} file
 * @returns {boolean}
 */
export function isImageOrPdf(file) {
    return [
        'application/pdf',
        'image/gif',
        'image/jpeg',
        'image/pjpeg',
        'image/png',
    ].includes(file?.type);
}
