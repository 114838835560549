<template>
    <vault-icon :icon="icon" :style="{color: hex}"></vault-icon>
</template>

<script>
    // External Dependencies
    import {faChevronDown} from '@fortawesome/pro-regular-svg-icons/faChevronDown';

    // Internal Dependencies
    import BaseIcon from './BaseIcon';

    export default BaseIcon.extend({
        name: 'IconChevronDown',

        data() {
            return {
                icon: faChevronDown,
            }
        }
    })
</script>
