<template>
    <div>
        <transition name="slide-down">
            <div v-if="completed">
                <vault-icon :icon="checkIcon"></vault-icon> Registration completed!
                <p>
                    You will be redirected to login shortly...
                </p>
                <a href="/app/dashboard" class="button">Login Now</a>
            </div>
            <form v-else="!completed">
                <div class="row">
                    <div class="col tab-full">
                        <form-group label="First Name" :errors="errors" name="first_name">
                            <input type="text" v-model.trim="model.first_name">
                        </form-group>
                    </div>
                    <div class="col tab-full">
                        <form-group label="Last Name" :errors="errors" name="last_name">
                            <input type="text" v-model.trim="model.last_name">
                        </form-group>
                    </div>
                    <div class="col tab-full">
                        <form-group label="Email" :errors="errors" name="email">
                            <input type="text" v-model.trim="model.email">
                        </form-group>
                    </div>
                    <div class="col tab-full">
                        <form-group label="Password" :errors="errors" name="password">
                            <input type="password" v-model.trim="model.password">
                        </form-group>
                    </div>
                    <div class="col tab-full">
                        <form-group label="Confirm Password" :errors="errors" name="password_confirmation">
                            <input type="password" v-model.trim="model.password_confirmation">
                        </form-group>
                    </div>
                    <div class="col tab-full">
                        <div class="form-group">
                            <button class="button" @click.prevent="register()">Create Account</button>
                        </div>
                    </div>
                </div>
            </form>
        </transition>
    </div>
</template>

<script>
    // External Dependencies
    import api from 'kickstart-ui/lib/api';
    import {faCheckCircle} from '@fortawesome/pro-solid-svg-icons/faCheckCircle';

    export default {
        name: 'UserRegistration',

        props: ['invite', 'hash'],

        data() {
            return {
                completed: false,
                model: {
                    email: '',
                    first_name: '',
                    last_name: '',
                    username: '',
                    password: '',
                    password_confirmation: '',
                    ...this.invite
                },
                errors: null,
                checkIcon: faCheckCircle,
            }
        },

        methods: {
            register() {
                this.model.username = this.model.email;

                const data = Object.entries(this.model).reduce((fd, [key, value]) => {
                    if ( value ) {
                        fd.append(key, value);
                    }

                    return fd;
                }, new FormData());

                api.post(`/invites/${this.hash}/register`, data)
                    .then((response) => {
                        this.completed = true;
                        setTimeout(() => {
                            window.location.href = !this.invite.customer_id ? '/app/dashboard' : '/customer/dashboard';
                        }, 5000)
                    })
                    .catch(({response}) => {
                        if ( response && response.data && response.data.errors ) {
                            this.errors = response.data.errors;
                        } else {
                            this.$toast({
                                type: 'error',
                                message: 'Could not save registration'
                            });
                        }
                    })
            },

        }
    }
</script>