/**
 * Set import templates to the store.
 *
 * @param commit
 * @param {array} templates
 */
export const setImportTemplates = ({commit}, templates) => {
    commit('SET_IMPORT_TEMPLATES', templates);
};

/**
 * Set the current import template.
 *
 * @param commit
 * @param {object} template
 */
export const setImportTemplate = ({commit}, template) => {
    commit('SET_IMPORT_TEMPLATE', template);
}

/**
 * Remove an import template from the store list by its id.
 * @param commit
 * @param {number} import_template_id
 */
export const removeImportTemplate = ({commit}, import_template_id) => {
    commit('REMOVE_IMPORT_TEMPLATE', import_template_id);
};

/**
 * Remove the current import template.
 */
export const removeCurrentImportTemplate = ({commit}) => {
    commit('REMOVE_CURRENT_IMPORT_TEMPLATE');
}

/**
 * Place a blank import template structure in the store.
 *
 */
export const setBlankImportTemplate = ({commit}) => {
    commit('SET_BLANK_IMPORT_TEMPLATE');
};

/**
 * Set import types to the store.
 */
export const setImportTypes = ({commit}, types) => {
    commit('SET_IMPORT_TYPES', types);
};

/**
 * Set file import formats
 * @param commit
 * @param {array} formats        [description]
 */
export const setImportFileFormats = ({commit}, formats) => {
    commit('SET_IMPORT_FILE_FORMATS', formats);
};

/**
 * Import Template Fields
 */
export const setImportTemplateField = ({commit}, {field, value}) => {
    commit('SET_IMPORT_TEMPLATE_FIELD', {field, value});
};

export const setImportTemplateFieldByID = ({commit}, {import_template_id, field, value}) => {
    commit('SET_IMPORT_TEMPLATE_FIELD_BY_ID', {import_template_id, field, value});
}

/**
 * Clear the import fields for the current template.
 */
export const clearImportFields = ({commit}) => {
    commit('CLEAR_FIELDS');
};

/**
 * Add a new field to the import template.
 * @param commit
 * @param {object} column
 */
export const addImportField = ({commit}, column) => {
    commit('ADD_FIELD', column);
};

/**
 *
 * @param commit
 * @param {number} import_type_field_id
 */
export const removeImportField = ({commit}, import_type_field_id) => {
    commit('REMOVE_IMPORT_FIELD', import_type_field_id);
}

/**
 *
 * @param commit
 * @param args
 */
export const setImportTemplateFieldProperty = ({commit}, args) => {
    commit('SET_IMPORT_TEMPLATE_FIELD_PROPERTY', args);
}

/**
 *
 * @param commit
 * @param {number} import_template_id
 * @param {number} pending_import
 */
export const addPendingImport = ({commit}, {import_template_id, pending_import}) => {
    commit('ADD_PENDING_IMPORT', {import_template_id, pending_import});
}

/**
 *
 * @param commit
 * @param {number} import_template_id
 * @param {number} import_pending_id
 */
export const removePendingImport = ({commit}, {import_template_id, import_pending_id}) => {
    commit('REMOVE_PENDING_IMPORT', {import_template_id, import_pending_id});
}

/**
 *
 * @param commit
 * @param {number} import_template_id
 */
export const clearPendingImports = ({commit}, import_template_id) => {
    commit('CLEAR_PENDING_IMPORTS', import_template_id)
}

/**
 *
 * @param commit
 * @param {number} import_history_id
 */
export const removeImportHistory = ({commit}, import_history_id) => {
    commit('REMOVE_IMPORT_HISTORY', import_history_id);
}
