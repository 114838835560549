import Vue from 'vue';
import _cloneDeep from 'lodash/cloneDeep';

const blank_ftp = /** @type {Ftp} */ {
    ftp_id: 0,
    host: '',
    port: 21,
    username: '',
    password: '',
    label: 'New FTP',
};

const default_ftp_configs = {
    invoices: {
        editing: false,
        event_name_long: 'upload invoices when published',
        event_name_short: 'upload published invoices',
        ftp: null,
        ftp_file_format: null,
    },
    visit_summaries: {
        editing: false,
        event_name_long: 'upload visit summaries when work is closed',
        event_name_short: 'upload completed visit summaries',
        ftp: null,
        ftp_file_name: '',
        show: {
            certificate: 1,
            forms: 1,
            items: 1,
            notes: 1,
            images: 1,
            documents: 1,
            diagram: 1,
        },
    },
};

const state = {
    blank_ftp: /** @type {Ftp} */ blank_ftp,
    ftps: /** @type {Ftp[]} */ [],

    ftp_configs: _cloneDeep(default_ftp_configs),
};

const matchFtpsToConfigs = (state) => {
    if ( !state.ftps || state.ftps.length === 0 ) {
        return;
    }

    Object.entries(state.ftp_configs)
        .forEach(([config_name, config]) => {
            let ftp_config = state.ftp_configs[config_name];
            ftp_config.ftp = state.ftps.find(({ftp_id}) => ftp_id === parseInt(config?.ftp_id)) || null;
            ftp_config.enabled = !!ftp_config.ftp;
            Vue.set(state.ftp_configs, config_name, ftp_config);
        });
};

const mutations = {
    SET_FTPS(state, ftps = []) {
        ftps.forEach(ftp => {
            ftp.ssl = !!ftp.ssl;
            ftp.passive = !!ftp.passive;
            ftp.sftp = !!ftp.sftp;
        });
        Vue.set(state, 'ftps', ftps);

        matchFtpsToConfigs(state);
    },

    RESET_FTP_CONFIGS(state) {
        Vue.set(state, 'ftp_configs', _cloneDeep(default_ftp_configs));
    },

    SET_FTP_CONFIGS(state, ftp_configs) {
        if ( ftp_configs.length === 0 ) {
            mutations.RESET_FTP_CONFIGS(state);
            return;
        }

        // set store config values from config_name->value format stored on server
        ftp_configs.forEach(({config_name, value}) => {
            switch ( config_name ) {
                case 'ftp.invoices':
                    Vue.set(state.ftp_configs.invoices, 'ftp_id', parseInt(value) || null);
                    break;

                case 'ftp.invoices.ftp_file_format':
                    Vue.set(state.ftp_configs.invoices, 'ftp_file_format', value);
                    break;

                case 'ftp.visit_summaries':
                    Vue.set(state.ftp_configs.visit_summaries, 'ftp_id', parseInt(value) || null);
                    break;

                case 'ftp.visit_summaries.show':
                    Vue.set(state.ftp_configs.visit_summaries, 'show', JSON.parse(value || null));
                    break;

                case 'ftp.visit_summaries.ftp_file_name':
                    Vue.set(state.ftp_configs.visit_summaries, 'ftp_file_name', value || null);
                    break;
            }
        });

        matchFtpsToConfigs(state);
    },

    SET_FTP_CONFIG_FTP(state, {ftp, config}) {
        Vue.set(state.ftp_configs[config], 'ftp', ftp);
    },
};

import * as getters from './getters';
import * as actions from './actions';

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
};
