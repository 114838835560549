// External Dependencies
import _findIndex from 'lodash/findIndex';
import Vue from 'vue';

const blank_service_item = {
    item_class: '',
    name: '',
    service_item_class_id: '',
    user_defined_number: '',
    description: '',
    is_taxable: false
};


/**
 * Get index of service class
 *
 * @param service_item_class_id
 * @returns {*}
 */
function indexOfClass(service_item_class_id) {
    return _findIndex(state.classes, (o) => {
        return o.service_item_class_id == service_item_class_id;
    });
}

/**
 * Get index of service item in classes items key
 *
 * @param class_index
 * @param service_item_id
 */
function indexOfItem(class_index, service_item_id) {
    return _findIndex(state.classes[class_index].items, (o) => {
        return o.service_item_id == service_item_id;
    });
}

/**
 * Reset state service_item to blank state
 */
function resetServiceItem() {
    state.service_item = Object.assign({}, blank_service_item);
}

const state = {
    classes: {
        service: [],
        product: [],
    },
    service_item: Object.assign({}, blank_service_item),
    categories: [],
    edit_category: {},
    del_category: {},
    projects: [],
    edit_project: {},
    statuses: [],
    edit_service: {},
    item_types: [
        { type: 'service', label: 'Services', has_classes: true, is_locked: false },
        { type: 'product', label: 'Products', has_classes: true, is_locked: false },
        { type: 'expense', label: 'Expenses', has_classes: false, is_locked: false },
        { type: 'labor', label: 'Labor', has_classes: false, is_locked: false },
        { type: 'combo', label: 'Combos', has_classes: false, is_locked: false },
        { type: 'cancellation_fee', label: 'Cancellation Fee', has_classes: false, is_locked: true },
        { type: 'minimum_site_charge', label: 'Minimum Site Charge', has_classes: false, is_locked: true },
        { type: 'sub_contractor_invoice', label: 'Sub Contractor Invoice', has_classes: false, is_locked: true },
    ],
    summary_options: [
        {
            label: 'Certificate',
            value: 'certificate',
        },
        {
            label: 'Forms',
            value: 'forms',
        },
        {
            label: 'Items',
            value: 'items',
        },
        {
            label: 'Notes',
            value: 'notes',
        },
        {
            label: 'Images',
            value: 'images',
        },
        {
            label: 'Documents',
            value: 'documents',
        },
        {
            label: 'Diagram',
            value: 'diagram',
        }
    ]
};

const mutations = {
    SET_CLASSES (state, {type, classes}) {
        Vue.set(state.classes, type, classes);
    },

    ADD_CLASS (state, new_class) {
        if ( state.classes[new_class.type] ) {
            state.classes[new_class.type].push(new_class);
        } else {
            Vue.set(state.classes, new_class.type, [new_class])
        }
    },

    UPDATE_CLASS (state, existing_class) {
        let index = state.classes[existing_class.type]
            .findIndex(({service_item_class_id}) => service_item_class_id === existing_class.service_item_class_id);

        if ( index !== -1 ) {
            state.classes[existing_class.type].splice(index, 1, existing_class);
        }
    },

    ADD_SERVICE_TO_CLASS (state, service) {
        let index = indexOfClass(service.service_item_class_id);

        if ( !state.classes[index].items ) {
            Vue.set(state.classes[index], 'items', []);
        }

        state.classes[index].items.push(service);
    },

    SET_SERVICE_ITEM (state, service_item) {
        state.service_item = service_item;
    },

    UPDATE_SERVICE_ITEM (state, service) {
        resetServiceItem();
    },

    DELETE_SERVICE_ITEM (state, {service_item_class_id, service_item_id}) {
        let index = indexOfClass(service_item_class_id);

        let service_index = indexOfItem(index, service_item_id);

        state.classes[index].items.splice(service_index, 1);
    },

    DELETE_CLASS (state, {type, service_item_class_id}) {
        let index = state.classes[type].findIndex(item => item.service_item_class_id === service_item_class_id);

        if ( index !== -1 ) {
            state.classes[type].splice(index, 1);
        }
    },

    EDIT_SERVICE (state, item) {
        Vue.set(state, 'edit_service', item)
    },

    CLEAR_EDIT_SERVICE (state) {
        state.edit_service = {};
    },

    SET_CATEGORIES (state, categories) {
        state.categories = categories;
    },

    ADD_CATEGORY (state, category) {
        state.categories.push(category);
    },

    EDIT_CATEGORY (state, category) {
        Vue.set(state, 'edit_category', category);
    },

    DEL_CATEGORY (state, category) {
        Vue.set(state, 'del_category', category);
    },

    CLEAR_EDIT_CATEGORY (state) {
        state.edit_category = {};
        state.del_category = {};
    },

    MOVE_CATEGORY (state, category) {
        /**
         * Remove the moved category from the old parent children array
         *
         * @param categories
         */
        function removeFromParent(categories) {
            for ( let cat of categories ) {
                if ( typeof cat.children !== 'undefined' && cat.children.length > 0 ) {
                    for ( let child of cat.children ) {
                        if (child.ticket_category_id === category.ticket_category_id && cat.ticket_category_id !== category.parent_ticket_category_id) {
                            let index = _findIndex(cat.children, (o) => o.ticket_category_id == category.ticket_category_id)

                            cat.children.splice(index, 1);
                            return;
                        }
                    }

                    removeFromParent(cat.children);
                }
            }
        }

        /**
         * Find the category new parent to add the children array
         *
         * @param categories
         */
        function findNewParent(categories) {
            for ( let cat of categories ) {
                if ( cat.ticket_category_id === category.parent_ticket_category_id ) {
                    cat.children.push(category);
                } else if ( cat.children.length > 0 ) {
                    findNewParent(cat.children);
                }
            }
        }

        findNewParent(state.categories);
        removeFromParent(state.categories);
    },

    SET_PROJECTS (state, projects) {
        state.projects = projects;
    },

    ADD_PROJECT (state, project) {
        state.projects.push(project);
    },

    EDIT_PROJECT (state, project) {
        Vue.set(state, 'edit_project', project);
    },

    UPDATE_PROJECT (state, project) {
        // TODO: update this if we next projects
        let index = _findIndex(state.projects, (o) => o.ticket_project_id === project.ticket_project_id);

        Vue.set(state.projects, index, project);
    },

    DEL_PROJECT (state, project) {
        let index = _findIndex(state.projects, (o) => o.ticket_project_id === project.ticket_project_id);

        state.projects.splice(index, 1);
    },

    CLEAR_EDIT_PROJECT (state) {
        state.edit_project = {};
    },

    SET_STATUSES (state, statuses) {
        state.statuses = statuses;
    },

    EDIT_SERVICE_PROPERTY(state, {property, value}) {
        Vue.set(state.edit_service, property, value);
    }
};

import * as getters from './getters';
import * as actions from './actions';

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
