import Vue from 'vue';

// Used when creating new templates.
const blank_export_template = {
    export_template_id: 0,
    export_template_name: '',
    export_file_format_id: 0,
    employee_only: 0,
    add_heading_row: 1,
    export_type_id: 0,
    entity_id: 0,

    // FTP Settings
    auto_run: 0,
    auto_run_type: 'F',
    export_time: null,
    next_export_date: null,
    export_frequency: null,
    export_all: 0,
    ftp_box_id: 0,
    attribute_set_code: 0,

    fields: [],
    pending: [],
};

const blank_export_template_field = {
    export_template_id: 0,
    export_index: 0,
    export_type_field_id: 0,
    attribute_id: 0,
};

const state = {
    // All export templates.
    export_templates: [],
    export_types: [],
    export_file_formats: [],
    export_template: {},
    blank_export_template: Object.assign({}, blank_export_template),
};

const mutations = {
    SET_EXPORT_TYPES(state, types) {
        state.export_types = types;
    },

    SET_EXPORT_FILE_FORMATS(state, formats) {
        state.export_file_formats = formats;
    },

    SET_EXPORT_TEMPLATES(state, templates) {
        state.export_templates = templates;
    },

    SET_EXPORT_TEMPLATE(state, template) {
        for (let existing_template_index in state.export_templates) {
            let existing_template = state.export_templates[existing_template_index];

            if (existing_template.export_template_id == template.export_template_id) {
                Vue.set(state.export_templates, existing_template_index, template);
                break;
            }
        }
    },

    SET_EXPORT_TEMPLATE_FIELD_BY_ID(state, {export_template_id, field, value}) {
        for (let i in state.export_templates) {
            let export_template = state.export_templates[i];

            if (export_template.export_template_id == export_template_id) {
                Vue.set(state.export_templates[i], field, value);
                break;
            }
        }
    },

    SET_BLANK_EXPORT_TEMPLATE(state) {
        state.export_template = Object.assign({}, blank_export_template);
    },

    DELETE_PENDING_EXPORT(state, export_pending) {
        let export_template_id = export_pending.export_template_id;

        for (let existing_template_index in state.export_templates) {
            let existing_template = state.export_templates[existing_template_index];

            if (existing_template.export_template_id == export_template_id) {
                for (let existing_pending_index in existing_template.pending) {
                    let existing_pending_export = existing_template.pending[existing_pending_index];

                    if (existing_pending_export.export_pending_id &&
                        existing_pending_export.export_pending_id == export_pending.export_pending_id) {

                        state.export_templates[existing_template_index].pending.splice(existing_pending_index, 1);
                        break;
                    }
                }

                break;
            }
        }
    },

};

import * as getters from './getters';
import * as actions from './actions';

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
