import Vue from 'vue';

const state = {
    task_categories: [],
    tasks: [],
    task_paginator: {},
    selected_task_category: {},
    selected_task_ids: [],
    select_all: false,
};

const mutations = {
    SET_TASK_CATEGORIES (state, categories) {
        Vue.set(state, 'task_categories', categories);
    },

    SET_TASKS (state, tasks) {
        Vue.set(state, 'tasks', tasks)
    },

    SET_TASKS_PAGINATOR (state, paginator) {
        Vue.set(state, 'task_paginator', paginator);
    },

    SET_TASK_CATEGORY (state, task_category_id) {
        const category = state.task_categories.find(category => category.task_category_id === task_category_id);

        state.selected_task_category = category || {};
    },

    ADD_SELECTED_TASK_ID (state, task_id) {
        if ( state.selected_task_ids.indexOf(task_id) === -1 ) {
            state.selected_task_ids.push(task_id);
        }
    },

    REMOVE_SELECTED_TASK_ID (state, task_id) {
        let index= state.selected_task_ids.indexOf(task_id);

        if ( index === -1 ) {
            return;
        }

        state.selected_task_ids.splice(index, 1);
    },

    RESET_SELECTED_TASK_IDS (state) {
        state.selected_task_ids = [];
    },

    SET_SELECT_ALL (state, select_all) {
        state.select_all = select_all;
    },
};

import * as getters from './getters';
import * as actions from './actions';

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}