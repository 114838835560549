import {isArray} from 'helpers/types'

/**
 *
 * @param arr1
 * @param arr2
 * @returns {boolean}
 */
export function arraysAreEqual(arr1, arr2) {
    if (!isArray(arr1) || !isArray(arr2)) {
        throw 'arraysAreEqual: Both operands must be arrays';
    }

    if ( arr1.length !== arr2.length ) {
        return false;
    }

    let arr1_copy = arr1.slice().sort();
    let arr2_copy = arr2.slice().sort();

    for (let i = 0; i < arr1_copy.length; ++i) {
        if (arr1_copy[i] !== arr2_copy[i]) {
            return false;
        }
    }

    return true;
}

/**
 * [1, 2, 3] => 1, 2 and 3
 *
 * @param arr
 * @returns {string|*}
 */
export function prettyJoin(arr) {
    if (!isArray(arr)) {
        throw 'prettyJoin: Argument must be an array.';
    }

    switch (arr.length) {
        case 0: return '';
        case 1: return arr[0];
        case 2: return arr[0] + ' and ' + arr[1];
        default:
            return arr.slice(0, arr.length - 1).join(', ') + ' and ' + arr[arr.length - 1];
    }
}

/**
 * @see https://stackoverflow.com/a/37580979/4468423
 * @param arr
 * @returns {*[]}
 */
export function permute(arr) {
    let length = arr.length,
        result = [arr.slice()],
        c = new Array(length).fill(0),
        i = 1, k, p;

    while ( i < length ) {
        if ( c[i] < i ) {
            k = i % 2 && c[i];
            p = arr[i];
            arr[i] = arr[k];
            arr[k] = p;
            ++c[i];
            i = 1;
            result.push(arr.slice());
        } else {
            c[i] = 0;
            ++i;
        }
    }
    return result;
}

/**
 * Returns value as an array if it is not already one.
 * @param {*|*[]} value
 * @returns {*[]}
 */
export function arrayWrap(value) {
    return Array.isArray(value) ? value : [value];
}
