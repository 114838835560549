/**
 *
 * @param state
 * @returns {[]}
 */
export function inventory(state) {
    return state.inventory.data;
}

/**
 *
 * @param state
 * @returns {{}}
 */
export function inventory_pagination(state) {
    return state.inventory.paginator;
}

/**
 *
 * @param state
 * @returns {[]}
 */
export function locations(state) {
    return state.locations.data;
}

/**
 *
 * @param state
 * @returns {string|null}
 */
export function reference_title(state) {
    if ( state.reference ) {
        if ( state.reference.location_id ) {
            return `#${state.reference.location_number} ${state.reference.city} ${state.reference.state}`;
        }

        if ( state.reference.fleet_id ) {
            return `#${state.reference.fleet_number} ${state.reference?.year || ''} ${state.reference.make?.make} ${state.reference.model?.model}`;
        }
    }

    return null;
}

/**
 *
 * @param state
 * @returns {{}}
 */
export function locations_pagination(state) {
    return state.locations.paginator;
}

/**
 *
 * @param state
 * @returns {{id: string, type: string}|{id: string, type: string}|RegExp|{}|escope.Reference|string|*}
 */
export function reference(state) {
    return state.reference;
}

/**
 *
 * @param state
 * @returns {null|{}}
 */
export function drag_data(state) {
    return state.drag_data;
}

/**
 *
 * @param state
 * @returns {*}
 */
export function item(state) {
    return state.item;
}

/**
 *
 * @param state
 * @returns {*}
 */
export function equipment(state) {
    return state.equipment;
}

/**
 *
 * @param state
 * @returns {null|*}
 */
export function inventory_location(state) {
    return state.inventory_location;
}

/**
 *
 * @param state
 * @returns {[]}
 */
export function parts(state) {
    return state.parts;
}

/**
 *
 * @param state
 * @returns {[]}
 */
export function partsWithQuantity(state) {
    return state.parts.filter(({quantity}) => quantity > 0)
}
