/**
 *
 * @param state
 * @return {state.price_profile|{price_profile_id, profile_name, sync_combos, modifiers}}
 */
export function price_profile(state) {
    return state.price_profile;
}

/**
 *
 * @param state
 * @return {*}
 */
export function blank_profile(state) {
    return Object.assign({}, state.blank_profile);
}

/**
 *
 * @param state
 * @return {*}
 */
export function price_items(state) {
    return state.price_items;
}

/**
 *
 * @param state
 * @return {state.class_modifiers|{}}
 */
export function class_modifiers(state) {
    return state.class_modifiers;
}

/**
 *
 * @param state
 * @returns {state.pagination|{}}
 */
export function pagination(state) {
    return state.pagination;
}

/**
 *
 * @param state
 * @returns {state.chart_account_segments|[]}
 */
export function chart_account_segments(state) {
    return state.chart_account_segments;
}

/**
 *
 * @param state
 * @returns {state.chart_accounts|[]}
 */
export function chart_accounts(state) {
    return state.chart_accounts;
}

/**
 *
 * @param state
 * @returns {state.blank_chart_account_segment}
 */
export function blank_chart_account_segment(state) {
    return {...state.blank_chart_account_segment};
}


/**
 *
 * @param state
 * @returns {state.blank_chart_account}
 */
export function blank_chart_account(state) {
    return {...state.blank_chart_account};
}