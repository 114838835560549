import _groupBy from 'lodash/groupBy';

export default class DeliveryAddressCollection {

    constructor(addresses) {
        this._addresses = addresses;
        this.addresses = _groupBy(addresses, ({type, group}) => type === 'phone' && group === 'fax' ? group : type);
    }

    /**
     *
     * @returns {*}
     */
    all() {
        return this._addresses.map(this.transform.bind(this));
    }

    /**
     *
     * @param address
     * @returns {{icon: string}}
     */
    transform(address) {
        return {
            ...address,
            action: this.typeAction(address.type, address.value),
            icon: this.typeIcon(address.type)
        }
    }

    /**
     *
     * @param type
     * @param value
     * @returns {string}
     */
    typeAction(type, value) {
        switch ( type ) {
            case 'email':
                return `mailto:${value}`;
            case 'phone':
                return `tel:${value}`;
            default:
                return '';
        }
    }

    /**
     *
     * @param type
     * @returns {string}
     */
    typeIcon(type) {
        switch ( type ) {
            case 'email':
                return 'icon-envelope';
            case 'phone':
                return 'icon-phone';
            default:
                return 'icon-' + type
        }
    }

    /**
     *
     * @returns {Array}
     */
    phoneNumbers() {
        return ('phone' in this.addresses) ? this.addresses.phone : [];
    }

    /**
     *
     * @returns {Array}
     */
    faxNumbers() {
        return ('fax' in this.addresses) ? this.addresses.fax : [];
    }

    /**
     *
     * @returns {Array}
     */
    emails() {
        return ('email' in this.addresses) ? this.addresses.email : [];
    }

    /**
     *
     * @returns {null}
     */
    firstPhoneNumber() {
        return this.phoneNumbers().length ? this.phoneNumbers()[0].value : null;
    }

    /**
     *
     * @returns {null}
     */
    firstEmail() {
        return this.emails().length ? this.emails()[0].value : null;
    }

    /**
     *
     * @returns {boolean}
     */
    empty() {
        return this._addresses.length == 0;
    }

    /**
     *
     * @returns {boolean}
     */
    notEmpty() {
        return !this.empty();
    }
}
