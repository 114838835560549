// External Dependencies
import Vue from 'vue';

// Internal Dependencies

export default {
    get(url = '', params = {}, config = {}) {
        return Vue.api.get(url, {params}, config);
    },

    post(url = '', request = {}, config = {}) {
        return Vue.api.post(url, request, config);
    },

    put(url = '', request = {}, config= {}) {
        return Vue.api.put(url, request, config);
    },

    patch(url = '', request = {}, config = {}) {
        return Vue.api.patch(url, request, config);
    },

    delete(url = '', request = {}) {
        return Vue.api.delete(url, request);
    }
};
