import _sortBy from 'lodash/sortBy';
import _cloneDeep from 'lodash/cloneDeep.js';

function sortItems(items) {
    return _sortBy(items, (item) => item.name.toLowerCase());
}

const blank_profile = {
    price_profile_id: 0,
    profile_name: '',
    cancellation_fee: '',
    sync_combos: 0,
    modifiers: [],
};

const blank_price_items = {
    product: [],
    service: [],
    combo: [],
    expense: [],
    labor: [],
}

const state = {
    price_profile: Object.assign({}, blank_profile),
    blank_profile: blank_profile,
    price_items: _cloneDeep(blank_price_items),
    class_modifiers: [],
    pagination: {},
    chart_account_segments: [],
    chart_accounts: [],
    blank_chart_account_segment: {
        chart_account_segment_id: null,
        name: '',
        description: '',
        position: '',
        length: '',
    },
    blank_chart_account: {
        chart_account_id: null,
        account_name: '',
        chart_account: '',
        type: 'revenue',
        default: false,
    }
};

const mutations = {
    SET_PROFILE (state, profile) {
        state.price_profile = profile;
        state.price_items = _cloneDeep(blank_price_items);
    },
    RESET_PROFILE (state) {
        state.price_profile = Object.assign({}, blank_profile);
    },
    SET_PROFILE_MODIFIERS(state, modifiers) {
        state.price_profile.modifiers = modifiers;
    },
    SET_PRICE_ITEMS (state, {type, items}) {
        state.price_items[type] = sortItems(items);
    },
    ADD_PRICE_ITEM (state, {type, item}) {
        state.price_items[type] = sortItems([...state.price_items[type], item]);
    },
    UPDATE_PRICE_ITEM (state, {type, item}) {
        let index = state.price_items[type].findIndex(({service_item_id}) => service_item_id == item.service_item_id);

        if ( index !== -1 ) {
            state.price_items[type].splice(index, 1, item);
            state.price_items[type] = sortItems(state.price_items[type]);
        }
    },
    SET_PRICE_CLASS_ITEMS (state, items) {
        state.class_modifiers = items;
    },
    DELETE_PRICE_ITEM (state, item) {
        let type = item.type;

        let found_index = state.price_items[type]
            .findIndex(({service_item_id}) => service_item_id === item.service_item_id);

        if ( found_index !== -1 ) {
            state.price_items[type].splice(found_index, 1);
        }
    },
    SET_PAGINATION (state, pagination) {
        state.pagination = pagination
    },
    SET_CHART_SEGMENTS (state, segments) {
        state.chart_account_segments = segments;
    },
    SET_CHART_ACCOUNTS (state, accounts) {
        state.chart_accounts = accounts;
    },
};

import * as getters from './getters';
import * as actions from './actions';

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
