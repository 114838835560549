import Repository from '../Repository';

export class CountryRepository extends Repository {
    constructor() {
        super();
        this.resource = 'countries';
    }
}

const Countries = new CountryRepository();

export default Countries;
