import Vue from 'vue';
import _cloneDeep from 'lodash/cloneDeep';
import {formatDate} from 'kickstart-ui/lib/dates';
import deepSet from 'helpers/deepSet';

/**
 * @returns {*}
 */
function setDefaultQuoteDate() {
    return formatDate(new Date);
}

const blank_quote = {
    quote_date: setDefaultQuoteDate(),
    description: '',
    items: [],
};

const state = {
    quote: _cloneDeep(blank_quote),
};

const mutations = {
    SET_QUOTE (state, quote) {
        Vue.set(state, 'quote', quote);
    },

    CLEAR_QUOTE (state) {
        Vue.set(state, 'quote', _cloneDeep(blank_quote));
    },

    SET_QUOTE_DATA (state, {where, value}) {
        Vue.set(state.quote, where, value);
    },

    SET_QUOTE_ITEMS (state, items) {
        if ( Array.isArray(items) ) {
            for ( let i in items ) {
                const item = state.quote.items.find((o) => o.service_item_id == items[i].service_item_id);

                if ( !!item ) {
                    if ( 'modifiers' in item ) {
                        delete item.modifiers;
                    }

                    items[i] = Object.assign({}, items[i], item);
                }
            }
        }

        Vue.set(state.quote, 'items', items);
    },

    ADD_QUOTE_ITEM (state, item) {
        if ( !state.quote.items ) {
            Vue.set(state.quote, 'items', []);
        }

        state.quote.items.push(item);
    },

    UPDATE_QUOTE_ITEM_DATA (state, {value, property, item}) {
        const index = state.quote.items.indexOf(item);

        if ( index !== -1 ) {
            const quote_item = state.quote.items[index];
            const original_amount = parseFloat(item.total_price);

            if ( property.indexOf('.') === -1 ) {
                Vue.set(quote_item, property, value);
            } else {
                deepSet(quote_item, property, value);
            }

            let total_price = parseFloat(item.quantity * item.unit_price);

            if ( item.tax_rate ) {
                total_price += item.tax_rate;
            }

            Vue.set(quote_item, 'total_price', total_price);

            Vue.set(state.quote, 'quote_total', ((state.quote.quote_total || 0 - original_amount || 0) + total_price || 0));
        } else {
            console.error('Could not find item prop ', item, property);
        }
    },

    ADD_ERRORS_TO_QUOTE_ITEM (state, {errors, item}) {
        const index = state.quote.items.indexOf(item);

        if ( index !== -1 ) {
            Vue.set(state.quote.items[index], 'errors', errors);
        }
    },

    CLEAR_QUOTE_ITEM_ERRORS (state, item) {
        const index = state.quote.items.indexOf(item);

        if ( index !== -1 ) {
            Vue.delete(state.quote.items[index], 'errors');
        }
    },

    DELETE_QUOTE_ITEM (state, item) {
        const index = state.quote.items.indexOf(item);

        if ( index !== -1 ) {
            state.quote.items.splice(index, 1);
        }

        state.quote.quote_total -= parseFloat(item.total_price || 0);
    },

    REFRESH_TOTAL (state) {
        let total = 0;

        for ( let i in state.quote.items ) {
            total += parseFloat(state.quote.items[i].total_price);
        }

        Vue.set(state.quote, 'quote_total', parseFloat(total).toFixed(2));
    },
};

import * as getters from './getters';
import * as actions from './actions';

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
