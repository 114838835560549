import EventHandler from 'helpers/EventHandler';

/**
 * Set work order customer
 *
 * @param commit
 * @param customer
 */
export function setCustomer({commit}, customer) {
    commit('SET_CUSTOMER', customer);
}

/**
 * Set work order billing customer
 *
 * @param commit
 * @param customer
 */
export function setBillingCustomer({commit}, customer) {
    commit('SET_BILLING_CUSTOMER', customer);
}

/**
 * Set work order location
 *
 * @param commit
 * @param location
 */
export function setLocation({commit}, location) {
    commit('SET_LOCATION', location);
}

/**
 *
 * @param commit
 * @param diagram
 */
export function setLocationDiagram({commit}, diagram) {
    commit('SET_LOCATION_DIAGRAM', diagram);
}

/**
 *
 */
export function clearLocationDiagram({commit}) {
    commit('SET_LOCATION_DIAGRAM', null)
}

/**
 * Set an attribute on the work order object
 *
 * @param commit
 * @param where
 * @param value
 * @param push
 */
export function setWorkOrderAttribute({commit}, {where, value, push}) {
    commit('SET_WORK_ORDER_ATTRIBUTE', {where, value, push});
}

/**
 * Update a service item
 *
 * @param commit
 * @param where
 * @param value
 * @param item
 */
export function updateWorkOrderServiceItem({commit}, {where, value, item}) {
    commit('UPDATE_WORK_ORDER_SERVICE_ITEM', {where, value, item});
}

/**
 * Set existing work order
 *
 * @param commit
 * @param work_order
 */
export function setWorkOrder({commit}, work_order) {
    commit('SET_WORK_ORDER', work_order);
}

/**
 *
 * @param commit
 * @param items
 */
export function setVisitItems({commit}, items) {
    commit('SET_VISIT_ITEMS', items);
}

/**
 * Reset the state of work order to blank
 *
 * @param commit
 */
export function resetWorkOrder({commit}) {
    commit('RESET_WORK_ORDER');
}

/**
 *
 * @param commit
 * @param monitor
 */
export function removeMonitor({commit}, monitor) {
    commit('REMOVE_MONITOR', monitor);
}

/**
 *
 * @param commit
 * @param edoc_file_id
 */
export function removeFile({commit}, edoc_file_id) {
    commit('REMOVE_FILE', edoc_file_id);
}

/**
 *
 * @param commit
 * @param item
 */
export function removeServiceItem({commit}, item) {
    commit('REMOVE_SERVICE_ITEM', item);
}

/**
 *
 * @param commit
 * @param errors
 */
export function setWorkOrderErrors({commit}, errors) {
    commit('SET_ERRORS', errors);
}

/**
 *
 * @param commit
 */
export function clearWorkOrderErrors({commit}) {
    commit('CLEAR_ERRORS');
}

/**
 *
 * @param commit
 * @param notes
 */
export function setNotes({commit}, notes) {
    commit('SET_NOTES', notes);
}

/**
 * clears notes
 *
 * @param commit
 */
export function clearNotes({commit}) {
    commit('CLEAR_NOTES');
}

/**
 * Add note to notes state
 *
 * @param commit
 * @param note
 */
export function addNote({commit}, note) {
    commit('ADD_NOTE', note)
}

/**
 * Set work schedule to store
 *
 * @param commit
 * @param schedule
 */
export function setWorkSchedule({commit}, schedule) {
    commit('SET_WORK_SCHEDULE', schedule);
}

/**
 * Set work schedule attribute
 *
 * @param commit
 * @param where
 * @param value
 * @param push
 */
export function setWorkScheduleAttribute({commit}, {where, value}) {
    commit('SET_WORK_SCHEDULE_ATTRIBUTE', {where, value});
}

/**
 * Resets work schedule back to blank
 *
 * @param commit
 */
export function resetWorkSchedule({commit}) {
    commit('RESET_WORK_SCHEDULE');
}

/**
 *
 * @param commit
 * @param status
 */
export function setGeneratingWorkVisits({commit}, status) {
    commit('SET_GENERATING_WORK_VISITS', status);
}

/**
 *
 * @param commit
 * @param state
 * @param work_id
 */
export function createGeneratingVisitsHandler({commit, state}, work_id) {
    if ( !work_id ) {
        return;
    }

    let progress_event = `work:${work_id}:visit-progress`;

    state.generating_visits_handler = EventHandler.channel('user')
        .listen(progress_event, ({progress}) => {
            let progress_value = parseFloat(progress);

            commit('SET_GENERATE_VISITS_PROGRESS', progress_value);
        });
}

/**
 *
 * @param commit
 * @param state
 * @param work_id
 */
export function removeGeneratingVisitsHandler({commit, state}, work_id) {
    state.generating_visits_handler = null;
}

/**
 *
 * @param commit
 * @param progress
 */
export function setGenerateVisitsProgress({commit}, progress) {
    commit('SET_GENERATE_VISITS_PROGRESS', progress);
}

/**
 *--------------------------------------------------
 * Bulk Workorder Actions
 *--------------------------------------------------
 */

/**
 *
 * @param commit
 * @param key
 * @param ids
 */
export function addSelectedWorkOrder({commit}, {key, ids}) {
    commit('ADD_SELECTED_WORKORDER_ID', {key, ids});
}

/**
 *
 * @param commit
 * @param key
 * @param ids
 */
export function removeSelectedWorkOrder({commit}, {key, ids}) {
    commit('REMOVE_SELECTED_WORKORDER_ID', {key, ids});
}

/**
 *
 * @param commit
 * @param key
 */
export function clearSelectedWorkOrders({commit}, key) {
    commit('CLEAR_SELECTED_WORKORDERS', key);
}

/**
 *
 * @param commit
 * @param contact
 */
export function setContact({commit}, contact) {
    commit('SET_CONTACT', contact);
}

/**
 *
 * @param commit
 * @param projects
 */
export function setProjects({commit}, projects) {
    commit('SET_RESOURCE', {resource: 'projects', value: projects});
}

/**
 *
 * @param commit
 * @param categories
 */
export function setCategories({commit}, categories) {
    commit('SET_RESOURCE', {resource: 'categories', value: categories});
}

/**
 *
 * @param commit
 * @param statuses
 */
export function setStatuses({commit}, statuses) {
    commit('SET_RESOURCE', {resource: 'statuses', value: statuses});
}

/**
 * -------------------------
 * Schedule Filter Actions
 * -------------------------
 */

/**
 *
 * @param commit
 * @param filter
 * @param value
 */
export function setScheduleFilter({commit}, {filter, value}) {
    commit('SET_SCHEDULE_FILTER', {filter, value});
}

/**
 *
 * @param commit
 */
export function clearScheduleFilters({commit}) {
    commit('CLEAR_SCHEDULE_FILTERS');
}

/**
 *
 * @param commit
 * @param key
 */
export function clearScheduleFilter({commit}, key) {
    commit('CLEAR_SCHEDULE_FILTER', key);
}

/**
 *
 * @param commit
 * @param date
 */
export function setScheduleDate({commit}, date) {
    commit('SET_SCHEDULE_DATE', date);
}

/**
 *
 * @param commit
 * @param data
 */
export function setDragData({commit}, data) {
    commit('SET_DRAG_DATA', data);
}

/**
 *
 * @param commit
 * @param data
 */
export function setWorkInvoices({commit}, data) {
    commit('SET_WORK_INVOICES', data);
}

/**
 *
 * @param commit
 * @param view
 */
export function setGridView({commit}, view) {
    commit('SET_GRID_VIEW', view);
}

/**
 *
 * @param commit
 * @param data
 */
export function addBulkVisit({commit}, data) {
    commit('ADD_BULK_SCHEDULE_VISIT', data);
}

/**
 *
 * @param commit
 * @param data
 */
export function removeBulkVisit({commit}, data) {
    commit('REMOVE_BULK_SCHEDULE_VISIT', data);
}

/**
 *
 */
export function clearBulkVisits({commit}) {
    commit('CLEAR_BULK_SCHEDULE_VISITS');
}


/**
 *
 * @param commit
 * @param items
 */
export function setAvailableItems({commit}, items) {
    commit('SET_AVAILABLE_ITEMS', items);
}

/**
 *
 * @param commit
 * @param work_id
 */
export function addToSelections({commit}, work_id) {
    commit('ADD_TO_SELECTIONS', work_id);
}

/**
 *
 * @param commit
 * @param work_ids
 */
export function selectAll({commit}) {
    commit('SELECT_ALL');
}

/**
 *
 * @param commit
 * @param work_id
 */
export function removeFromSelections({commit}, work_id) {
    commit('REMOVE_FROM_SELECTIONS', work_id);
}

/**
 *
 * @param commit
 */
export function resetSelections({commit}) {
    commit('RESET_SELECTIONS');
}

/**
 *
 */
export function setScheduleListVisits({commit}, {date, visits}) {
    commit('CLEAR_SCHEDULE_LIST_VISITS', date);
    commit('ADD_SCHEDULE_LIST_VISITS', {date, visits});
}

/**
 *
 */
export function clearSelectedScheduleListVisits({commit}, date = null) {
    commit('SELECT_ALL_SCHEDULE_LIST_VISITS', {
        date,
        check: false,
    });
}

/**
 *
 */
export function addScheduleListVisits({commit}, {date, visits}) {
    commit('ADD_SCHEDULE_LIST_VISITS', {date, visits});
}

/**
 *
 */
export function selectScheduleListVisit({commit}, {date, work_visit_number, check}) {
    commit('SELECT_SCHEDULE_LIST_VISIT', {date, work_visit_number, check});
}

/**
 *
 */
export function selectAllScheduleListVisits({commit}, {date, check}) {
    commit('SELECT_ALL_SCHEDULE_LIST_VISITS', {date, check});
}

/**
 * @param commit
 * @param {Object} data
 */
export function setScheduleLoading({commit}, data) {
    commit('SET_SCHEDULE_LOADING', data);
}

/**
 *
 * @param commit
 * @param {Object} data
 */
export function setScheduleAssignedList({commit}, data) {
    commit('SET_SCHEDULE_ASSIGNED_LIST', data);
}

/**
 * @param commit
 * @param schedule_list_key
 * @param group_id
 * @param collapsed
 */
export function setGroupCollapse({commit}, {schedule_list_key, group_id, collapsed}) {
    commit('SET_GROUP_COLLAPSE', {schedule_list_key, group_id, collapsed});
}