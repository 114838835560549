/**
 *
 * @param commit
 * @param {Certification[]} certifications
 */
export function setCertifications({commit}, certifications) {
    // TODO: after upgrading to Laravel ^8.42 we can remove the map
    commit('SET_CERTIFICATIONS', certifications.map(certification => {
        // get just the last file uploaded
        if ( !!certification.files ) {
            return {...certification, file: certification.files[certification.files.length - 1] || null };
        }
        delete certification.files;

        return certification;
    }));
}

/**
 *
 * @param commit
 * @param {CertificationType[]} certification_types
 */
export function setCertificationTypes({commit}, certification_types) {
    commit('SET_CERTIFICATION_TYPES', certification_types);
}

/**
 *
 * @param commit
 * @param {{}} paginator
 */
export function setTypesPaginator({commit}, paginator) {
    commit('SET_TYPES_PAGINATOR', paginator);
}
