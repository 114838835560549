<template>
    <vault-icon :icon="icon" :style="{color: hex}"></vault-icon>
</template>

<script>
    // External Dependencies
    import {faSyncAlt} from '@fortawesome/pro-regular-svg-icons/faSyncAlt';

    // Internal Dependencies
    import BaseIcon from './BaseIcon';

    export default BaseIcon.extend({
        name: 'IconRefresh',

        data() {
            return {
                icon: faSyncAlt,
            }
        }
    })
</script>