import Pusher from 'pusher-js';

// Verbose output in debug mode.
Pusher.logToConsole = import.meta.env.VITE_APP_DEBUG === "true";

const STATE_OK = 0;
const STATE_INITIALIZING = 1;
const STATE_UNAVAILABLE = 2;

class EventChannel {
    /**
     * @param  Pusher pusher  [description]
     * @param  String channel [description]
     */
    constructor(pusher, channel) {
        this.channel = pusher.subscribe(channel);
    }

    /**
     * Listen for an event on this channel.
     *
     * @param  String   event    [description]
     * @param  callable callback [description]
     */
    listen(event, callback) {
        let ret = this.channel.bind(event, callback);

        return this;
    }

    unlisten(event) {
        this.channel.unbind(event);

        return this;
    }
}

class EventChannelMananger {
    constructor(pusher) {
        this.pusher = pusher;
        this.channels = {};
    }

    add(key, channel) {
        if (!this.channels.hasOwnProperty(key)) {
            this.channels[key] = new EventChannel(this.pusher, channel);
        }
    }

    remove(key) {
        if (this.channels.hasOwnProperty(key)) {
            delete this.channels[key];
        }

        this.pusher.unsubscribe(key);
    }

    get(key) {
        if (!this.channels.hasOwnProperty(key)) {
            throw "Channel not defined";
        }

        return this.channels[key];
    }
}

class EventHandler {
    constructor(key, cluster) {
        this.pusher = new Pusher(key, {
            cluster: cluster,
            encrypted: true
        });

        // Async state change binds
        this.pusher.connection.bind("connected", () => {
            console.log("Pusher connection established")
        }).bind("unavailable", () => {
            console.error("Pusher connection unavailable");
        });

        this.channel_manager = new EventChannelMananger(this.pusher);
    }

    /**
     * Return a channel object, throws if the key doesn't exist.
     * @param  string key [description]
     *
     * @return EventChannel
     */
    channel(key) {
        return this.channel_manager.get(key);
    }

    /**
     * Add a channel.
     *
     * @param string key
     * @param string channel
     */
    addChannel(key, channel) {
        this.channel_manager.add(key, channel);

        return this;
    }

    /**
     * Remove a channel.
     *
     * @param  string key
     */
    removeChannel(key) {
        this.channel_manager.remove(key);

        return this;
    }
}

const handler = new EventHandler(
    import.meta.env.VITE_PUSHER_APP_KEY,
    import.meta.env.VITE_PUSHER_APP_CLUSTER
);

export default handler;
