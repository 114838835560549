import Vue from 'vue';

/**
 * Set entity id to vuex store
 *
 * @param commit
 * @param entity_id
 */
export const setEntityId = ({commit}, entity_id) => {
    commit('SET_ENTITY_ID', entity_id);
};

/**
 * Set entity object
 *
 * @param commit
 * @param entity
 */
export const setEntity = ({commit}, entity) => {
    commit('SET_ENTITY', entity);
};

/**
 * Set user id to vuex store
 *
 * @param commit
 * @param user_id
 */
export const setUserId = ({commit}, user_id) => {
    commit('SET_USER_ID', user_id);
};

/**
 * Set user role id
 *
 * @param commit
 * @param user_role_id
 */
export const setUserRoleId = ({commit}, user_role_id) => {
    commit('SET_USER_ROLE_ID', user_role_id);
}

/**
 *
 * @param commit
 * @param customer_id
 */
export const setUserCustomerId = ({commit}, customer_id) => {
    commit('SET_USER_CUSTOMER_ID', customer_id);
}

/**
 * Set username to vuex store
 *
 * @param commit
 * @param username
 */
export const setUsername = ({commit}, username) => {
    commit('SET_USERNAME', username);
};

/**
 *
 * @param commit
 * @param user_id
 * @param username
 * @param user_type
 * @param user_display
 * @param user_role_id
 */
export const setUserInfo = ({commit}, {user_id, username, user_type, user_display, user_role_id}) => {
    commit('SET_USER_INFO', {user_id, username, user_type, user_display, user_role_id});
}

/**
 *
 * @param commit
 * @param user_type
 */
export const setUserType = ({commit}, user_type) => {
    commit('SET_USER_TYPE', user_type);
}

/**
 * Sets if a user is a system admin
 *
 * @param commit
 * @param system_admin
 */
export const setSystemAdmin = ({commit}, system_admin) => {
    commit('SET_SYSTEM_ADMIN', system_admin);
}

/**
 *
 * @param commit
 * @param is_impersonating
 */
export const setIsImpersonating = ({commit}, is_impersonating) => {
    commit('SET_IS_IMPERSONATING', is_impersonating);
}

/**
 * Resets the breadcrumb to the passed value
 *
 * @param commit
 * @param breadcrumb
 */
export const resetBreadcrumb = ({commit}, breadcrumb) => {
    commit('RESET_BREADCRUMB', breadcrumb);
}

/**
 * Pushes a new breadcrumb to the stack
 *
 * @param commit
 * @param breadcrumb
 */
export const pushBreadcrumb = ({commit}, breadcrumb) => {
    commit('PUSH_BREADCRUMB', breadcrumb);
}

/**
 *
 * @param commit
 */
export const popBreadcrumb = ({commit}) => {
    commit('POP_BREADCRUMB');
}

/**
 *
 * @param commit
 * @param breadcrumb
 */
export const editBreadcrumb = ({commit}, breadcrumb) => {
    commit('EDIT_BREADCRUMB', breadcrumb);
}

/**
 *
 * @param commit
 * @param state
 */
export const refreshPermissions = ({commit, state}) => {
    Vue.api.get(`/entities/${state.entity_id}/users/${state.user_id}/permissions`).then(({data}) => {
        commit('SET_PERMISSIONS', data);
    }).catch(() => {
        // TODO ... figure out what to do when the permissions endpoint fails
        // TODO also need to decide a good time to refresh this data in case a users permissions are updated
        // TODO might be a good use for pusher
    })
}


/**
 * Set permissions on initial page load
 *
 * @param commit
 * @param perms
 */
export const setPermissions = ({commit}, perms) => {
    commit('SET_PERMISSIONS', perms);
};

/**
 *
 * @param commit
 * @param loaded
 */
export const setAppLoaded = ({commit}, loaded) => {
    commit('SET_APP_LOADED', loaded);
};

/**
 * Set user role to store
 *
 * @param commit
 * @param role
 */
export const setUserRole = ({commit}, role) => {
    commit('SET_USER_ROLE', role);
};

/**
 * Set export filter
 *
 * @param commit
 * @param filter
 */
export const setExportFilter = ({commit}, filter) => {
    commit('SET_EXPORT_FILTER', filter);
};

/**
 * Reset all export filter
 *
 * @param commit
 */
export const resetExportFilters = ({commit}) => {
    commit('RESET_EXPORT_FILTERS');
};

/**
 * Remove single filter
 *
 * @param commit
 * @param filter
 */
export const removeExportFilter = ({commit}, filter) => {
    commit('REMOVE_EXPORT_FILTER', filter);
};

/**
 * Set Integration Options
 * @param commit
 * @param integration_options
 */
export const setIntegrationOptions = ({commit}, integration_options) => {
    commit('SET_INTEGRATION_OPTIONS', integration_options);
};

/**
 * Set the app version
 * @param commit
 * @param integration_options
 */
export const setAppVersion = ({commit}, app_version) => {
    commit('SET_APP_VERSION', app_version);
};

/**
 * Enable the flag that signals the app needs a reload
 * @param commit
 * @param integration_options
 */
export const setRefreshRequired = ({commit}) => {
    commit('SET_REFRESH_REQUIRED');
};

/**
 *
 * @param commit
 * @param route_to
 */
export const setRouteTo = ({commit}, route_to) => {
    commit('SET_ROUTE_TO', route_to)
}

/**
 *
 * @param commit
 * @param tax_categories
 */
export const setTaxCategories = ({commit}, tax_categories) => {
    commit('SET_TAX_CATEGORIES', tax_categories);
}

export const setHasTaskCategories = ({commit}, has_task_categories) => {
    commit('SET_HAS_TASK_CATEGORIES', has_task_categories);
}

/**
 *
 * @param commit
 * @param {Mention[]} mentions
 * @returns {Promise}
 */
export const setMentions = ({commit}, mentions) => {
    commit('SET_MENTIONS', mentions);
}

/**
 *
 * @param commit
 * @param {Mention} mention
 */
export const updateMention = ({commit}, mention) => {
    commit('UPDATE_MENTION', mention);
}

/**
 *
 * @param commit
 * @param {Mention} mention
 */
export const deleteMention = ({commit}, mention) => {
    commit('DELETE_MENTION', mention);
}
