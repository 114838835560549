import Vue from 'vue';

const paginator = {
    total_count: 0,
    total_pages: 0,
    current_page: 1,
    limit: 50,
    next_page: null,
    previous_page: null
};

const state = {
    custom_processes: {
        paginator: {...paginator},
        data: []
    },
    custom_process: null,
    logs: {
        paginator: {...paginator},
        data: []
    }
};

const mutations = {
    SET_CUSTOM_PROCESSES(state, {data, paginator}) {
        Vue.set(state.custom_processes, 'paginator', paginator);
        Vue.set(state.custom_processes, 'data', data);
    },

    SET_CUSTOM_PROCESS_LOGS(state, {data, paginator}) {
        Vue.set(state.logs, 'paginator', paginator);
        Vue.set(state.logs, 'data', data);
    },

    SET_CUSTOM_PROCESS(state, custom_process) {
        Vue.set(state, 'custom_process', custom_process);
    },

    UPDATE_CUSTOM_PROCESS_FIELD(state, {custom_process, field, value}) {
        let index = state.custom_processes.data.findIndex((c) => c.custom_process_id == custom_process.custom_process_id);

        if ( !index ) {
            console.warn('Cant find index of custom process');
            return;
        }

        Vue.set(state.custom_processes.data[index], field, value);
    },

    RESET_CUSTOM_PROCESS(state) {
        Vue.set(state, 'custom_process', null);
    },
};

import * as getters from './getters';
import * as actions from './actions';

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
