import _groupBy from 'lodash/groupBy';
/**
 *
 * @param state
 * @returns {[]|{data: [], loading: boolean, paginator: {next_page: null, total_count: number, limit: number, total_pages: number, previous_page: null, current_page: number}}|*}
 */
export function webhooks(state) {
    return state.webhooks;
}

/**
 *
 * @param state
 * @returns {*}
 */
export function events(state) {
    return state.events;
}

/**
 *
 * @param state
 * @returns {*}
 */
export function groupedEvents(state) {
    return _groupBy(state.events, (event) => event.event.split('.')[0]);
}