import Vue from 'vue';
import {formatDate, formatTime} from 'kickstart-ui/lib/dates';
import { object_get } from 'kickstart-ui/lib/objects';
import { capitalize, snakeToTitle } from 'helpers/strings';

/**
 * Date format filter
 */
Vue.filter('date', (date, to_format = 'm/d/Y', from_format = 'Y-m-d') => {
    if (!date) {
        return date;
    }

    // Check for a time in the to_format. If so then lets format for time
    let toSplit = to_format.split(' ');
    if (toSplit.length > 1 && toSplit[1].length > 0) {
        to_format = formatTime(date.split(' ')[1], to_format)
    }

    return formatDate(date, to_format, from_format);
});

Vue.filter('time', (date, to_format = 'h:ia') => {
    if (!date) {
        return date;
    }

    return formatTime(date.split(' ')[1], to_format);
});

/**
 * Convert Snake Case to Title Case
 */
Vue.filter('snakeToTitle', snakeToTitle)

/**
 * Capitalize a string
 */
Vue.filter('capitalize', capitalize)

/**
 * Transforms a filter value into a query param.
 *
 * @param {object} value
 * @returns {string}
 */
export function filterToQueryParam(value) {
    // filter objects
    if ( value.value ) {
        return Object.entries(value.value)
            .map(([key, value]) => `${key}:${value}`)
            .join(';');

    // legacy filters
    } else {
        return [
            value.filter_key,
            value.filter_label,
            object_get(value, 'filter_name')
        ].join('||').replace(/\|+$/, '');
    }
}

/**
 * Transforms a query param into a filter value.
 *
 * @param {?string} param
 * @param {?string} key
 * @returns {object}
 */
export function queryParamToFilter(param, key = null) {
    if ( param === null ) {
        return null;
    }

    if ( param.includes(':') ) {
        // filter object
        return {
            value: param.split(';')
                .reduce((filter, subkey) => {
                    let [key, value] = subkey.split(':');
                    filter[key] = value;
                    return filter;
                }, {}),
        };
    }

    if ( param.includes('||') ) {
        // legacy filter
        let [filter_key, filter_label, filter_name] = param.split('||');
        return {filter_key, filter_label, filter_name};
    }

    if ( key !== null ) {
        return Object.fromEntries([[key, param]]);
    }

    return param;
}
