// External Dependencies
import Vue from 'vue';

const state = {
    active_marker: {},
    fly_to_location: {},
};

const mutations = {
    SET_ACTIVE_MARKER(state, marker) {
        Vue.set(state, 'active_marker', marker);
    },

    SET_FLY_TO(state, location) {
        Vue.set(state, 'fly_to_location', location);
    }
};

import * as getters from './getters';
import * as actions from './actions';

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}