/**
 * @param state
 * @returns {[]}
 */
export function task_categories(state) {
    return state.task_categories;
}

/**
 * ca@param state
 * @returns {number|*}
 */
export function active_task_category_id(state) {
    return state.selected_task_category?.task_category_id || 0;
}

/**
 * @param state
 * @returns {*|{}}
 */
export function active_task_category(state) {
    return state.selected_task_category;
}

/**
 * @param state
 * @returns {*[]|[]|*}
 */
export function tasks(state) {
    return state.tasks;
}

/**
 * @param state
 * @returns {{}}
 */
export function task_paginator(state) {
    return state.task_paginator;
}

/**
 * @param state
 * @return {*[]|[]|*}
 */
export function selected_task_ids(state) {
    return state.selected_task_ids;
}

/**
 * @param state
 * @returns {boolean|*}
 */
export function select_all(state) {
    return state.select_all;
}

/**
 * @param state
 * @returns {number}
 */
export function additional_task_count(state) {
    if ( !state.select_all ) {
        return 0;
    }

    if ( !state.selected_task_ids.length ) {
        return 0;
    }

    return state.task_paginator?.total_count > state.selected_task_ids.length
        ? (state.task_paginator?.total_count - state.selected_task_ids.length)
        : 0;

}
