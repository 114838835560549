<template>
    <vault-icon :icon="icon" :style="{color: hex}"></vault-icon>
</template>

<script>
    // External Dependencies
    import {faFileAlt} from '@fortawesome/pro-solid-svg-icons/faFileAlt';

    // Internal Dependencies
    import BaseIcon from './BaseIcon';

    export default BaseIcon.extend({
        name: 'IconText',

        data() {
            return {
                icon: faFileAlt,
            }
        }
    })
</script>
