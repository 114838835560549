import _debounce from 'lodash/debounce.js';

import Counties from '@Vault/Admin/Billing/repositories/CountiesRepository.js';
import Countries from '@Vault/Locations/CountryRepository.js';

/**
 *
 * @param commit
 * @param locations
 */
export function setLocations({commit}, locations) {
    commit('SET_LOCATIONS', locations);
}

/**
 *
 * @param commit
 * @param location
 */
export function setLocation({commit}, location) {
    commit('SET_LOCATION', location);
}

/**
 *
 * @param commit
 * @param relation
 * @param data
 */
export function setLocationRelationship({commit}, {relation, data}) {
    commit('SET_LOCATION_RELATIONSHIP', {relation, data});
}

/**
 *
 * @param commit
 * @param reference
 */
export function setReference({commit}, reference) {
    commit('SET_REFERENCE', reference);
}

/**
 *
 * @param commit
 * @param states
 */
export function setStates({commit}, states) {
    commit('SET_STATES', states);
}

/**
 *
 * @param commit
 * @param counties
 */
export function setCounties({commit}, counties) {
    commit('SET_COUNTIES', { counties });
}

/**
 *
 * @param commit
 * @param counties
 */
export function mergeCounties({commit}, counties) {
    commit('SET_COUNTIES', { counties, merge: true });
}

/**
 * Get county data for the given county_ids, loading counties as necessary.
 * @param commit
 * @param {?[]} county_ids
 * @returns {Promise<County[]|[]>}
 */
export function loadCountyData({commit}, county_ids = null) {
    county_ids ??= [];
    if ( county_ids.length === 0 ) {
        return Promise.resolve([]);
    }

    // determine which counties are not yet loaded
    const loaded_county_ids = this.getters['locations/countyIds'];
    const not_loaded_county_ids = county_ids.filter(x => !loaded_county_ids.has(x));

    // get the counties already loaded
    const loaded_counties = (this.getters['locations/counties'] || [])
        .filter(county => county_ids.includes(county.county_id));

    // if all requested counties are already loaded, return them
    if ( not_loaded_county_ids.length === 0 ) {
        return Promise.resolve(loaded_counties);
    }

    // fetch the remaining counties, merge them into the store, and return the combined list
    return Counties.get('', { county_ids: not_loaded_county_ids.join(',') })
        .then(({data}) => {
            commit('SET_COUNTIES', { counties: data, merge: true });
            return loaded_counties.concat(data);
        });
}

/**
 *
 * @param commit
 */
export const loadAllCountriesWithStates = _debounce(({commit}) => {
    Countries.list({ with: 'states' })
        .then(({data}) => {
            commit('SET_COUNTRIES', data);
            commit('SET_CURRENT_COUNTRY_STATES');

            let states = [];

            for ( let country of data ) {
                if ( !country.states ) {
                    return states;
                }

                for ( let state of country.states ) {
                    state['country_name'] = country.country_name;
                    states.push(state);
                }
            }

            commit('SET_ALL_STATES', states);
        });
}, 100);

/**
 *
 * @param commit
 * @param states
 */
export function setAllStates({commit}, states) {
    commit('SET_ALL_STATES', states);
}

/**
 *
 * @param commit
 * @param countries
 */
export function setCountries({commit}, countries) {
    commit('SET_COUNTRIES', countries);
}

/**
 *
 * @param commit
 * @param country_id
 */
export function setCountryId({commit}, country_id) {
    commit('SET_COUNTRY_ID', parseInt(country_id));
}

/**
 *
 * @param commit
 */
export function setCurrentCountryStates({commit}) {
    commit('SET_CURRENT_COUNTRY_STATES');
}

/**
 *
 * @param commit
 * @param state_id
 */
export function setStateId({commit}, state_id) {
    commit('SET_STATE_ID', parseInt(state_id));
}

/**
 *
 * @param commit
 */
export function resetLocation({commit}) {
    commit('RESET_LOCATION');
}

/**
 *
 * @param commit
 * @param pagination
 */
export function setLocationPagination({commit}, pagination) {
    commit('SET_LOCATION_PAGINATION', pagination);
}

/**
 *
 * @param commit
 * @param equipment
 */
export function setLocationEquipment({commit}, equipment) {
    commit('SET_LOCATION_EQUIPMENT', equipment);
}

/**
 * @param commit
 */
export function deleteLocationDiagram({commit}) {
    commit('DELETE_LOCATION_DIAGRAM');
}

/**
 * @param commit
 * @param diagram
 */
export function setDiagram({commit}, diagram) {
    commit('SET_LOCATION_DIAGRAM', diagram);
}

/**
 * @param commit
 * @param entries
 */
export function setFormEntries({commit}, entries) {
    commit('SET_FORM_ENTRIES', entries);
}

/**
 *
 * @param commit
 * @param county_id
 */
export function setCountyId({commit}, county_id) {
    commit('SET_COUNTY_ID', parseInt(county_id));
}

/**
 *
 * @param commit
 * @param municipalities
 */
export function setMunicipalities({commit}, municipalities) {
    commit('SET_MUNICIPALITIES', { municipalities: municipalities });
}
