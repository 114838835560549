import Repository from 'Repository';

export class EquipmentTypesRepository extends Repository {

    constructor() {
        super();
        this.resource = 'equipment/types';
    }
}

const EquipmentTypes = new EquipmentTypesRepository();

export default EquipmentTypes;

/**
 * @typedef EquipmentType
 * @type {object}
 * @property {number} equipment_type_id
 * @property {string} equipment_type
 * @property {object} options
 * @property {?CustomField[]} fields
 * @property {?EquipmentMake[]} makes
 */

export const EQUIPMENT_DEFAULT_FIELDS = [
    'equipment_number',
    'install_date',
    'make',
    'model',
    'serial',
];
