export default {

    data() {
        return {
            error_number_regex: /<ERR:[A-Z]{2}-[0-9]{3}>/,
        };
    },

    methods: {
        hasErrorNumber(number) {
            if ( !this.errors ) {
                return false;
            }

            return Object.entries(this.errors).filter(([, m]) => {
                return m.includes('<ERR:' + number + '>');
            }).length > 0;
        },
        removeErrorNumber(message) {
            return message.replace(this.error_number_regex, '');
        },
        getErrorNumber(message) {
            let matches = message?.match(this.error_number_regex);

            if ( matches?.length ) {
                return matches[0].replace('<ERR:', '').replace('>', '');
            }

            return false;
        },
    }
}