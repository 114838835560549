/**
 *
 * @param state
 * @returns {Array}
 */
export function field_types(state) {
    return state.field_types;
}

/**
 *
 * @param state
 * @returns {string[]}
 */
export function option_types(state) {
    return state.option_types;
}

/**
 *
 * @param state
 * @returns {*}
 */
export function fields(state) {
    return (group) => state.fields[group];
}

/**
 *
 * @param state
 * @returns {*}
 */
export function field(state) {
    return state.field;
}

/**
 *
 * @param state
 * @returns {*}
 */
export function available_rules(state) {
    return state.field_types
        .reduce((obj, item) => {
            obj[item.field_type] = {...item.available_rules};
            return obj;
        }, {});
}

/**
 *
 * @param state
 * @returns {{data: [], paginator: {next_page: null, total_count: number, limit: number, total_pages: number, previous_page: null, current_page: number}}|Array|Object}
 */
export function libraries(state) {
    return state.libraries;
}

/**
 *
 * @param state
 * @returns {ServiceItem[]}
 */
export function cachedItems(state) {
    return state.cached_items;
}

/**
 *
 * @param state
 * @returns {ServiceItem[]}
 */
export function serviceItemNames(state) {
    return state.cached_items
        .reduce((items, {service_item_id, name}) => {
            items[service_item_id] = name;
            return items;
        }, {});
}
