/**
 * Get all import templates.
 *
 * @param state
 * @returns {Array}
 */
export function import_templates(state) {
    return state.import_templates;
}

/**
 * Get all import types
 *
 * @param state
 * @returns {Array}
 */
export function import_types(state) {
    return state.import_types;
}

/**
 * Get all import file formats
 *
 * @param state
 * @returns {Array}
 */
export function import_file_formats(state) {
    return state.import_file_formats;
}

/**
 * Get the current import template.
 *
 * @param state
 * @returns {state.import_template|{}}
 */
export function import_template(state) {
    return state.import_template;
}