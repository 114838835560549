import store from '@/store/index';
import {historyMap, elementLoop, isUndoing, isRedoing, shouldAddHistory} from '@Vault/Diagrams/store/history-helpers';

/**
 * @param action
 * @param payload
 * @param options
 */
const addHistory = (action, payload, options) => {
    if ( isUndoing(options) ) {
        store.commit('diagrams/ADD_REDO', {action, payload});
    } else {
        store.commit('diagrams/ADD_HISTORY', {action, payload});
        store.commit('diagrams/SET_DIRTY', true);
    }

    if ( !isUndoing(options) && !isRedoing(options) && store.state.diagrams.redo.length > 0 ) {
        store.commit('diagrams/CLEAR_REDO');
    }
}

/**
 * @param commit
 * @param diagram
 */
export function setDiagram({commit}, diagram) {
    commit('SET_DIAGRAM', diagram);
}

/**
 * @param commit
 * @param diagram_id
 */
export function setDiagramId({commit}, diagram_id) {
    commit('SET_DIAGRAM_ID', diagram_id);
}

/**
 * @param commit
 * @param svg
 */
export function setSVG({commit}, svg) {
    commit('SET_SVG', svg);
}

/**
 * @param commit
 * @param options
 */
export function addElement({commit}, options) {
    const element = options.element;

    if ( shouldAddHistory(options) ) {
        addHistory('deleteSelected', [{
            id: element.id(),
            state: element
        }], element);
    }

    commit('ADD_ELEMENT', element);
}

/**
 * @param commit
 * @param element
 */
export function addSelected({commit}, element) {
    commit('ADD_SELECTED', element);
}

/**
 * @param commit
 * @param state
 * @param options
 */
export function undoDelete({commit, state}, options) {
    addHistory('deleteSelected', options.payload, options);

    for ( const {state: $element} of options.payload ) {
        if ( !$element.is_custom_element ) {
            const group = state.svg.group();

            group.attr('style', 'cursor:move;');
            group.add($element.element);
        } else {
            state.svg.add($element.element)
        }

        commit('ADD_ELEMENT', $element);
    }
}

/**
 * @param commit
 * @param payload
 * @param element
 */
export function deleteSelected({commit, state}, payload = null) {
    const history = historyMap(payload, state, element => element);

    addHistory('undoDelete', history, payload);

    elementLoop(payload, state, element => {
        element.deselect();
        element.selectize(false).resize('stop');

        commit('REMOVE_ELEMENT', element);

        const text = state.svg.find('.' + element.id());

        if ( text !== null ) {
            text.remove();
        }

        if ( element.is_custom_element ) {
            element.remove();
        } else {
            element.parent().remove();
        }
    });

    commit('CLEAR_SELECTED');

    if ( state.element_ids.length === 0 ) {
        commit('CLEAR_ELEMENTS')
    }
}

/**
 * @param commit
 * @param state
 */
export function clearSelected({commit, state}) {
    state.selected.forEach(element => {
        element.deselect();
    });

    commit('CLEAR_SELECTED');
}

/**
 * @param commit
 * @param element
 */
export function deselectElement({commit}, element) {
    commit('DESELECT_ELEMENT', element);
}

/**
 * @param commit
 * @param state
 * @param options
 */
export function updateSelectedFill({commit, state}, options) {
    const history = historyMap(options, state, element => element.fill());

    addHistory('updateSelectedFill', history, options);

    commit('UPDATE_SELECTED_FILL', options);
}

/**
 * @param commit
 * @param state
 * @param options
 */
export function updateSelectedStroke({commit, state}, options) {
    const history = historyMap(options, state, element => {
        const attr = element.attr();
        const data = {
            color: attr.stroke,
            width: attr['stroke-width']
        };

        if ( 'stroke-dasharray' in attr ) {
            data['stroke-dasharray'] = attr['stroke-dasharray'];
        }

        return data;
    });

    addHistory('updateSelectedStroke', history, options);

    commit('UPDATE_SELECTED_STROKE', options);
}

/**
 * @param commit
 * @param state
 * @param options
 */
export function updateSelectedSize({commit, state}, options) {
    const history = sizeHistory(options, state);

    addHistory('updateSelectedSize', history, options);

    commit('UPDATE_SELECTED_SIZE', options);
}

/**
 * @param state
 */
export function addSelectedSizeHistory({state}) {
    const options = null;
    const history = sizeHistory(options, state);

    addHistory('updateSelectedSize', history, options);
}

/**
 * Size history map
 *
 * @param options
 * @param state
 * @returns {*}
 */
const sizeHistory = (options, state) => {
    return historyMap(options, state, element => ({
        height: element.height(),
        width: element.width(),
        rotate: element.transform().rotate,
        constrain: element.params('constrain') || false,
        cx: element.bbox().cx,
        cy: element.bbox().cy,
        x: element.x(),
        y: element.y(),
    }));
}

/**
 * @param commit
 * @param state
 * @param rotate
 */
export function updateSelectedRotate({commit, state}, rotate) {
    const history = historyMap(rotate, state, element => element.transform().rotate);

    addHistory('updateSelectedRotate', history, rotate);

    commit('UPDATE_SELECTED_ROTATE', rotate);
}

/**
 * @param commit
 * @param state
 * @param position
 */
export function updateSelectedPosition({commit, state}, position) {
    const history = historyMap(position, state, element => {
        const {x, y} = element.bbox();

        return {x, y};
    });

    addHistory('updateSelectedPosition', history, position);

    commit('UPDATE_SELECTED_POSITION', position);
}

/**
 * @param state
 */
export function addSelectedPositionHistory({state}) {
    const options = null;
    const history = historyMap(options, state, element => {
        const {x, y} = element.bbox();

        return {x, y};
    });

    addHistory('updateSelectedPosition', history, options);
}

/**
 * @param commit
 * @param state
 * @param zindex
 */
export function updateSelectedZIndex({commit, state}, zindex) {
    const history = historyMap(zindex, state, element => element.parent().position());

    addHistory('updateSelectedZIndex', history, zindex);

    commit('UPDATE_SELECTED_ZINDEX', zindex);
}

/**
 * @param commit
 * @param data
 */
export function updateSelectedLabelData({commit}, data) {
    commit('UPDATE_SELECTED_LABEL_DATA', data);
}

/**
 * @param commit
 * @param state
 * @param rounded_factor
 */
export function updateRoundedFactor({commit, state}, rounded_factor) {
    const history = historyMap(rounded_factor, state, element => element.data('rounded-factor'));

    addHistory('updateRoundedFactor', history, rounded_factor);

    commit('UPDATE_ROUNDED_FACTOR', rounded_factor);
}

/**
 * @param commit
 * @param state
 * @param text_size
 */
export function updateTextSize({commit, state}, text_size) {
    const history = historyMap(text_size, state, element => element.font('size'));

    addHistory('updateTextSize', history, text_size);

    commit('UPDATE_TEXT_SIZE', text_size);
}

/**
 * @param commit
 * @param state
 * @param text
 */
export function updateText({commit, state}, text) {
    const history = historyMap(text, state, element => element.text());

    addHistory('updateText', history, text);

    commit('UPDATE_TEXT', text);
}

/**
 * @param commit
 * @param diagram_name
 */
export function updateDiagramName({commit}, diagram_name) {
    commit('UPDATE_DIAGRAM_NAME', diagram_name);
}

/**
 * @param commit
 * @param diagram_description
 */
export function updateDiagramDescription({commit}, diagram_description) {
    commit('UPDATE_DIAGRAM_DESCRIPTION', diagram_description);
}

/**
 * @param commit
 * @param state
 * @param constrain
 */
export function updateConstrain({commit, state}, constrain) {
    const history = historyMap(constrain, state, element => element.params('constrain'));

    addHistory('updateConstrain', history, constrain);

    commit('UPDATE_CONSTRAIN', constrain);
}

/**
 * @param commit
 * @param shapes
 */
export function setShapes({commit}, shapes) {
    commit('SET_SHAPES', shapes);
}

/**
 * @param commit
 * @param custom_elements
 */
export function setCustomElements({commit}, custom_elements) {
    commit('SET_CUSTOM_ELEMENTS', custom_elements);
}

/**
 * @param commit
 * @param state
 * @param custom_element
 */
export function addCustomElement({commit, state}, custom_element) {
    const group_index = state.custom_elements
        .findIndex(({diagram_element_group_id}) => diagram_element_group_id === custom_element.diagram_element_group_id);

    if ( group_index === -1 && !!custom_element?.group ) {
        commit('ADD_CUSTOM_ELEMENT_GROUP', custom_element.group);
    }

    commit('ADD_CUSTOM_ELEMENT', custom_element);
}

/**
 * @param commit
 * @param state
 * @param custom_element
 */
export function updateCustomElement({commit, state}, custom_element) {
    const element = state.custom_elements
        .flatMap(({elements}) => elements)
        .find(i => i.diagram_element_id === custom_element.diagram_element_id);

    if ( element && element.diagram_element_group_id !== custom_element.diagram_element_group_id ) {
        commit('REMOVE_CUSTOM_ELEMENT', element);
        commit('ADD_CUSTOM_ELEMENT', custom_element);
    } else {
        commit('UPDATE_CUSTOM_ELEMENT', custom_element);
    }
}

/**
 * @param commit
 * @param custom_element
 */
export function removeCustomElement({commit}, custom_element) {
    commit('REMOVE_CUSTOM_ELEMENT', custom_element);
}

/**
 * @param commit
 * @param movement
 */
export function setDiagramMovement({commit}, movement) {
    commit('SET_DIAGRAM_MOVEMENT', movement);
}

/**
 * @param commit
 * @param state
 */
export function undo({state, dispatch}) {
    if ( state.history.length === 0 ) {
        return;
    }

    const previous = state.history.shift();

    dispatch(previous.action, {undo: true, payload: previous.payload});
}

/**
 * @param state
 * @param dispatch
 */
export function redo({state, dispatch}) {
    if ( state.redo.length === 0 ) {
        return;
    }

    const next = state.redo.shift();

    dispatch(next.action, {redo: true, payload: next.payload});
}

/**
 * @param commit
 */
export function resetStore({commit}) {
    commit('RESET_STORE');
}

/**
 * @param commit
 * @param errors
 */
export function setDiagramErrors({commit}, errors) {
    commit('DIAGRAM_ERRORS', errors);
}

/**
 * @param commit
 */
export function clearDiagramErrors({commit}) {
    commit('CLEAR_DIAGRAM_ERRORS');
}

/**
 * @param commit
 * @param dirty
 */
export function setDirty({commit}, dirty) {
    commit('SET_DIRTY', dirty);
}

/**
 * @param commit
 * @param custom_element
 */
export function setEditingCustomElement({commit}, custom_element = {}) {
    commit('SET_EDITING_CUSTOM_ELEMENT', custom_element);
}

/**
 * @param commit
 * @param elements
 * @param group
 */
export function updateCustomElementSort({commit}, {elements, group}) {
    commit('UPDATE_CUSTOM_ELEMENT_SORT', {elements, group});
}

/**
 * @param commit
 * @param group
 */
export function addCustomElementGroup({commit}, group) {
    commit('ADD_CUSTOM_ELEMENT_GROUP', group);
}

/**
 * @param commit
 * @param group
 */
export function updateCustomElementGroup({commit}, group) {
    commit('UPDATE_CUSTOM_ELEMENT_GROUP', group);
}

/**
 * @param commit
 * @param groups
 */
export function updateCustomElementGroupSort({commit}, groups) {
    commit('UPDATE_CUSTOM_ELEMENT_GROUP_SORT', groups);
}