import Vue from 'vue';

/**
 * @typedef ReferenceCategory
 * @type {object}
 * @property {string} name
 * @property {number} reference_category_id
 * @property {?number} parent_category_id
 * @property {?ReferenceCategory[]} child_categories
 * @property {?string} created_at
 * @property {?string} updated_at
 *
 * @typedef ReferenceDocument
 * @type {object}
 * @property {string} title
 * @property {number} reference_document_id
 * @property {?number} reference_category_id
 * @property {*} content
 * @property {?string} effective_date
 * @property {?string} created_at
 * @property {?string} updated_at
 *
 * @typedef ReferenceItem
 * @property {string} type
 * @property {ReferenceCategory|ReferenceDocument} item
 */

/** @type {ReferenceDocument} */
const blank_document = { title: null, content: null };

const state = {
    /** @type {ReferenceCategory[]} */ categories: [],
    /** @type {?ReferenceDocument} */  document: blank_document,
    /** @type {ReferenceDocument[]} */ documents: [],
    /** @type {?ReferenceCategory} */  dragging_category: null,
    /** @type {?ReferenceDocument} */  dragging_document: null,
    /** @type {boolean} */             editing_document: false,
    /** @type {ReferenceCategory[]} */ flattened_categories: [],
    /** @type {?ReferenceCategory} */  selected_category: null,
    /** @type {?ReferenceItem} */      selected_item: null,
    paginator: {},
};

const mutations = {
    /**
     * @param state
     * @param {ReferenceCategory[]} categories
     */
    SET_CATEGORIES (state, categories) {
        Vue.set(state, 'categories', categories);

        let flattened = [];
        let flatten = (categories) => {
            categories.forEach((category) => {
                flattened.push(category);
                flatten(category.child_categories || []);
            });
        }
        flatten(state.categories);
        Vue.set(state, 'flattened_categories', flattened);
    },

    /**
     * @param state
     * @param {ReferenceDocument} document
     */
    SET_DOCUMENT (state, document) {
        Vue.set(state, 'document', document);
    },

    /**
     * @param state
     */
    RESET_DOCUMENT (state) {
        Vue.set(state, 'document', blank_document);
    },

    /**
     * @param state
     * @param {ReferenceDocument[]} documents
     */
    SET_DOCUMENTS (state, documents) {
        Vue.set(state, 'documents', documents);
    },

    /**
     * @param state
     * @param paginator
     * @constructor
     */
    SET_DOCUMENTS_PAGINATOR (state, paginator) {
        Vue.set(state, 'paginator', paginator);
    },

    /**
     * @param state
     * @param {ReferenceCategory} category
     */
    SET_DRAGGING_CATEGORY (state, category) {
        Vue.set(state, 'dragging_category', category);
    },

    /**
     * @param state
     */
    RESET_DRAGGING_CATEGORY (state) {
        Vue.set(state, 'dragging_category', null);
    },

    /**
     * @param state
     * @param {ReferenceDocument} document
     */
    SET_DRAGGING_DOCUMENT (state, document) {
        Vue.set(state, 'dragging_document', document);
    },

    /**
     * @param state
     */
    RESET_DRAGGING_DOCUMENT (state) {
        Vue.set(state, 'dragging_document', null);
    },

    /**
     * @param state
     * @param {boolean} editing
     */
    SET_EDITING_DOCUMENT (state, editing) {
        Vue.set(state, 'editing_document', editing);
    },

    /**
     * @param state
     * @param {ReferenceCategory} category
     */
    SET_SELECTED_CATEGORY (state, category) {
        Vue.set(state, 'selected_category', category);
    },

    /**
     * @param state
     * @param {?number} category_id
     */
    SET_SELECTED_CATEGORY_BY_ID (state, category_id) {
        if ( !category_id ) {
            return Vue.set(state, 'selected_category', null);
        }

        let category = state.flattened_categories
            .find(({reference_category_id}) => reference_category_id == category_id);

        if ( category ) {
            Vue.set(state, 'selected_category', category);
        }
    },

    /**
     * @param state
     * @param {?ReferenceItem} item
     */
    SET_SELECTED_ITEM (state, item) {
        Vue.set(state, 'selected_item', item);
    },
};

import * as getters from './getters';
import * as actions from './actions';

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
