import Vue from 'vue';
import {object_set, object_get} from 'kickstart-ui/lib/objects.js';
import _cloneDeep from 'lodash/cloneDeep';
import { snakeToTitle } from 'helpers/strings.js';
import { REGEX_REFERENCE_ALL } from 'helpers/expressions.js';
import { EQUIPMENT_DEFAULT_FIELDS } from '@Vault/Equipment/repositories/EquipmentTypesRepository.js';
import {isObject} from 'helpers/types';

const blank_form = {
    name: '',
    sections: [],
    require_result: false,
    options: {},
};

const blank_field = {
    group: 'form_section',
    field_type: 'text',
    label: '',
    placeholder: '',
    default_value: '',
    required: 0,
    options: null
};

const blank_form_references = {
    form: {},
    equipment: {},
    work: {},
};

const blank_store = {
    forms: [],
    entry: null,
    entry_sections: [],
    entry_values: {},
    entry_files: {},
    entry_location: {},
    entry_customer: {},
    form: {...blank_form},
    section: {
        section_name: ''
    },
    field: null,
    // represents values of the current section at the current section index
    section_values: {},
    section_index: 0,
    equipment_type_fields: [],
    equipment_type_fields_loaded: false,
    formula_suggestions: [],
    read_only_suggestions: [],
    form_references: {...blank_form_references},
    context_date: null,
    // Inventory location of the form entry is based on context data
    inventory_location_id: null,
    // Entry section lists represents the list count for multi-list sections so it can be retained when switching between sections
    entry_section_lists: {},
    print_view_cache_key: null,
    form_print_view: {},
    result_options: [
        {type: 'In Progress', result: 4},
        {type: 'Completed', result: 0},
        {type: 'Pass', result: 1},
        {type: 'Fail', result: 2},
        {type: 'Inconclusive', result: 3},
    ],
    result_state_options: [
        {type: 'Passing', result: 'passing'},
        {type: 'Inconclusive', result: 'inconclusive'},
        {type: 'Fail', result: 'fail'},
    ]
}

const state = {...blank_store};

const fillEntryValues = ((obj, {custom_field_id, form_section_id, value_index, value}) => {
    // Initialize the form sections values
    if ( !(form_section_id in obj) ) {
        Vue.set(obj, form_section_id, {});
    }
    // Initialize the value index for the section
    if ( !(value_index in obj[form_section_id]) ) {
        Vue.set(obj[form_section_id], value_index, {});
    }

    if ( custom_field_id !== undefined && value !== undefined ) {
        // Set the value for the given value index
        Vue.set(obj[form_section_id][value_index], custom_field_id, value);
    }
})

const mutations = {
    SET_FORMS(state, forms) {
        Vue.set(state, 'forms', forms);
    },

    SET_FORM(state, form) {
        // also initialize sections if it doesn't have any yet
        Vue.set(state, 'form', {
            ...form,
            sections: form.sections || [],
        });

        if ( form.sections ) {
            this.commit('forms/RESET_FORM_REFERENCE_VALUES');
        }
    },

    RESET_FORM(state) {
        Vue.set(state, 'form', {...blank_form});
        state.field = null;
    },

    SET_SECTION(state, section) {
        section = _cloneDeep(section);

        if ( section.fields ) {
            for ( let field of section.fields ) {
                if ( field.field_type === 'formula' && typeof(field.options.formula) === 'string' ) {
                    field.options.formula = [{
                        formula: field.options.formula,
                        conditions: [],
                    }];
                }
            }
        }

        let index = state.form.sections.findIndex(({form_section_id}) => form_section_id == section.form_section_id);

        if ( index !== -1 ) {
            // update the existing section so that previously loaded relationships don't disappear
            section = {
                ...state.form.sections[index],
                ...section
            };

            Vue.set(state.form.sections, index, section);
        } else {
            state.form.sections.push(section);
        }

        Vue.set(state, 'section', section);
    },

    SET_SECTION_SORT(state, sections) {
        // map section ids to sort order
        sections = sections.reduce((map, section) => {
            map[section.form_section_id] = section.sort;
            return map;
        }, {});

        Vue.set(state.form, 'sections', state.form.sections.map(section => ({
            ...section,
            sort: sections[section.form_section_id],
        })));

        // update current section
        Vue.set(state.section, 'sort', sections[state.section.form_section_id]);
    },

    SET_FORM_SECTIONS(state, sections) {
        Vue.set(state.form, 'sections', sections);

        this.commit('forms/RESET_FORM_REFERENCE_VALUES');
    },

    RESET_SECTION(state) {
        Vue.set(state, 'section', {section_name: ''});
    },

    SET_FIELD(state, field) {
        state.field = field;
    },

    NEW_FIELD(state) {
        state.field = Object.assign({custom_field_id: null}, {...blank_field, options: {}});
    },

    RESET_FIELD(state) {
        state.field = null;
    },

    SET_ENTRY(state, entry) {
        state.entry = entry;
    },

    SET_ENTRY_FORM_SECTIONS(state, sections) {
        let field_index = 1;
        for ( let s in sections ) {
            let section = sections[s];

            for ( let f in section.fields || [] ) {
                let field = section.fields[f];

                field.field_index = field_index++;
            }
        }

        Vue.set(state, 'entry_sections', sections);
    },

    RESET_ENTRY_SECTION_LISTS(state) {
        // Reset the entry section lists when the entry sections are set
        Vue.set(state, 'entry_section_lists', {});
    },

    SET_SECTION_FIELDS(state, fields) {
        Vue.set(state.section, 'fields', fields);
    },

    SET_FORMULA_SUGGESTIONS(state, fields) {
        Vue.set(state, 'formula_suggestions', fields);
    },

    SET_READ_ONLY_SUGGESTIONS(state, fields) {
        Vue.set(state, 'read_only_suggestions', fields);
    },

    SET_EQUIPMENT_TYPE_FIELDS(state, fields) {
        Vue.set(state, 'equipment_type_fields', fields.slice().map(field => {
            return {
                ...field,
                parent_type: 'equipment',
                form_section_id: state.section.form_section_id,
                section_name: state.section.section_name,
            };
        }));

        Vue.set(state, 'equipment_type_fields_loaded', true);
    },

    SET_VALUES(state, values) {
        let entry_values = {};

        let fields = (state.entry_sections.flatMap(section => section.fields) || []);

        // get custom_field_ids referenced in conditions or formulas
        let references = [...(new Set([
            // conditions
            ...fields.flatMap(field => {
                return (field.options?.conditions || [])
                    .filter(({condition_type}) => condition_type === 'custom_field')
                    .map(({custom_field_id}) => parseInt(custom_field_id))
            }),

            // formula/conditions
            ...fields.filter(field => field.field_type === 'formula')
                .flatMap(field => field.options.formula)
                .reduce((references, {formula, conditions}) => {
                    return references.concat(
                        // references from formula expressions
                        [...(formula || '').matchAll(REGEX_REFERENCE_ALL)]
                            .reduce((custom_field_ids, reference) => {
                                let split = reference[1].split(':');
                                if ( split.length === 1 || split[0] === 'custom_field' ) {
                                    custom_field_ids.push(parseInt(split.pop()))
                                }
                                return custom_field_ids
                            }, []),

                        // references from formula conditions
                        conditions
                            .filter(({condition_type}) => condition_type === 'custom_field')
                            .map(({custom_field_id}) => parseInt(custom_field_id))
                    );
                }, []),

            // read-only/conditions
            ...fields.filter(field => field.field_type === 'read')
                .flatMap(field => field.options.read)
                .reduce((references, {value, conditions}) => {
                    return references.concat(
                        // references from read-only values
                        [...(value || '').matchAll(REGEX_REFERENCE_ALL)]
                            .reduce((custom_field_ids, reference) => {
                                let split = reference[1].split(':');
                                if ( split.length === 1 || split[0] === 'custom_field' ) {
                                    custom_field_ids.push(parseInt(split.pop()))
                                }
                                return custom_field_ids
                            }, []),

                        // references from read-only conditions
                        conditions
                            .filter(({condition_type}) => condition_type === 'custom_field')
                            .map(({custom_field_id}) => parseInt(custom_field_id))
                    );
                }, []),
        ]))];

        for ( let value of values ) {
            let section_id = value.data.form_section_id;
            let path = `${section_id}.${value.value_index}.${value.custom_field_id}`;

            if ( value.field.deleted_at !== null ) {
                continue;
            }

            if ( value.field.is_multi_value_field && !object_get(entry_values, path) ) {
                // Initialize the value to an empty array for multi value fields
                // That way the object set will append the values together based on the value_index
                object_set(entry_values, path, []);
            }

            if ( value.field.field_type === 'formula' && value.data?.meta ) {
                // Reconstruct array formula result value

                let {context, ...meta} = value.data.meta;

                if ( !object_get(entry_values, path) ) {
                    object_set(entry_values, path, { meta, value: {} });
                }

                // Values are stored in value.value keyed by a context value e.g. unique y values in SUMBY(x, y)
                object_set(entry_values, `${path}.value.${context}`, value.value);

            } else {
                object_set(entry_values, path, value.value);
            }

            if ( references.includes(value.custom_field_id) ) {
                mutations.SET_FORM_REFERENCE_VALUE(state, {
                    reference_key: `custom_field:${value.custom_field_id}`,
                    context: 'form',
                    context_id: section_id,
                    value_index: value.value_index,
                    value: value.value,
                    multi_value: value.field.is_multi_value_field,
                });
            }
        }

        Vue.set(state, 'entry_values', entry_values);
    },

    RESET_FORM_REFERENCE_VALUES(state) {
        let references = _cloneDeep(blank_form_references);

        for ( let section of state.form.sections ) {
            for ( let field of section?.fields || [] ) {
                references.form[`custom_field:${field.custom_field_id}`] = {
                    label: field.label,
                    section_name: section.section_name,
                    values: null,
                    field,
                };
            }

            if ( section.equipment_type ) {
                for ( let field of section.equipment_type.fields ) {
                    references.form[`custom_field:${field.custom_field_id}`] = {
                        label: field.label,
                        section_name: section.section_name,
                        values: null,
                        field,
                    };

                    references.equipment[`custom_field:${field.custom_field_id}`] = {
                        label: field.label,
                        values: null,
                        field,
                    };
                }

                // reset default field values
                for ( let field of EQUIPMENT_DEFAULT_FIELDS ) {
                    references.form[`equipment_type:${section.equipment_type.equipment_type_id}:${field}`] = {
                        label: snakeToTitle(field),
                        section_name: section.section_name,
                        values: null,
                    }
                }
            }
        }

        state.form_references = references;
    },

    SET_FORM_REFERENCE_VALUES(state, form_reference_values) {
        form_reference_values.forEach(({context, context_id, value_index = 0, reference_key, value, multi_value}) => {
            mutations.SET_FORM_REFERENCE_VALUE(state,{reference_key, context, context_id, value_index, value, multi_value});
        });
    },

    SET_FORM_REFERENCE_VALUE(state, {reference_key, context, context_id, value_index, value, multi_value = false}) {
        if ( !state.form_references[context][reference_key] ) {
            Vue.set(state.form_references[context], reference_key, {values: null});
        }

        if ( !state.form_references[context][reference_key].values ) {
            Vue.set(state.form_references[context][reference_key], 'values', {});
        }

        if ( !state.form_references[context][reference_key].values[context_id] ) {
            Vue.set(state.form_references[context][reference_key].values, context_id, {});
        }

        Vue.set(
            state.form_references[context][reference_key].values[context_id],
            value_index,
            multi_value
                ? [...new Set((state.form_references[context][reference_key].values[context_id][value_index] || []).concat(value))]
                    .filter(v => !['', null, undefined].includes(v))
                : value
        );
    },

    UNSET_FORM_REFERENCE_VALUES(state, form_reference_values) {
        form_reference_values.forEach(({context, context_id, value_index = 0, reference_key}) => {
            mutations.UNSET_FORM_REFERENCE_VALUE(state,{reference_key, context, context_id, value_index});
        });
    },

    UNSET_FORM_REFERENCE_VALUE(state, {reference_key, context, context_id, value_index}) {
        mutations.SET_FORM_REFERENCE_VALUE(state,{reference_key, context, context_id, value_index, value: null});
    },

    SET_FIELD_VALUE(state, {section_id, value, value_index}) {
        Vue.set(state.entry_values[section_id], value_index, value);
    },

    SET_FILES(state, files) {
        let entry_files = {};

        for ( let i in files ) {
            let file = files[i];

            if ( !file.data ) {
                continue;
            }

            let section_id = file.data.form_section_id;
            let value_index = file.data.value_index;
            let custom_field_id = file.target_id;

            // Initialize an empty array
            if ( !object_get(entry_files, `${section_id}.${value_index}.${custom_field_id}`) ) {
                object_set(
                    entry_files,
                    `${section_id}.${value_index}.${custom_field_id}`,
                    []
                );
            }

            object_set(entry_files, `${section_id}.${value_index}.${custom_field_id}`, file);
        }

        Vue.set(state, 'entry_files', entry_files);
    },

    SET_SECTION_FIELD_FILES(state, {section_id, value_index, custom_field_id, files}) {
        if ( !state.entry_files[section_id] ) {
            Vue.set(state.entry_files, section_id, {});
        }

        if ( !state.entry_files[section_id][value_index] ) {
            Vue.set(state.entry_files[section_id], value_index, {});
        }

        Vue.set(state.entry_files[section_id][value_index], custom_field_id, files);
    },

    RESET_STORE(state) {
        for ( const [key, value] of Object.entries(state.form_references) ) {
            if ( Object.keys(value).length > 0 ) {
                for ( const [k,] of Object.entries(value) ) {
                    Vue.delete(state.form_references[key], k);
                }
            }

            Vue.delete(state.form_references, key);
        }

        for ( const [key,] of Object.entries(state) ) {
            if ( !blank_store?.[key] ) {
                if ( isObject(state[key]) ) {
                    for ( const [k,] of Object.entries(state[key]) ) {
                        Vue.delete(state[key], k);
                    }
                }

                Vue.delete(state, key)
            }
        }

        for ( const [key, value] of Object.entries(blank_store) ) {
            Vue.set(state, key, value);
        }
    },

    SET_ENTRY_SECTION_DATA(state, entry_sections) {
        if ( state.entry ) {
            Vue.set(state.entry, 'entry_sections', entry_sections.slice());
        }
    },

    MERGE_ENTRY_SECTION_DATA(state, entry_sections) {
        if ( state.entry ) {
            entry_sections = entry_sections.slice();

            // Create dictionaries indexes by `value_index` to match up the existing data to that being passed
            let current_entry_sections_map = Object.fromEntries(
                state.entry.entry_sections.slice().map(v => [`${v.value_index}-${v.form_section_id}`, v])
            );
            let passed_entry_sections_map = Object.fromEntries(
                entry_sections.map(v => [`${v.value_index}-${v.form_section_id}`, v])
            );

            for ( let section_value_index in current_entry_sections_map ) {
                // If the passed data does not  have a value for the index then we will fill one in
                if ( !passed_entry_sections_map[section_value_index] ) {
                    delete current_entry_sections_map[section_value_index].original_value_index;
                    passed_entry_sections_map[section_value_index] = {
                        ...current_entry_sections_map[section_value_index],
                        merged: true
                    };
                }
            }

            Vue.set(state.entry_section_lists, Object.keys(passed_entry_sections_map).reduce((lists, section_value_index) => {

                let [value_index, form_section_id] = section_value_index.split('-');
                if ( !lists[form_section_id] ) {
                    lists[form_section_id] = [value_index];
                } else {
                    lists[form_section_id].push(value_index);
                }

                return lists;
            }, {}));
            Vue.set(state.entry, 'entry_sections', Object.values(passed_entry_sections_map));
        }
    },

    /**
     *
     * @param state
     * @param location
     */
    SET_ENTRY_LOCATION(state, location) {
        Vue.set(state, 'entry_location', {...location});
    },

    /**
     *
     * @param state
     * @param customer
     */
    SET_ENTRY_CUSTOMER(state, customer) {
        Vue.set(state, 'entry_customer', {...customer});
    },

    /**
     * Updates/sets equipment for the given form section/index, creating an entry section if necessary.
     * Equipment values might have changed so this also updates any other entry sections with the same equipment_id.
     *
     * @param state
     * @param {number} form_section_id
     * @param {number} value_index
     * @param {Equipment} equipment
     * @param {boolean} update_all
     */
    SET_ENTRY_SECTION_EQUIPMENT(state, {form_section_id, value_index, equipment, update_all = true}) {
        // get the entry section matching the given form_section_id/value_index
        let index = state.entry.entry_sections.findIndex(section => section.form_section_id === form_section_id && section.value_index === value_index);

        // create an entry section if it doesn't exist
        if ( index === -1 ) {
            state.entry.entry_sections.push({
                form_section_id,
                value_index,
                equipment,
                equipment_id: equipment.equipment_id,
            });

        // otherwise update the existing one with the given equipment
        } else {
            Vue.set(state.entry.entry_sections, index, {
                ...state.entry.entry_sections[index],
                equipment: {...equipment},
                equipment_id: equipment.equipment_id,
            });
        }

        if ( !update_all ) {
            return;
        }

        // update other entry sections with the same equipment_id
        for ( let i in state.entry.entry_sections ) {
            let entry_section = state.entry.entry_sections[i];

            if ( entry_section.equipment_id === equipment.equipment_id ) {
                Vue.set(state.entry.entry_sections[i], 'equipment', {
                    ...(entry_section.equipment || {}),
                    ...equipment,
                    equipment_id: equipment.equipment_id,
                });
            }
        }
    },

    /**
     *
     * @param state
     * @param form_triggers
     */
    SET_FORM_TRIGGERS(state, form_triggers) {
        Vue.set(state.form, 'triggers', form_triggers);
    },

    UPDATE_SECTION_VALUE(state, {value, custom_field_id}) {
        Vue.set(state.section_values, custom_field_id, value);
    },

    UNSET_SECTION_FIELD_VALUES(state, {fields, value_index, form_section_id}) {
        fields.forEach(field => {
            mutations.UPDATE_SECTION_VALUE(state, {
                custom_field_id: field.custom_field_id,
                value: null
            });

            mutations.SET_FORM_REFERENCE_VALUE(state, {
                reference_key: `custom_field:${field.custom_field_id}`,
                context: 'form',
                context_id: form_section_id,
                value_index,
                value: null
            });
        });
    },

    SET_SECTION_INDEX(state, index) {
        Vue.set(state, 'section_index', index);

        if ( state.section.form_section_id in state.entry_values &&
            state.section_index in state.entry_values[state.section.form_section_id]
        ) {
            Vue.set(state, 'section_values', {...state.entry_values[state.section.form_section_id][state.section_index]});
        } else {
            Vue.set(state, 'section_values', {});
        }
    },

    SET_RESULT_VALIDATION(state, validation) {
        Vue.set(state.form, 'validation', validation);
    },

    SET_ENTRY_CONTEXT_DATE(state, context_date) {
        state.context_date = context_date;
    },

    SET_ENTRY_INVENTORY_LOCATION_ID(state, inventory_location_id) {
        state.inventory_location_id = inventory_location_id;
    },

    PATCH_ENTRY_VALUES(state, values) {
        values.forEach(({custom_field_id, form_section_id, value_index, value}) => {
            fillEntryValues(state.entry_values, {custom_field_id, form_section_id, value_index, value});
        })
    },

    INSERT_ENTRY_VALUE(state, {form_section_id, value_index, equipment}) {
        let entry_values = Object.entries(state.entry_values[form_section_id] || {})
            // increment the value index of existing values >= given value index
            .map(([entry_value_index, value]) => {
                let index = entry_value_index >= value_index ? parseInt(entry_value_index) + 1 : entry_value_index;
                return [index, value];
            });

        // add placeholder object at the given value index
        entry_values.push([value_index, {}]);

        Vue.set(state.entry_values, form_section_id, Object.fromEntries(entry_values));

        let entry_sections = state.entry.entry_sections.map(entry_section => {
            // increment the value index of existing sections >= given value index
            let new_value_index = entry_section.value_index >= value_index ? parseInt(entry_section.value_index) + 1 : entry_section.value_index
            return {
                ...entry_section,
                // save the original value index
                original_value_index: entry_section.original_value_index ?? entry_section.value_index,
                value_index: new_value_index,
            };
        });

        // add placeholder object
        entry_sections.push({ form_section_id, value_index, ...(equipment ? {equipment} : {})});

        Vue.set(state.entry, 'entry_sections', entry_sections);
    },

    REMOVE_ENTRY_VALUE(state, {form_section_id, value_index}) {
        let entry_values = Object.entries(state.entry_values[form_section_id] || {})
            // remove the given value index
            .filter(([entry_value_index, value]) => entry_value_index != value_index)
            // decrement the value index of existing values > given value index
            .map(([entry_value_index, value]) => {
                let index = entry_value_index > value_index ? parseInt(entry_value_index) - 1 : entry_value_index;
                return [index, value];
            });

        Vue.set(state.entry_values, form_section_id, Object.fromEntries(entry_values));

        let entry_sections = state.entry.entry_sections
            // remove the given form_section_id/value_index
            .filter(entry_section => !(entry_section.form_section_id == form_section_id && entry_section.value_index == value_index))
            // decrement the value index of existing sections > value index
            .map(entry_section => ({
                ...entry_section,
                value_index: entry_section.value_index > value_index ? parseInt(entry_section.value_index) - 1 : entry_section.value_index,
            }));

        Vue.set(state.entry, 'entry_sections', entry_sections);

        // remove form references
        let references = _cloneDeep(state.form_references.form);
        for ( let reference_key in references ) {
            for ( let section_id in (references[reference_key].values || {}) ) {
                references[reference_key].values[section_id] = Object.fromEntries(
                    Object.entries(references[reference_key].values[section_id])
                        .filter(([index,]) => index != value_index)
                        .map(([index, value]) => [index > value_index ? index - 1 : index, value])
                );
            }
        }
        Vue.set(state.form_references, 'form', references);
    },

    SET_ENTRY_SECTION_LISTS(state, {section_id, list}) {
        Vue.set(state.entry_section_lists, section_id, list);
    },

    SORT_ENTRY_SECTION_LIST(state, section_id) {
        if ( section_id in state.entry_section_lists ) {
            state.entry_section_lists[section_id].sort();
        }
    },

    SET_PRINT_VIEW_CACHE_KEY(state, cache_key) {
        state.print_view_cache_key = cache_key;
    },

    SET_PRINT_VIEW(state, print_view) {
        Vue.set(state, 'form_print_view', print_view);
    },

    UPDATE_PRINT_VIEW_PAGES(state, print_view_pages) {
        Vue.set(state.form_print_view, 'print_view_pages', print_view_pages);
    },

    UPDATE_PRINT_VIEW_PAGE_CONDITIONS(state, {form_print_view_page_id, conditions}) {
        const page_index = state.form_print_view
            ?.print_view_pages
            ?.findIndex(page => page.form_print_view_page_id === form_print_view_page_id);

        if ( page_index === -1 ) {
            return;
        }

        Vue.set(state.form_print_view.print_view_pages[page_index], 'conditions', conditions)
    },
};

import * as getters from './getters';
import * as actions from './actions';

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
