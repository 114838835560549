<template>
    <ul class="breadcrumb">
        <template v-for="(link, index) in breadcrumbs">
            <li v-if="link.to">
                <router-link :to="link.to" v-if="index != breadcrumbs.length -1">
                    <span>{{link.title | ellipsis}}</span>
                </router-link>
                <template v-else>{{link.title}}</template>
            </li>
            <li v-else>
                <span>{{link | ellipsis}}</span>
            </li>
        </template>
    </ul>
</template>

<script>
    import {mapGetters} from 'vuex';
    import {ellipsis} from 'kickstart-ui/lib/strings';

    export default {
        name: 'PageHeader',

        computed: {
            ...mapGetters([
                'breadcrumbs'
            ])
        },

        filters: {
            ellipsis(str) {
                return ellipsis(str, 25);
            }
        }
    }
</script>
