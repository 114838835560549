/**
 *
 * @param commit
 * @param which
 * @param where
 * @param value
 */
export function setFilter({commit}, {namespace, which, where, value}) {
    commit('SET_FILTER', {namespace, which, where, value});
}

/**
 *
 * @param commit
 * @param {string} namespace
 * @param {string|string[]} which
 * @param {?string} sub_key
 */
export function clearFilter({commit}, {namespace, which, sub_key = null}) {
    commit('CLEAR_FILTER', {namespace, which, sub_key});
}

export function clearAllFilters({commit}, namespace) {
    commit('CLEAR_ALL_FILTERS', namespace);
}

/**
 *
 * @param commit
 * @param namespace
 */
export function setNamespace({commit}, namespace) {
    commit('SET_NAMESPACE', namespace);
}

/**
 *
 * @param commit
 * @param namespace
 */
export function clearFilterNamespace({commit}, namespace) {
    commit('CLEAR_NAMESPACE', namespace);
}
