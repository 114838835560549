<template>
    <vault-icon :icon="icon" :style="{color: hex}"></vault-icon>
</template>

<script>
    // External Dependencies
    import {faEnvelope} from '@fortawesome/pro-solid-svg-icons/faEnvelope';

    // Internal Dependencies
    import BaseIcon from './BaseIcon';

    export default BaseIcon.extend({
        name: 'IconEnvelope',

        data() {
            return {
                icon: faEnvelope,
            }
        }
    })
</script>
