/**
 *
 * @param commit
 * @param invoices
 */
export function setInvoices({commit}, invoices) {
    commit('SET_INVOICES', invoices);
}

/**
 *
 * @param commit
 * @param invoice
 */
export function removeInvoice({commit}, invoice) {
    commit('REMOVE_INVOICE', invoice);
}

/**
 *
 * @param commit
 * @param invoice
 */
export function setInvoice({commit}, invoice) {
    commit('SET_INVOICE', invoice);
}

/**
 *
 * @param commit
 * @param date
 */
export function setInvoiceDate({commit}, date) {
    commit('SET_INVOICE_DATE', date);
}

/**
 *
 * @param commit
 * @param invoice_items
 */
export function setInvoiceItems({commit}, invoice_items) {
    commit('SET_INVOICE_ITEMS', invoice_items);
}

/**
 *
 * @param commit
 * @param pending_jobs
 */
export function setInvoicePendingJobs({commit}, pending_jobs) {
    commit('SET_INVOICE_PENDING_JOBS', pending_jobs);
}

/**
 *
 * @param commit
 * @param work
 */
export function setInvoiceWork({commit}, work) {
    commit('SET_INVOICE_WORK', work);
}

/**
 *
 * @param commit
 * @param visits
 */
export function setInvoiceVisits({commit}, visits) {
    commit('SET_INVOICE_VISITS', visits);
}

/**
 *
 * @param commit
 */
export function resetInvoice({commit}) {
    commit('RESET_INVOICE');
}

/**
 *
 * @param commit
 * @param item
 */
export function addInvoiceItem({commit}, item) {
    commit('ADD_INVOICE_ITEM', item);
}

/**
 *
 * @param commit
 * @param item
 */
export function deleteInvoiceItem({commit}, item) {
    commit('DELETE_INVOICE_ITEM', item);
}

/**
 *
 * @param commit
 * @param item
 */
export function updateInvoiceItem({commit}, item) {
    commit('UPDATE_INVOICE_ITEM', item);
}

/**
 *
 * @param commit
 * @param item
 * @param property
 * @param value
 */
export function updateInvoiceItemProperty({commit}, {item, property, value}) {
    commit('UPDATE_INVOICE_ITEM_PROP', {item, property, value})
}

/**
 *
 * @param commit
 * @param work
 */
export function addInvoiceWork({commit}, work) {
    commit('ADD_INVOICE_WORK', work);
}

/**
 *
 * @param commit
 * @param customer
 */
export function setInvoiceCustomer({commit}, customer) {
    commit('SET_INVOICE_CUSTOMER', customer);
}

/**
 *
 * @param commit
 * @param location
 */
export function setInvoiceLocation({commit}, location) {
    commit('SET_INVOICE_LOCATION', location);
}

/**
 *
 * @param commit
 * @param contact
 */
export function setInvoiceContact({commit}, contact) {
    commit('SET_INVOICE_CONTACT', contact);
}

/**
 *
 * @param commit
 * @param sales_tax
 */
export function setSalesTax({commit}, sales_tax) {
    commit('SET_SALES_TAX', sales_tax);
}

/**
 *
 * @param commit
 * @param is_taxable_state_loading Boolean
 */
export function setIsTaxableStateLoading({commit}, is_taxable_state_loading) {
    commit('SET_IS_TAXABLE_STATE_LOADING', is_taxable_state_loading);
}

/**
 *
 * @param commit
 * @param is_taxable_state Boolean
 */
export function setIsTaxableState({commit}, is_taxable_state) {
    commit('SET_IS_TAXABLE_STATE', is_taxable_state);
}

/**
 * @param commit
 * @param request_canceler Object
 */
export function setTaxableStateRequestCanceler({commit}, request_canceler) {
    commit('SET_TAXABLE_STATE_REQUEST_CANCELER', request_canceler);
}

/**
 *
 * @param commit
 * @param state_used_for_tax string
 */
export function setStateUsedForTax({commit}, state_used_for_tax) {
    commit('SET_STATE_USED_FOR_TAX', state_used_for_tax);
}
