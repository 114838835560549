/**
 * Get all export templates.
 *
 * @param state
 * @returns {Array}
 */
export function export_templates(state) {
    return state.export_templates;
}

/**
 * Get all export types
 *
 * @param state
 * @returns {Array}
 */
export function export_types(state) {
    return state.export_types;
}

/**
 * Get all export file formats
 *
 * @param state
 * @returns {Array}
 */
export function export_file_formats(state) {
    return state.export_file_formats;
}

/**
 * Get the current export template.
 *
 * @param state
 * @returns {state.export_template|{}}
 */
export function export_template(state) {
    return state.export_template;
}

/**
 * Get a copy of blank template.

 * @param state
 * @returns {*}
 */
export function blank_template(state) {
    return Object.assign({}, state.blank_export_template);
}
