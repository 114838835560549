/**
 *
 * @param state
 * @return {Array}
 */
export function customers(state) {
    return state.customers;
}

/**
 *
 * @param state
 * @return {state.customer|{customer_name, customer_id}}
 */
export function customer(state) {
    return state.customer;
}

/**
 *
 * @param state
 * @return {state.customer|{customer_name, customer_id}}
 */
export function contacts(state) {
    return state.customer_contacts;
}

/**
 *
 * @param state
 * @return {state.customer|{customer_name, customer_id}}
 */
export function contactsPagination(state) {
    return state.pagination.contacts;
}

/**
 *
 * @param state
 * @return {state.pagination.customers|{}}
 */
export function pagination(state) {
    return state.pagination.customers;
}

/**
 *
 * @param state
 * @return {Array}
 */
export function customerLocations(state) {
    return state.customer_locations;
}

/**
 *
 * @param state
 * @return {Array}
 */
export function contact(state) {
    return state.contact;
}

/**
 *
 * @param state
 * @return {Array}
 */
export function price_profiles(state) {
    return state.price_profiles;
}

/**
 *
 * @param state
 * @returns {Array}
 */
export function billing_terms(state) {
    return state.billing_terms;
}

/**
 *
 * @param state
 * @return {*}
 */
export function blank_contact_location(state) {
    return Object.assign({}, state.blank_contact_location);
}

/**
 *
 * @param state
 * @return {*}
 */
export function blank_contact(state) {
    return Object.assign({}, state.blank_contact);
}

/**
 *
 * @param state
 * @return {Array}
 */
export function office(state) {
    return state.office;
}

/**
 *
 * @param state
 * @return {Array}
 */
export function contact_types(state) {
    return state.contact_types;
}

/**
 *
 * @param state
 * @returns {*}
 */
export function users(state) {
    return state.users;
}

/**
 *
 * @param state
 * @returns {*}
 */
export function users_paginator(state) {
    return state.pagination.users;
}

/**
 *
 * @param state
 * @returns {*}
 */
export function users_count(state) {
    return state.customer.users_count;
}

/**
 * @param state
 * @returns {Array}
 */
export function active_notification_profiles(state) {
    return state.active_notification_profiles;
}

/**
 * @param state
 * @returns {Array}
 */
export function available_notification_profiles(state) {
    return state.available_notification_profiles;
}
