<template>
    <div class="form-group" :class="{ 'has-error': showError, 'disabled': disabled }" :dusk="'fg_' + name">
        <div class="label" v-if="label || $slots.label">
            <div class="right" v-if="$slots.actions">
                <slot name="actions"></slot>
            </div>
            <slot name="label">
                {{label}}
                <span v-if="conditionallyRequired" class="text-blue-600" title="Conditionally required">*</span>
                <span v-else-if="required" class="text-error" title="Required">*</span>

                <a href="#" ref="inputTooltip" v-if="hasTooltipSlot || helpText" class="text-xs tooltip-link ml-1">
                    <vault-icon :icon="icons.question"></vault-icon>
                </a>
                <ks-tooltip target="inputTooltip" trigger="click">
                    <div :style="'max-width: ' + helpTextMaxWidth">
                        {{ helpText }}
                    </div>
                    <slot name="tooltip"></slot>
                </ks-tooltip>
                <div v-if="advanced !== 'none'" class="inline-block right">
                    <a href="#" @click.prevent="$emit('click-advanced')" class="ml-1 text-xs">{{ advanced }}</a>
                </div>
            </slot>

            <a
                v-if="$slots.slideout || hasSlideOutProp"
                href="#"
                :title="hoverText"
                class="text-xs tooltip-link ml-1"
                @click.prevent="showSlideOut = !showSlideOut"
            >
                <vault-icon v-if="showSlideOut" :icon="icons.close"></vault-icon>
                <vault-icon v-else :icon="icons.question"></vault-icon>
            </a>

            <slide-transition>
                <div v-if="showSlideOut" class="text-xs mt-1">
                    <slot name="slideout">
                        <div v-if="slideOutText">{{slideOutText}}</div>
                        <div v-else-if="slideOutHtml" v-html="slideOutHtml"></div>
                    </slot>
                </div>
            </slide-transition>
        </div>
        <slot></slot>
        <div v-if="!hasMultipleErrors" class="error-msg" :class="{ 'show-msg': showError }">
            <span v-html="formatErrorHTML(message)"></span>
        </div>
        <template v-else>
            <div v-for="(error, index) in message" class="error-msg" :key="'error-msg-' + index" :class="{ 'show-msg': showError }">
                <span v-html="formatErrorHTML(error)"></span>
            </div>
        </template>
        <template v-for="(error, error_number) in errorsByNumber">
            <slot :name="error_number"></slot>
        </template>

        <slot name="footer"></slot>
    </div>
</template>

<script>
    import {object_get} from 'kickstart-ui/lib/objects';

    import {faQuestionCircle} from '@fortawesome/pro-light-svg-icons/faQuestionCircle';
    import {faTimes} from '@fortawesome/pro-light-svg-icons/faTimes';
    import ErrorNumbersMixin from '@Vault/UI/mixins/ErrorNumbersMixin.js';

    export default {
        name: 'FormGroup',

        mixins: [
            ErrorNumbersMixin,
        ],

        props: {
            label: {
                type: String,
                default: null
            },
            helpText: {
                type: String,
                default: null,
            },
            helpTextMaxWidth: {
                type: String,
                default: '250px',
            },
            errors: {
                type: Object,
                default() {
                    return null;
                }
            },
            errorKeys: {
                type: Array,
                default: null,
            },
            name: {
                type: String,
                default: '',
            },
            required: {
                type: [Boolean, Number],
                default: false,
            },
            conditionallyRequired: {
                type: Boolean,
                default: false,
            },
            parseNestedNames: {
                type: Boolean,
                default: true,
            },
            customErrorMessage: {
                type: String,
                default: '',
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            advanced: {
                type: String,
                default: 'none',
            },
            slideOutHtml: {
                type: String,
                default: '',
            },
            slideOutText: {
                type: String,
                default: '',
            },
            showSlideOutDefault: {
                default: false,
            },
            removePrefix: {
                type: String,
                default: '',
            },
        },

        computed: {
            errorsByNumber() {
                if ( !this.errors || Object.keys(this.errors || {}).length === 0 ) {
                    return {};
                }

                let errors_arr = this.parseNestedNames
                    ? this.getErrorsArray(object_get(this.errors, this.name))
                    : this.getErrorsArray(this.errors[this.name]);

                return errors_arr.reduce((numbers_dictionary, v) => {
                    let error_number = this.getErrorNumber(v);
                    if ( false !== error_number ) {
                        if ( !(error_number in numbers_dictionary) ) {
                            numbers_dictionary[error_number] = [];
                        }
                        numbers_dictionary[error_number].push(v);
                    }

                    return numbers_dictionary;
                }, {}) || {};
            },
            message() {
                if ( !this.errors || Object.keys(this.errors).length < 1) {
                    return '';
                }

                let message = '';

                // laravel validation returns nested field names as a simple string,
                // e.g. errors = { 'location.address1': 'this field is required' }
                // and object_get() parses dot-separated names recursively:
                // errors = { location: { address: 'this field is required' } }
                // hence this logic to avoid object_get()
                if ( this.parseNestedNames ) {
                    message = this.getErrors(object_get(this.errors, this.name));
                } else {
                    message = this.getErrors(this.errors[this.name] || '');
                }

                if ( message && this.customErrorMessage ) {
                    message = this.customErrorMessage;
                }

                if ( this.removePrefix !== '' ) {
                    message = message.replace(this.removePrefix, '')
                }

                return message;
            },

            showError() {
                if ( !this.errors ) {
                    return false;
                }

                return this.message.length > 0;
            },

            hasMultipleErrors() {
                return Array.isArray(this.message);
            },

            hasSlideOutProp() {
                return (this.slideOutText && this.slideOutText.length > 0) || (this.slideOutHtml && this.slideOutHtml.length > 0);
            },

            hasTooltipSlot() {
                return this.$slots.tooltip;
            },

            hoverText() {
                return this.slideOutText?.length > 0 ? this.slideOutText : '';
            },
        },

        data() {
            return {
                showSlideOut: this.showSlideOutDefault,
                icons: {
                    close: faTimes,
                    question: faQuestionCircle,
                },
            };
        },

        methods: {
            getErrors(errors) {
                if (this.errorKeys === null) {
                    return errors;
                }

                errors = (errors === '') ? [] : [errors];

                this.errorKeys.forEach(key => {
                    if ( this.errors[key] ) {
                        errors.push(this.errors[key]);
                    }
                })

                if ( errors.length === 0 ) {
                    return '';
                }

                if ( errors.length === 1 ) {
                    return errors[0];
                }

                return errors;
            },

            getErrorsArray(errors) {
                let errors_array = this.getErrors(errors);

                if ( !errors_array ) {
                    return [];
                }

                if ( !Array.isArray(errors_array) ) {
                    return [errors_array];
                }

                return errors_array;
            },

            formatErrorHTML(error) {
                return this.removeErrorNumber(String(error)).replaceAll("\n", '<br>');
            }
        }
    }
</script>
