import Vault from '../helpers/vault.js';

/**
 * Class used to make api calls based on a resource endpoint
 * You can define the resource in an extending class
 */
export default class Repository {

    /**
     * Initialize the repository
     */
    constructor() {
        this.resource = '';
        this.store = '';
        this.idName = 'id';
        this._params = {};
        this.requesting = false;
    }

    /**
     * Set the params for the resource
     *
     * @param params
     * @returns {Repository}
     */
    params(params) {
        this._params = params;
        return this;
    }

    /**
     * Sets the id of the repository
     *
     * @param id
     */
    setId(id) {
        this.id = id;
    }


    /**
     *
     * @return {{}}
     */
    idParams() {
        let id_params = {};
        id_params[this.idName] = this.id;
        return id_params;
    }

    /**
     * Builds the endpoint based on a passed segment
     *
     * @param segments...
     * @return {string}
     */
    endpoint() {
        let endpoint = `${this.resource}`;

        // Replace endpoint with params
        for ( let param_name in this._params ) {
            endpoint = endpoint.replace(`:${param_name}`, this._params[param_name]);
        }

        // Add the arguments to the request
        for ( let i in arguments ) {
            let segment = arguments[i];
            endpoint += ( segment != '' && segment != null ? `/${segment}` : '' );
        }

        return endpoint;
    }

    /**
     * Behaves differently depending on the number of passed params
     * 1 argument passed
     *  - Will be treated as get variables
     * 2 arguments passed
     *  - First parameter will be treated as an array of segments
     *  - Second will be treated as get variables
     *
     * @param params...
     * @returns {string}
     */
    endpointUri() {
        let params = {};
        let segments = null;

        switch ( arguments.length ) {
            case 0:
                break;
            case 1:
                params = arguments[0];
                break;
            default:
                segments = arguments[0];
                params = arguments[1];
                break;
        }

        let query = Object.keys(params)
            .map((k) => {
                if ( Array.isArray(params[k]) ) {
                    return params[k].map((v) => {
                        return encodeURIComponent(k) + '[]=' + encodeURIComponent(v)
                    }).join('&');
                }

                return encodeURIComponent(k) + '=' + encodeURIComponent(params[k])
            })
            .join('&');

        return this.endpoint(segments) + ( query.length ? `?${query}` : '');
    }

    /**
     * Calls the endpoint
     *
     * @param method
     * @param segment
     * @param request
     * @param config
     */
    call(method, segment, request = {}, config = {}) {
        if ( !(segment instanceof Array) ) {
            segment = [segment];
        }

        this.requesting = true;

        return Vault[method](this.endpoint.apply(this, segment), request, config).catch(({response}) => {

            if ( response && 'status' in response && response.status && response.status == 422 ) {
                // For Validation errors
                return Promise.reject(response.data);
            }

            return Promise.reject(response);
        }).finally(() => {
            this.requesting = false;
        })
    }

    /**
     * Find the resource based on an id
     *
     * @param id
     * @param request
     * @return Promise
     */
    find(id, request = {}) {
        this.id = id;
        return this.get(id, request);
    }

    /**
     * Deletes the resource based on an id
     *
     * @param id
     * @param request
     * @return Promise
     */
    destroy(id, request = {}) {
        return this.delete(id, request);
    }

    /**
     * Gets a list of the resource
     *
     * @param request
     * @param config
     * @return Promise
     */
    list(request = {}, config = {}) {
        return this.call('get', '', request, config);
    }

    /**
     * Calls post for the resource
     *
     * @param request
     * @param config
     * @return Promise
     */
    create(request = {}, config = {}) {
        return this.call('post', '', request, config);
    }

    /**
     * Calls put for the resource
     *
     * @param id
     * @param request
     * @param config
     * @return Promise
     */
    update(id, request = {}, config = {}) {
        return this.call('put', id, request, config);
    }

    /*
    ----------------------------
    HTTP verbs
    ----------------------------
    */

    /**
     * Calls resource endpoint with get
     *
     * @param segment
     * @param request
     * @param config
     * @return Promise
     */
    get(segment, request = {}, config = {}) {
        return this.call('get', segment, request, config);
    }

    /**
     * Calls resource endpoint with post
     *
     * @param segment
     * @param request
     * @param config
     * @return Promise
     */
    post(segment, request = {}, config = {}) {
        return this.call('post', segment, request, config);
    }

    /**
     * Calls resource endpoint with put
     *
     * @param segment
     * @param request
     * @param config
     * @return {*}
     */
    put(segment, request = {}, config = {}) {
        return this.call('put', segment, request, config);
    }

    /**
     * Calls resource endpoint with patch
     *
     * @param segment
     * @param request
     * @param config
     * @return {*}
     */
    patch(segment, request = {}, config = {}) {
        return this.call('patch', segment, request, config);
    }

    /**
     * Calls resource endpoint with delete
     *
     * @param segment
     * @param request
     * @return {*}
     */
    delete(segment, request = {}) {
        return this.call('delete', segment, request);
    }
}
