<template>
    <div>
        <page-header>
            <page-breadcrumb></page-breadcrumb>
        </page-header>

        <router-view></router-view>
    </div>
</template>

<script>
// External Dependencies

// Internal Dependencies

export default {}
</script>
