const state = {
    /** @type {Certification[]} */ certifications: [],
    /** @type {CertificationType[]} */ certification_types: [],
    types_paginator: {},
};

const mutations = {
    /**
     * @param state
     * @param {Certification[]} certifications
     */
    SET_CERTIFICATIONS (state, certifications) {
        state.certifications = certifications;
    },

    /**
     * @param state
     * @param {CertificationType[]} certification_types
     */
    SET_CERTIFICATION_TYPES (state, certification_types) {
        state.certification_types = certification_types;
    },

    /**
     * @param state
     * @param {{}} paginator
     */
    SET_TYPES_PAGINATOR (state, paginator) {
        state.types_paginator = paginator;
    },
};

import * as getters from './getters';
import * as actions from './actions';

export default {
	namespaced: true,
	state,
	mutations,
	getters,
	actions
}
