/**
 *
 * @param commit
 * @param customers
 */
export function setCustomers({commit}, customers) {
    if ( customers.data ) {
        commit('SET_CUSTOMERS', customers.data);
        commit('SET_CUSTOMERS_PAGINATION', customers.paginator);
    } else {
        commit('SET_CUSTOMERS', customers);
    }
}

/**
 *
 * @param commit
 * @param contacts
 */
export function setContacts({commit}, contacts) {
    if ( contacts.data ) {
        commit('SET_CUSTOMER_CONTACTS', contacts.data);
        commit('SET_CONTACTS_PAGINATION', contacts.paginator);
    } else {
        commit('SET_CUSTOMER_CONTACTS', contacts);
    }
}

/**
 *
 * @param commit
 * @param contact
 */
export function updateContact({commit}, contact) {
    commit('UPDATE_CONTACT', contact);
}

/**
 *
 * @param commit
 * @param customer
 */
export function addCustomer({commit}, customer) {
    commit('ADD_CUSTOMER', customer);
}

/**
 *
 * @param commit
 * @param customer
 */
export function setCustomer({commit}, customer) {
    commit('SET_CUSTOMER', customer);
}

/**
 *
 * @param commit
 */
export function resetCustomer({commit}) {
    commit('RESET_CUSTOMER');
}

/**
 *
 * @param {Note}  note
 * @param commit
 */
export function addContactNote({commit}, note) {
    commit('ADD_CONTACT_NOTE', note);
}

/**
 *
 * @param commit
 * @param customer_locations
 */
export function setCustomerLocations({commit}, customer_locations) {
    commit('SET_CUSTOMER_LOCATIONS', customer_locations);
}

/**
 *
 * @param commit
 * @param contact
 */
export function setContact({commit}, contact) {
    commit('SET_CONTACT', contact);
}

/**
 *
 * @param commit
 * @param default_val
 */
export function setContactDefault({commit}, default_val) {
    commit('SET_CONTACT_DEFAULT', default_val);
}

/**
 *
 * @param commit
 */
export function resetContact({commit}, data) {
    commit('RESET_CONTACT', data);
}

/**
 *
 * @param commit
 * @param price_profiles
 */
export function setPriceProfiles({commit}, price_profiles) {
    commit('SET_PRICE_PROFILES', price_profiles);
}

/**
 *
 * @param commit
 * @param billing_terms
 */
export function setBillingTerms({commit}, billing_terms) {
    commit('SET_BILLING_TERMS', billing_terms);
}

/**
 *
 * @param commit
 * @param office
 */
export function setCustomerOffice({commit}, office) {
    commit('SET_CUSTOMER_OFFICE', office);
}

/**
 *
 * @param commit
 * @param types
 */
export function setContactTypes({commit}, types) {
    commit('SET_CONTACT_TYPES', types);
}

/**
 *
 * @param commit
 * @param users
 */
export function setCustomerUsers({commit}, {users, paginator}) {
    commit('SET_CUSTOMER_USERS', {users, paginator});
}

/**
 *
 * @param commit
 * @param profiles
 */
export function setAvailableNotificationProfiles({ commit }, profiles) {
    commit('SET_AVAILABLE_NOTIFICATION_PROFILES', profiles);
}

/**
 *
 * @param commit
 * @param profiles
 */
export function setActiveNotificationProfiles({ commit }, profiles) {
    commit('SET_ACTIVE_NOTIFICATION_PROFILES', profiles);
}
