<template>
    <vault-icon :icon="icon" :style="{color: hex}"></vault-icon>
</template>

<script>
    // External Dependencies
    import {faHistory} from '@fortawesome/pro-regular-svg-icons/faHistory';

    // Internal Dependencies
    import BaseIcon from './BaseIcon';

    export default BaseIcon.extend({
        name: 'IconHistory',

        data() {
            return {
                icon: faHistory,
            }
        }
    });
</script>
