<template>
    <vault-icon :icon="icon" :style="{color: hex}"></vault-icon>
</template>

<script>
    // External Dependencies
    import {faCalculatorAlt} from '@fortawesome/pro-duotone-svg-icons/faCalculatorAlt';

    // Internal Dependencies
    import BaseIcon from './BaseIcon';

    export default BaseIcon.extend({
        name: 'IconCalculator',

        data() {
            return {
                icon: faCalculatorAlt,
            }
        }
    })
</script>