// External Dependencies
import Vue from 'vue';
import _pick from 'lodash/pick';

const state = {
    filters: {},
    dependent_filters: {
        work_visits: {
            attachments: ['attachments', 'attachment_type'],
        },
        work_orders: {
            attachments: ['attachments', 'attachment_type'],
        },
    },
};

/**
 * The filters that should be kept when clearing the namespace.
 */
const remembered_filters = {
    work_orders: ['show_closed'],
    work_visits: ['show_completed'],
};

const mutations = {
    /**
     *
     * @param state
     * @param namespace
     * @constructor
     */
    SET_NAMESPACE (state, namespace) {
        if ( !(namespace in state.filters) ) {
            Vue.set(state.filters, namespace, {});
        }
    },

    /**
     *
     * @param state
     * @param namespace
     * @constructor
     */
    CLEAR_NAMESPACE (state, namespace) {
        Vue.set(state.filters, namespace, _pick(state.filters[namespace], remembered_filters[namespace]));
    },

    /**
     *
     * @param state
     * @param namespace
     * @param which
     * @param where
     * @param value
     * @constructor
     */
    SET_FILTER (state, {namespace, which, where, value}) {
        if ( typeof where != 'undefined' && where != '' ) {
            if ( typeof state.filters[namespace][which] == 'undefined' ) {
                Vue.set(state.filters[namespace], which, {});
            }

            if ( typeof state.filters[namespace][which][where] == 'undefined' ) {
                Vue.set(state.filters[namespace][which], where, {});
            }

            Vue.set(state.filters[namespace][which], where, value);
        } else if ( typeof which != 'undefined' && which != '' ) {
            Vue.set(state.filters[namespace], which, value);
        } else {
            Vue.set(state.filters, namespace, value);
        }
    },

    /**
     *
     * @param state
     * @param {string} namespace
     * @param {string|string[]} which
     * @param {?string} sub_key
     */
    CLEAR_FILTER (state, {namespace, which, sub_key = null}) {
        if ( Array.isArray(which) ) {
            which.forEach(key => mutations.CLEAR_FILTER(state, {namespace, which: key}));
        }

        if ( !(namespace in state.filters) || !(which in state.filters[namespace]) ) {
            return;
        }

        if ( sub_key !== null && 'value' in state.filters[namespace][which] ) {
            Vue.delete(state.filters[namespace][which].value, sub_key);

            if ( Object.keys(state.filters[namespace][which].value).length > 0 ) {
                return;
            }
        }

        Vue.delete(state.filters[namespace], which);
    },

    /**
     * Clears ALL search filters
     * @param state
     * @param namespace
     * @constructor
     */
    CLEAR_ALL_FILTERS (state, namespace) {
        Vue.set(state.filters, namespace, {});
    },
};

import * as getters from './getters';
import * as actions from './actions';

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
}
