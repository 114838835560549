import Vue from 'vue';

/**
 * @typedef {Object} EntityConfig
 * @property {String} logo
 * @property {Boolean} location_number_auto_generate
 * @property {Boolean} work_visit_timecard_enable
 * @property {Boolean} enable_sub_contractors
 * @property {Boolean} enable_visit_verification
 * @property {Boolean} enable_inventory
 * @property {Boolean} enable_inventory_min_max
 * @property {Boolean} visit_complete_auto_schedule
 * @property {Boolean} enable_mandatory_service_reason
 * @property {String} work_visit_schedule_sort
 * @property {Boolean} enable_user_timesheets
 * @property {Boolean} approve_customer_contacts
 * @property {Boolean} auto_number_fleet
 * @property {Boolean} require_visit_diagram
 * @property {Boolean} require_visit_verification
 * @property {Boolean} work_require_invoice_before_closing
 * @property {String} invoice_notification_delay
 */
const config_defaults = {
    logo: null,
    location_number_auto_generate: true,
    work_visit_timecard_enable: false,
    enable_sub_contractors: false,
    enable_visit_verification: false,
    enable_inventory: false,
    enable_inventory_min_max: false,
    visit_complete_auto_schedule: false,
    enable_mandatory_service_reason: false,
    work_visit_schedule_sort: 'time',
    enable_user_timesheets: false,
    require_visit_diagram: false,
    require_visit_verification: false,
    approve_customer_contacts: false,
    summary_auto_include_attachments_images: false,
    summary_auto_include_attachments_documents: false,
    work_require_invoice_before_closing: false,
    invoice_notification_delay: null,
};

const config_types = {
    logo: String,
    location_number_auto_generate: Boolean,
    work_visit_timecard_enable: Boolean,
    enable_sub_contractors: Boolean,
    enable_visit_verification: Boolean,
    enable_inventory: Boolean,
    enable_inventory_min_max: Boolean,
    visit_complete_auto_schedule: Boolean,
    enable_mandatory_service_reason: Boolean,
    work_visit_schedule_sort: String,
    enable_user_timesheets: Boolean,
    auto_number_fleet: Boolean,
    require_visit_diagram: Boolean,
    require_visit_verification: Boolean,
    approve_customer_contacts: Boolean,
    summary_auto_include_attachments_images: Boolean,
    summary_auto_include_attachments_documents: Boolean,
    work_require_invoice_before_closing: Boolean,
    invoice_notification_delay: String,
};

const state = {
    configs: {
        ...config_defaults,
    },
};

const mutations = {
    SET_CONFIGS (state, configs) {
        // Set each config value
        configs.forEach((config) => {
            switch ( config_types[config.config_name] ) {
                case Boolean:
                    Vue.set(
                        state.configs,
                        config.config_name,
                        config.value && config.value != '0' ? true : false
                    );
                    break;
                default:
                    Vue.set(state.configs, config.config_name, config.value);
            }
        })
    },

    SET_CONFIG_VALUE (state, {config_name, value}) {
        state.configs[config_name] = value;
    }
};

import * as getters from './getters';
import * as actions from './actions';

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
