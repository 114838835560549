import DeliveryAddressCollection from "@Vault/Customers/DeliveryAddressCollection";
import {round} from 'helpers/strings';

/**
 *
 * @param state
 * @return {Array}
 */
export function invoices(state) {
    return state.invoices;
}

/**
 *
 * @param state
 * @return {*}
 */
export function invoice(state) {
    return state.invoice;
}

export function contact_emails(state) {
    return new DeliveryAddressCollection(state.invoice?.contact?.delivery_address || [])
        .emails();
}

export function location_emails(state) {
    return new DeliveryAddressCollection(state.invoice?.location?.delivery_address || [])
        .emails();
}

/**
 *
 * @param state
 * @return {state.pagination.invoices|{}}
 */
export function invoice_pagination(state) {
    return state.pagination.invoices;
}

/**
 *
 * @param state
 * @return {Number}
 */
export function invoice_total(state) {
    if ( state.invoice.items )
        return state.invoice.items
            .filter(item => !item.ignore_values)
            .reduce((total, item) => total + round((item.quantity * (item.tax_rate || 1) * item.unit_price)), 0);
}

/**
 *
 * @param state
 * @returns {Boolean}
 */
export function is_taxable_state_loading(state) {
    return state.taxable_state.is_loading;
}

/**
 *
 * @param state
 * @returns {Boolean}
 */
export function is_taxable_state(state) {
    return state.taxable_state.is_taxable;
}

/**
 *
 * @param state
 * @returns {Boolean}
 */
export function state_used_for_tax(state) {
    return state.taxable_state.state;
}

/**
 *
 * @param state
 * @returns {Object}
 */
export function taxable_state_request_canceler(state) {
    return state.taxable_state.request_canceler;
}

