import Repository from '../../../Repository';

export class CountiesRepository extends Repository {
    constructor() {
        super();
        this.resource = 'counties';

    }

}

const Counties = new CountiesRepository();

export default Counties;

/**
 * @typedef County
 * @type {object}
 * @property {number} county_id
 * @property {string} county_name
 * @property {number} state_id
 * @property {?State} state
 */
