/**
 *
 * @param state
 * @returns {EquipmentType} equipment_type
 */
export function equipment_type(state) {
    return state.equipment_type;
}

/**
 *
 * @param state
 * @returns {[]}
 */
export function equipment_types(state) {
    return state.equipment_types.data;
}

/**
 *
 * @param state
 * @returns {[]}
 */
export function equipment_types_inventory(state) {
    return state.equipment_types.data.filter((type) => {
        return !type.options?.scope || type.options?.scope.indexOf('inventory') !== -1;
    });
}


/**
 *
 * @param state
 * @returns {[]}
 */
export function equipment_types_customer(state) {
    return state.equipment_types.data.filter((type) => {
        return !type.options?.scope || type.options?.scope.indexOf('customer') !== -1;
    });
}

/**
 *
 * @param state
 * @returns {Boolean}
 */
export function equipment_types_loading(state) {
    return state.equipment_types_loading;
}

/**
 *
 * @param state
 * @returns {[]|{}}
 */
export function equipment_types_pagination(state) {
    return state.equipment_types.pagination;
}

/**
 *
 * @param state
 * @returns {*}
 */
export function equipment(state) {
    return state.equipment;
}

/**
 *
 * @param state
 * @returns {[{options: {auto_assign_equipment_number: {default: boolean, description: string, label: string, field_type: string}}, label: string, field_type: string, required: boolean}, {label: string, field_type: string, required: boolean}, {label: string, field_type: string, required: boolean}, {label: string, field_type: string, required: boolean}, {optional: boolean, label: string, field_type: string, required: boolean}]|[{is_required: boolean, label: string, field_type: string}, {is_required: boolean, label: string, field_type: string}, {is_required: boolean, label: string, field_type: string}, {is_required: boolean, label: string, field_type: string}, {is_required: boolean, label: string, field_type: string}]|[{label: string}]|*}
 */
export function equipment_default_fields(state) {
    return state.default_fields;
}

/**
 *
 * @param state
 * @returns {*}
 */
export function drag_data(state) {
    return state.drag_data;
}
