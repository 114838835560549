import {isObject} from 'helpers/types';

/**
 * Check if we want to undo
 *
 * @param options
 * @returns {boolean}
 */
export const isUndoing = options => isObject(options) && 'undo' in options && options.undo;

/**
 * Check if we want to redo
 *
 * @param options
 * @returns {boolean}
 */
export const isRedoing = options => isObject(options) && 'redo' in options && options.redo;

/**
 * Checks if history should be added
 *
 * @param options
 * @returns {*|boolean}
 */
export const shouldAddHistory = options => {
    if ( !isObject(options) || !('add_history' in options) ) {
        return true;
    }

    return isObject(options) && 'add_history' in options && options.add_history;
}

/**
 * @param options
 * @param elements
 * @param callback
 */
const historyLoop = (options, elements, callback) => {
    for ( const {id, state} of options.payload ) {
        const element = elements.find(element => element.id() === id);

        callback(element, state);
    }
}

/**
 * @param options
 * @param state
 * @param callback
 */
export const elementLoop = (options, state, callback) => {
    if ( isUndoing(options) || isRedoing(options) ) {
        historyLoop(options, state.elements, callback);
    } else {
        state.selected.forEach(element => {
            callback(element, options);
        })
    }
}

export const historyMap = (options, state, callback) => {
    if ( isUndoing(options) || isRedoing(options) ) {
        const ids = options.payload.map(item => item.id);

        return state.elements
            .filter(element => ids.includes(element.id()))
            .map(element => {
                const $options = options.payload.find(item => item.id === element.id());

                return Object.assign({}, {
                    id: element.id(),
                    state: callback(element, $options)
                });
            });
    }

    return state.selected.map(element => Object.assign({}, {
        id: element.id(),
        state: callback(element, options)
    }));
}