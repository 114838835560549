/**
 * Set the loaded categories.
 *
 * @param commit
 * @param {ReferenceCategory[]} categories
 * @param {?number} selected_category_id
 */
export const setCategories = ({commit}, {categories, selected_category_id}) => {
    commit('SET_CATEGORIES', categories);

    if ( selected_category_id ) {
        setSelectedCategoryById({commit}, selected_category_id);
    }
};

/**
 * Set the current document.
 *
 * @param commit
 * @param {ReferenceDocument} document
 */
export const setDocument = ({commit}, document) => {
    commit('SET_DOCUMENT', document);
};

/**
 * Reset the current document.
 *
 * @param commit
 */
export const resetDocument = ({commit}) => {
    commit('RESET_DOCUMENT');
};

/**
 * Set the loaded documents.
 *
 * @param commit
 * @param {ReferenceDocument[]} documents
 */
export const setDocuments = ({commit}, documents) => {
    commit('SET_DOCUMENTS', documents);
};

/**
 * Set paginator
 *
 * @param commit
 * @param paginator
 */
export const setDocumentsPaginator = ({commit}, paginator) => {
    commit('SET_DOCUMENTS_PAGINATOR', paginator);
};

/**
 * Set whether we are editing the current document.
 *
 * @param commit
 * @param {boolean} editing
 */
export const setEditingDocument = ({commit}, editing) => {
    commit('SET_EDITING_DOCUMENT', editing);
};

/**
 * Set the currently selected category.
 *
 * @param commit
 * @param {ReferenceCategory} category
 */
export const setSelectedCategory = ({commit}, category) => {
    commit('SET_SELECTED_CATEGORY', category);
};

/**
 * Set the currently selected category by id.
 *
 * @param commit
 * @param {?number} category_id
 */
export const setSelectedCategoryById = ({commit}, category_id) => {
    commit('SET_SELECTED_CATEGORY_BY_ID', category_id);
};

/**
 * Set the dragging category.
 *
 * @param commit
 * @param {ReferenceCategory} category
 */
export const startDragCategory = ({commit}, category) => {
    commit('SET_DRAGGING_CATEGORY', category);
};

/**
 * Resets the dragging category.
 *
 * @param commit
 */
export const endDragCategory = ({commit}) => {
    commit('RESET_DRAGGING_CATEGORY');
};

/**
 * Set the dragging document.
 *
 * @param commit
 * @param {ReferenceDocument} document
 */
export const startDragDocument = ({commit}, document) => {
    commit('SET_DRAGGING_DOCUMENT', document);
};

/**
 * Resets the dragging document.
 *
 * @param commit
 */
export const endDragDocument = ({commit}) => {
    commit('RESET_DRAGGING_DOCUMENT');
};

/**
 * Set the currently selected item.
 *
 * @param commit
 * @param {?ReferenceItem} item
 */
export const setSelectedItem = ({commit}, item = null) => {
    commit('SET_SELECTED_ITEM', item);
};
