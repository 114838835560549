<template>
    <vault-icon :icon="icon" :style="{color: hex}"></vault-icon>
</template>

<script>
    // External Dependencies
    import {faInfoCircle} from '@fortawesome/pro-solid-svg-icons/faInfoCircle';

    // Internal Dependencies
    import BaseIcon from './BaseIcon';

    export default BaseIcon.extend({
        name: 'IconInfo',

        props: {
            outline: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                icon: faInfoCircle,
            }
        }
    })
</script>
